import React, { useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { CSSTransition } from "react-transition-group";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

const UpdateTaskForm = ({ update, setUpdate, selectedTask }) => {
  const [showNotification, setshowNotification] = useState(false);
  const [errorNotification, setErrorNotification] = useState(false);
  const [loading, setLoading] = useState(false);
  const apiUrl = import.meta.env.VITE_API_URL;
  const token = JSON.parse(localStorage.getItem("user"))?.access;
  const id = JSON.parse(localStorage.getItem("user"))?.user_id;

  const handleUpdateTask = async (values, { setSubmitting, resetForm }) => {
    const taskData = {
      ...values,
      created_by: id,
    };
    setLoading(true);
    try {
      const response = await axios.patch(
        `${apiUrl}/api/v1/tracker/tasks/${selectedTask.id}?manager=${id}&created_by=${id}`,
        taskData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      setshowNotification("Task updated successfully!");
      setTimeout(() => setshowNotification(false), 3000);
      resetForm();
      setUpdate(false);
      window.location.reload();
    } catch (error) {
      setLoading(false);
      setErrorNotification("Error updating task!");
      setTimeout(() => setErrorNotification(false), 3000);
      console.error("API Error:", error.response?.data || error.message);
    } finally {
      setSubmitting(false);
    }
  };

  const handleCheckboxToggle = async (isCompleted) => {
    try {
      const response = await axios.patch(
        `${apiUrl}/api/v1/tracker/tasks/${selectedTask.id}/complete?manager=${id}&created_by=${id}`,
        { is_completed: isCompleted },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error(
        "Error updating is_completed:",
        error.response?.data || error.message
      );
      setErrorNotification("Error updating task!");
      setTimeout(() => setErrorNotification(false), 3000);
    }
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
    challenges: Yup.string().required("Challenge is required"),
    is_completed: Yup.boolean(),
  });

  return (
    <>
      <div
        className="fixed inset-0 overflow-scroll z-50 flex items-center justify-center bg-black/70 backdrop-blur-sm"
        onClick={() => setUpdate(false)}
      >
        <div
          className="relative bg-white rounded-lg shadow-lg w-[90%] max-w-lg animate-fadeIn p-8"
          onClick={(e) => e.stopPropagation()}
        >
          {/* Close Button */}
          <FontAwesomeIcon
            icon={faTimes}
            onClick={() => setUpdate(false)}
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 cursor-pointer transition duration-300"
            size="lg"
          />

          <Formik
            initialValues={{
              title: selectedTask.title,
              description: selectedTask.description,
              challenges: selectedTask.challenges,
              is_completed: selectedTask.is_completed,
            }}
            validationSchema={validationSchema}
            onSubmit={handleUpdateTask}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <h3 className="text-2xl font-semibold text-gray-800 mb-6 text-center">
                  Update Task
                </h3>

                {/* Title */}
                <div className="mb-5">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Title
                  </label>
                  <Field
                    type="text"
                    name="title"
                    className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 transition duration-300"
                  />
                  <ErrorMessage
                    name="title"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                {/* Description */}
                <div className="mb-5">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Description
                  </label>
                  <Field
                    as="textarea"
                    name="description"
                    className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 transition duration-300"
                    rows="4"
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                {/* Challenges */}
                <div className="mt-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Challenges
                  </label>
                  <Field
                    as="textarea"
                    name="challenges"
                    className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 transition duration-300"
                    rows="3"
                  />
                  <ErrorMessage
                    name="challenges"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                {/* Checkbox */}
                <div className="mt-4 flex gap-3 items-center">
                  <input
                    type="checkbox"
                    name="is_completed"
                    checked={values.is_completed}
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      setFieldValue("is_completed", isChecked);
                      handleCheckboxToggle(isChecked);
                    }}
                  />
                  <label className="block text-sm font-medium text-gray-700">
                    Click to mark task as completed
                  </label>
                </div>

                {/* Submit Button */}
                <div className="text-center mt-4">
                  <button
                    type="submit"
                    className="w-full py-3 bg-btn text-white rounded-md font-medium text-lg hover:bg-orange-600 focus:outline-none focus:ring-2 transition duration-300"
                  >
                    {loading ? "Updating..." : "Update Task"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default UpdateTaskForm;
