import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import PeriodContextSelector from "../Components/PeriodContextSelector";
import moment from "moment";
import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";

const ManagerKpis = () => {
  const id = JSON.parse(localStorage.getItem("user"))?.user_id;
  const [errorNotification, setErrorNotification] = useState(false);
  const [loading, setLoading] = useState(false);
  const apiUrl = import.meta.env.VITE_API_URL;
  const token = JSON.parse(localStorage.getItem("user"))?.access;
  const [currentPage, setCurrentPage] = useState(1);
  const [managersData, setManagersData] = useState([]);
  const itemsPerPage = 5;
  const totalPages = Math.ceil(managersData.length / itemsPerPage);

  const paginatedData = managersData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getStatusClass = (status) => {
    switch (status) {
      case "SUBMITTED":
        return "bg-green-100 text-green-700";
      case "Undone":
        return "bg-red-100 text-red-700";
      case "PENDING":
        return "bg-yellow-100 text-yellow-700";
      default:
        return "bg-gray-100 text-gray-700";
    }
  };

  useEffect(() => {
    const fetchKpis = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${apiUrl}/api/v1/tracker/kpis/reports/all?reported_by=${id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        const kpiData = response.data.data[0];

        setManagersData([
          {
            id: kpiData.id,
            name: kpiData.kpi?.name || "Unnamed KPI",
            due_date: kpiData.due_date,
            targets:
              kpiData.target_reports?.map((report) => ({
                id: report.id,
                name: report.name || `Target ${report.id}`,
                description: report.description || "No description available",
                total_expected: report.total_expected || 0,
                actual_completed: report.actual_completed || 0,
              })) || [],
            description: kpiData.kpi?.description || "No description available",
            status: kpiData.status || "No status available",
            start_date: kpiData.start_date,
            created_at: kpiData.created_at,
          },
        ]);
      } catch (error) {
        console.error("Error fetching KPIs:", error);
        setErrorNotification("There was an error while fetching the KPIs.");
        setTimeout(() => {
          setErrorNotification(false);
        }, 3000);
      } finally {
        setLoading(false);
      }
    };

    fetchKpis();
  }, []);

  const styles = {
    enter: "transform translate-x-full opacity-0",
    enterActive:
      "transform -translate-x-0 opacity-100 transition-all duration-500 ease-in-out",
    exitActive:
      "transform translate-x-full opacity-0 transition-all duration-500 ease-in-out",
  };

  return (
    <>
      <div className="">
        <div className="overflow-x-auto bg-white mt-4">
          <span className="flex items-center p-4 mt-4 justify-between">
            <h2 className="text-md font-bold rounded-lg">Newly Set KPIs</h2>
            <Link to="/manager_kpi_details">
              <h5 className="text-btn underline">View All</h5>
            </Link>
          </span>
          <div className="overflow-x-auto bg-white">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {loading ? (
                <div className="col-span-full text-center text-[13px] text-gray-500">
                  Loading KPIs...
                </div>
              ) : paginatedData.length > 0 ? (
                paginatedData.map((manager) => (
                  <div
                    key={manager.id}
                    className="p-4 border rounded-md shadow-md hover:shadow-lg cursor-pointer transition-shadow"
                  >
                    <div className="flex justify-between items-center">
                      <h4 className="text-lg font-semibold text-gray-700 mb-2">
                        {manager.name}
                      </h4>
                      <span
                        className={`inline-block mt-2 px-3 py-1 rounded-full text-xs font-medium whitespace-nowrap ${getStatusClass(
                          manager.status || "In Progress"
                        )}`}
                      >
                        {manager.status || "In Progress"}
                      </span>
                    </div>
                    {manager.targets && manager.targets.length > 0 && (
                      <div className="mb-4">
                        <h4 className="text-[13px] text-gray-600 mb-2">
                          Targets
                        </h4>
                        <div className="space-y-4">
                          {manager.targets.map((target, index) => (
                            <div
                              key={target.id || index}
                              className="border rounded-lg p-4 shadow-sm bg-gray-50 hover:bg-gray-100 transition duration-200"
                            >
                              <h5 className="text-md font-bold text-blue-600 mb-2">
                                {target.name}
                              </h5>
                              <p className="text-sm text-gray-700">
                                {target.description}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    <span className="flex justify-between items-center">
                      <p className="text-[13px] text-gray-600">
                        <strong>Start Date:</strong> {manager.start_date}
                      </p>

                      <p className="text-[13px] text-gray-600">
                        <strong>Due Date:</strong> {manager.due_date}
                      </p>
                    </span>
                  </div>
                ))
              ) : (
                <div className="col-span-full text-center text-[13px] text-gray-500">
                  No Available KPIs.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {managersData.length > itemsPerPage && (
        <div className="flex justify-center items-center mt-4">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="px-3 py-1 mx-1 text-sm font-semibold text-gray-700 bg-gray-200 rounded hover:bg-gray-300 disabled:opacity-50"
          >
            Previous
          </button>
          {[...Array(totalPages)].map((_, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(index + 1)}
              className={`px-3 py-1 mx-1 text-sm font-semibold ${
                currentPage === index + 1
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-gray-700 hover:bg-gray-300"
              } rounded`}
            >
              {index + 1}
            </button>
          ))}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="px-3 py-1 mx-1 text-sm font-semibold text-gray-700 bg-gray-200 rounded hover:bg-gray-300 disabled:opacity-50"
          >
            Next
          </button>
        </div>
      )}

      <CSSTransition
        in={errorNotification}
        classNames={styles}
        timeout={500}
        unmountOnExit
      >
        <div className="p-4 shadow-xl z-50 bg-white fixed right-3 top-10 border-s-2 border-danger rounded-md flex items-center gap-4">
          <span className="flex items-center gap-4">
            <FontAwesomeIcon className="text-danger" icon={faTimes} />
            <p className="font-light">{errorNotification}</p>
          </span>
        </div>
      </CSSTransition>
    </>
  );
};

export default ManagerKpis;
