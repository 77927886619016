import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import axios from "axios";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
);

const QuickTasks = () => {
  const apiUrl = import.meta.env.VITE_API_URL;
  const token = JSON.parse(localStorage.getItem("user"))?.access;
  const { id } = useParams();
  const [quickStats, setQuickStats] = useState({
    totalKpis: 0,
    completed: 0,
    undone: 0,
    completionRate: "N/A",
  });

  const [barData, setBarData] = useState({
    labels: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
    datasets: [
      {
        label: "Task Completed",
        data: [0, 0, 0, 0, 0],
        backgroundColor: "#FF8A00",
        borderColor: "#FF8A00",
        borderWidth: 1,
      },
      {
        label: "Task Created",
        data: [0, 0, 0, 0, 0],
        backgroundColor: "rgba(102, 153, 255, 0.6)",
        borderColor: "rgba(102, 153, 255, 1)",
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/v1/tracker/tasks/all`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        const tasks = response.data.data || [];
        const managerTasks = tasks.filter((task) => task.created_by.id === id);

        const totalKpis = managerTasks.length;

        const completedTasks = managerTasks.filter(
          (task) => task.is_completed
        ).length;

        const undoneTasks = managerTasks.filter(
          (task) => !task.is_completed
        ).length;

        const completionRate = totalKpis
          ? ((completedTasks / totalKpis) * 100).toFixed(2)
          : "0.00";

        const taskCompletionByDay = [0, 0, 0, 0, 0];
        const taskUndoneByDay = [0, 0, 0, 0, 0];

        managerTasks.forEach((task) => {
          const dayIndex = new Date(task.start_date).getDay();
          if (dayIndex > 0 && dayIndex <= 5) {
            if (task.is_completed) {
              taskCompletionByDay[dayIndex - 1] += 1;
            } else {
              taskUndoneByDay[dayIndex - 1] += 1;
            }
          } else {
            console.warn(
              "Task has an invalid or non-weekday start_date:",
              task
            );
          }
        });

        setBarData({
          labels: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
          datasets: [
            {
              label: "Tasks Completed",
              data: taskCompletionByDay,
              backgroundColor: "#4CAF50",
              borderColor: "#388E3C",
              borderWidth: 1,
            },
            {
              label: "Tasks Undone",
              data: taskUndoneByDay,
              backgroundColor: "#F44336",
              borderColor: "#D32F2F",
              borderWidth: 1,
            },
          ],
        });

        setQuickStats({
          totalKpis,
          completed: completedTasks,
          undone: undoneTasks,
          completionRate,
        });
      } catch (error) {
        console.error("Error fetching tasks:", error);
      }
    };

    fetchTasks();
  }, [apiUrl, token, id]);

  const barOptions = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: "x",
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          padding: 24,
        },
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        max: 25,
        title: {
          display: true,
          text: "Percentage",
        },
      },
    },
  };

  return (
    <>
      <div className="bg-white mb-4 shadow-md p-4 rounded-md mt-[24px]">
        <h2 className="font-bold mb-4">Quick Stats</h2>
        <div className="grid grid-cols-2 gap-4">
          <div className="flex bg-gray-100 p-2 border-dashed border rounded-md flex-col items-center">
            <span className="text-gray-500 text-xs">Tasks Created</span>
            <span className="text-gray-900 text-2xl font-bold">
              {quickStats.totalKpis}
            </span>
          </div>
          <div className="flex bg-gray-100 p-2 border-dashed border rounded-md flex-col items-center">
            <span className="text-gray-500 text-xs">Tasks Completed</span>
            <span className="text-gray-900 text-2xl font-bold">
              {quickStats.completed}
            </span>
          </div>
          <div className="flex bg-gray-100 p-2 border-dashed border rounded-md flex-col items-center">
            <span className="text-gray-500 text-xs">In Progress</span>
            <span className="text-gray-900 text-2xl font-bold">
              {quickStats.undone}
            </span>
          </div>
          <div className="flex bg-gray-100 p-2 border-dashed border rounded-md flex-col items-center">
            <span className="text-gray-500 text-xs">Completion Rate</span>
            <span className="text-gray-900 text-2xl font-bold">
              {quickStats.completionRate}%
            </span>
          </div>
        </div>
      </div>
      <div className="bg-white p-4 mb-3 rounded-md shadow-sm">
        <h2 className="font-bold text-sm mb-6">Task Completion</h2>
        <div style={{ width: "100%", height: "400px" }}>
          <Bar data={barData} options={barOptions} />
        </div>
      </div>
    </>
  );
};

export default QuickTasks;
