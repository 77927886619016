import axios from "axios";
import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { CSSTransition } from "react-transition-group";
import { faCheckCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EditProfile = () => {
  const [user, setUser] = useState(null);
  const apiUrl = import.meta.env.VITE_API_URL;
  const [showNotification, setshowNotification] = useState(false);
  const [errorNotification, setErrorNotification] = useState(false);
  const token = JSON.parse(localStorage.getItem("user"))?.access;

  useEffect(() => {
    const fetchResources = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/v1/auth/me`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUser(response.data);
      } catch (error) {
        console.error(
          "Error fetching resources:",
          error.response?.data || error.message
        );
      }
    };

    fetchResources();
  }, [apiUrl, token]);

  const validationSchema = yup.object().shape({
    phone: yup
      .string()
      .min(1, "Phone is required")
      .required("Phone is required"),
    address: yup
      .string()
      .min(1, "Address is required")
      .required("Address is required"),
    timezone: yup
      .string()
      .min(1, "Timezone is required")
      .required("Timezone is required"),
  });

  const handleSubmit = async (values) => {
    try {
      await axios.patch(`${apiUrl}/api/v1/auth/me/update/`, values, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setshowNotification("Profile updated successfully!");
      setTimeout(() => {
        setshowNotification(false);
      }, 3000);
    } catch (error) {
      console.error(
        "Error updating profile:",
        error.response?.data || error.message
      );
      setErrorNotification("Failed to update profile.");
      setTimeout(() => {
        setErrorNotification(false);
      }, 3000);
    }
  };

  const styles = {
    enter: "transform translate-x-full opacity-0",
    enterActive:
      "transform -translate-x-0 opacity-100 transition-all duration-500 ease-in-out",
    exitActive:
      "transform translate-x-full opacity-0 transition-all duration-500 ease-in-out",
  };

  return (
    <>
      <div className="md:ml-[350px]">
        <div className="p-4 flex justify-center items-center flex-col">
        <h2 className="text-3xl font-bold p-4">Edit Profile</h2>
          <div className="w-full max-w-md p-4">
            {user ? (
              <Formik
                initialValues={{
                  phone: user.phone || "",
                  address: user.address || "",
                  timezone: user.timezone || "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting }) => (
                  <Form className="space-y-4">
                    <div>
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Phone
                      </label>
                      <Field
                        type="text"
                        name="phone"
                        id="phone"
                        className="mt-1 block w-full px-3 py-2 border rounded-md"
                      />
                      <ErrorMessage
                        name="phone"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="address"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Address
                      </label>
                      <Field
                        type="text"
                        name="address"
                        id="address"
                        className="mt-1 block w-full px-3 py-2 border rounded-md"
                      />
                      <ErrorMessage
                        name="address"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="timezone"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Timezone
                      </label>
                      <Field
                        type="text"
                        name="timezone"
                        id="timezone"
                        disabled
                        className="mt-1 block w-full px-3 py-2 border rounded-md"
                      />
                      <ErrorMessage
                        name="timezone"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="w-full py-2 px-4 bg-btn text-white font-semibold rounded-md"
                    >
                      {isSubmitting ? "Saving..." : "Save Changes"}
                    </button>
                  </Form>
                )}
              </Formik>
            ) : (
              <p className="text-center text-gray-500">Loading user data...</p>
            )}
          </div>
        </div>
      </div>

      <CSSTransition
        in={showNotification}
        classNames={styles}
        timeout={500}
        unmountOnExit
      >
        <div className="p-4 shadow-xl z-50 bg-white fixed right-3 top-10 border-s-2 border-success rounded-md flex items-center gap-4">
          <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
          <p className="font-light">{showNotification}</p>
        </div>
      </CSSTransition>

      <CSSTransition
        in={errorNotification}
        classNames={styles}
        timeout={500}
        unmountOnExit
      >
        <div className="p-4 shadow-xl z-50 bg-white fixed right-3 top-10 border-s-2 border-danger rounded-md flex items-center gap-4">
          <FontAwesomeIcon icon={faTimes} className="text-danger" />
          <p className="font-light">{errorNotification}</p>
        </div>
      </CSSTransition>
    </>
  );
};

export default EditProfile;
