import { faPen, faTrashCan, faClockRotateLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

const Popup = ({ userId, styles, popup, setEdit, setErase, setPopup }) => {
  return (
    <>
      <CSSTransition in={popup} classNames={styles} timeout={500} unmountOnExit>
        <div
          onClick={() => setPopup(false)}
          className="fixed inset-0 z-20 flex overflow-scroll justify-center items-center bg-black bg-opacity-50 backdrop-blur-sm"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="bg-white p-4 h-auto max-h-[90vh] w-full fixed bottom-0 right-0 rounded-tl-lg rounded-tr-lg shadow-md overflow-y-auto"
          >
            <div className="flex flex-col gap-4">
              <Link
                to={`/managers/${userId}/sessions`}
                className="border bg-focus text-white font-bold flex items-center gap-2 justify-center hover:bg-blue-700 transition-all ease-in-out w-full py-2 px-4 rounded-md"
              >
                <FontAwesomeIcon icon={faClockRotateLeft} />
                View Sessions
              </Link>

              <button
                onClick={() => setEdit(true)}
                className="border bg-btn text-white font-bold flex items-center gap-2 justify-center hover:bg-orange-700 transition-all ease-in-out w-full py-2 px-4 rounded-md"
              >
                <FontAwesomeIcon icon={faPen} />
                Edit User
              </button>

              <button
                onClick={() => setErase(true)}
                className="bg-red-500 text-white font-bold flex items-center gap-2 justify-center hover:bg-red-700 transition-all ease-in-out w-full py-2 px-4 rounded-md"
              >
                <FontAwesomeIcon icon={faTrashCan} />
                Delete User
              </button>
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
};

export default Popup;
