import React, { useEffect, useRef } from 'react';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog";
import { MapPin } from "lucide-react";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Fix for the default marker icon
delete (L.Icon.Default.prototype as any)._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

interface LocationDialogProps {
    location: {
        city: string;
        country: string;
        lat: number;
        lon: number;
    };
}

const LocationDialog: React.FC<LocationDialogProps> = ({ location }) => {
    const mapRef = useRef<L.Map | null>(null);
    const mapContainerRef = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = React.useState(false);

    useEffect(() => {
        if (isOpen && mapContainerRef.current && !mapRef.current) {
            // Initialize map
            mapRef.current = L.map(mapContainerRef.current).setView(
                [location.lat, location.lon],
                13
            );

            // Add tile layer (OpenStreetMap)
            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '© OpenStreetMap contributors'
            }).addTo(mapRef.current);

            // Add marker
            const marker = L.marker([location.lat, location.lon])
                .addTo(mapRef.current)
                .bindPopup(`${location.city}, ${location.country}`);

            // Open popup
            marker.openPopup();
        }

        // Cleanup
        return () => {
            if (mapRef.current) {
                mapRef.current.remove();
                mapRef.current = null;
            }
        };
    }, [isOpen, location]);

    return (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogTrigger asChild>
                <div className="cursor-pointer text-focus hover:text-focus/80 flex items-center gap-2">
                    <MapPin className="w-4 h-4" />
                    {location.city}, {location.country}
                </div>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[600px]">
                <DialogHeader>
                    <DialogTitle className="flex items-center gap-2">
                        <MapPin className="w-5 h-5" />
                        {location.city}, {location.country}
                    </DialogTitle>
                </DialogHeader>

                <div className="mt-4">
                    <div
                        ref={mapContainerRef}
                        className="w-full h-[400px] rounded-lg overflow-hidden"
                    />

                    <div className="mt-4 grid grid-cols-2 gap-4 text-sm">
                        <div className="bg-gray-50 p-3 rounded-lg">
                            <span className="text-gray-500">Latitude</span>
                            <p className="font-medium">{location.lat.toFixed(6)}°</p>
                        </div>
                        <div className="bg-gray-50 p-3 rounded-lg">
                            <span className="text-gray-500">Longitude</span>
                            <p className="font-medium">{location.lon.toFixed(6)}°</p>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default LocationDialog;