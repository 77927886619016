import React, { createContext, useContext, useReducer, useCallback, useEffect } from 'react';
import axios from '@/lib/axios';
import { Template } from './TemplateContext';

export type Target = {
  id?: string;
  name: string;
  description: string;
  total_expected: number;
  is_inverted: boolean;
};

export type KPI = {
  id: string;
  name: string;
  description: string;
  assignee_name: string;
  assignee: string;
  period: 'WEEKLY' | 'MONTHLY';
  targets: Target[];
};

export type State = {
  kpis: KPI[];
  loading: boolean;
  error: string | null;
};

type Action =
  | { type: 'SET_KPIS'; payload: KPI[] }
  | { type: 'SET_LOADING'; payload: boolean }
  | { type: 'SET_ERROR'; payload: string }
  | { type: 'UPDATE_KPI_TARGETS'; payload: { kpiId: string; targets: Target[] } }
  | { type: 'ADD_TARGET_TO_KPI'; payload: { kpiId: string; template: Target } };

const initialState: State = {
  kpis: [],
  loading: false,
  error: null
};

const KPIContext = createContext<{
  state: State;
  fetchKpis: () => Promise<void>;
  saveKpi: (kpi: KPI) => void;
  updateKpiTargets: (kpiId: string, targets: Target[]) => void;
  addTargetToKpi: (kpiId: string, template: Template) => void;
} | null>(null);

function kpiReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SET_KPIS':
      return { ...state, kpis: action.payload };
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    case 'SET_ERROR':
      return { ...state, error: action.payload };
    case 'UPDATE_KPI_TARGETS':
      return {
        ...state,
        kpis: state.kpis.map(kpi =>
          kpi.id === action.payload.kpiId
            ? { ...kpi, targets: action.payload.targets }
            : kpi
        )
      };
    case 'ADD_TARGET_TO_KPI':
      return {
        ...state,
        kpis: state.kpis.map(kpi =>
          kpi.id === action.payload.kpiId
            ? {
              ...kpi,
              targets: [...kpi.targets, {
                ...action.payload.template,
                total_expected: 0,
                is_inverted: false
              }]
            }
            : kpi
        )
      };
    default:
      return state;
  }
}

export function KPIProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(kpiReducer, initialState);

  const fetchKpis = useCallback(async () => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      const response = await axios.get('/tracker/admin/kpis/');
      dispatch({ type: 'SET_KPIS', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: 'Failed to fetch KPIs' });
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  }, []);

  const updateKpiTargets = useCallback((kpiId: string, targets: Target[]) => {
    dispatch({ type: 'UPDATE_KPI_TARGETS', payload: { kpiId, targets } });
  }, []);

  const addTargetToKpi = useCallback((kpiId: string, template: Template) => {
    dispatch({
      type: 'ADD_TARGET_TO_KPI',
      payload: {
        kpiId, template: {
          ...template,
          total_expected: 0,
        }
      }
    });
  }, []);

  const saveKpi = useCallback(async (kpi: KPI) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      await axios.put(`/tracker/admin/kpis/${kpi.id}/`, kpi);
      fetchKpis();
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: 'Failed to save KPI' });
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
      fetchKpis();
    }
  }, []);

  useEffect(() => {
    fetchKpis();
  }, [fetchKpis]);

  return (
    <KPIContext.Provider value={{
      state,
      fetchKpis,
      updateKpiTargets,
      addTargetToKpi,
      saveKpi
    }}>
      {children}
    </KPIContext.Provider>
  );
}

export function useKPI() {
  const context = useContext(KPIContext);
  if (!context) {
    throw new Error('useKPI must be used within a KPIProvider');
  }
  return context;
}