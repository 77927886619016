import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { CSSTransition } from "react-transition-group";
import axios from "axios";
import moment from "moment";

const ResourceRequestForm = ({ setRequestForm, requestForm }) => {
  const [notificationMessage, setNotificationMessage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const apiUrl = import.meta.env.VITE_API_URL;
  const user = JSON.parse(localStorage.getItem("user"))?.user_id;

  const initialValues = {
    name: "",
    due_date: "",
    priority: "LOW",
    qty: 1,
    manager_notes: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(1, "Resource name must have at least 1 character")
      .max(255, "Resource name must be at most 255 characters")
      .required("Resource name is required."),
    due_date: Yup.date().required("Due date is required."),
    priority: Yup.string()
      .oneOf(["LOW", "MEDIUM", "HIGH", "CRITICAL"], "Invalid priority")
      .required("Priority is required."),
    qty: Yup.number()
      .min(1, "Quantity must be at least 1")
      .required("Quantity is required."),
    manager_notes: Yup.string()
      .required("Notes are required")
      .min(1, "Notes must have at least 1 character"),
  });

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      const payload = {
        ...values,
        requested_by: user,
        due_date: moment(values.due_date).format("YYYY-MM-DD"),
      };

      const token = JSON.parse(localStorage.getItem("user"))?.access;
      if (!token) throw new Error("Missing authorization token");

      const response = await axios.post(
        `${apiUrl}/api/v1/tracker/resource-requests/new`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 201) {
        setRequestForm(false);
        window.location.reload();
      }
    } catch (error) {
      console.error("API Error:", error.response?.data || error.message);
      setNotificationMessage(
        error.response?.data?.message || "Error creating a new request."
      );
      setTimeout(() => setNotificationMessage(false), 3000);
    } finally {
      setIsSubmitting(false);
    }
  };

  const styles2 = {
    enter: "transform translate-x-full opacity-0",
    enterActive:
      "transform -translate-x-0 opacity-100 transition-all duration-500 ease-in-out",
    exitActive:
      "transform translate-x-full opacity-0 transition-all duration-500 ease-in-out",
  };

  return (
    <div
      className="fixed inset-0 z-50 overflow-scroll pt-24 flex items-center justify-center bg-black/70 backdrop-blur-sm"
      onClick={() => setRequestForm(false)}
    >
      <div
        className="relative bg-white rounded-lg shadow-lg w-[90%] max-w-lg animate-fadeIn p-8"
        onClick={(e) => e.stopPropagation()}
      >
        <FontAwesomeIcon
          icon={faTimes}
          onClick={() => setRequestForm(false)}
          className="float-right"
        />
        <h2 className="text-xl font-bold text-gray-800 mb-6">
          Request Resource
        </h2>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isValid }) => (
            <Form>
              <div className="mb-4">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Resource Name
                </label>
                <Field
                  type="text"
                  id="name"
                  name="name"
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md"
                />
                <ErrorMessage
                  name="name"
                  component="p"
                  className="text-red-500 text-xs mt-1"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="due_date"
                  className="block text-sm font-medium text-gray-700"
                >
                  Due Date
                </label>
                <Field
                  type="date"
                  id="due_date"
                  name="due_date"
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md"
                />
                <ErrorMessage
                  name="due_date"
                  component="p"
                  className="text-red-500 text-xs mt-1"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="priority"
                  className="block text-sm font-medium text-gray-700"
                >
                  Priority
                </label>
                <Field
                  as="select"
                  name="priority"
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md"
                >
                  <option value="LOW">LOW</option>
                  <option value="MEDIUM">MEDIUM</option>
                  <option value="HIGH">HIGH</option>
                  <option value="CRITICAL">CRITICAL</option>
                </Field>
                <ErrorMessage
                  name="priority"
                  component="p"
                  className="text-red-500 text-xs mt-1"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="qty"
                  className="block text-sm font-medium text-gray-700"
                >
                  Quantity
                </label>
                <Field
                  type="number"
                  id="qty"
                  name="qty"
                  min="1"
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md"
                />
                <ErrorMessage
                  name="qty"
                  component="p"
                  className="text-red-500 text-xs mt-1"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="manager_notes"
                  className="block text-sm font-medium text-gray-700"
                >
                  Notes
                </label>
                <Field
                  as="textarea"
                  id="manager_notes"
                  name="manager_notes"
                  rows="4"
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md"
                />
                <ErrorMessage
                  name="manager_notes"
                  component="p"
                  className="text-red-500 text-xs mt-1"
                />
              </div>

              <div className="mb-4">
                <button
                  type="submit"
                  disabled={!isValid || isSubmitting}
                  className="w-full px-4 py-2 bg-btn text-white rounded-md"
                >
                  {isSubmitting ? "Sending..." : "Submit Request"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <CSSTransition
        in={notificationMessage}
        classNames={styles2}
        timeout={500}
        unmountOnExit
      >
        <div className="p-4 shadow-xl z-50 bg-white fixed right-3 top-10 border-s-2 border-danger rounded-md flex items-center gap-4">
          <span className="flex items-center gap-4">
            <FontAwesomeIcon className="text-danger" icon={faTimesCircle} />
            <p className="font-light">{notificationMessage}</p>
          </span>
        </div>
      </CSSTransition>
    </div>
  );
};

export default ResourceRequestForm;
