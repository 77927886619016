import { Search } from 'lucide-react';
import { Input } from '@/components/ui/input';
import { useTemplate } from '@/Contexts/TemplateContext';
import { useDebouncedCallback } from 'use-debounce';

export const TemplateSearch = () => {
    const { state: { searchQuery }, setSearchQuery } = useTemplate();

    const debouncedSearch = useDebouncedCallback((value) => {
        setSearchQuery(value);
    }, 300);

    return (
        <div className="relative">
            <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-500" />
            <Input
                placeholder="Search templates..."
                defaultValue={searchQuery}
                onChange={(e) => debouncedSearch(e.target.value)}
                className="pl-8"
            />
        </div>
    );
};