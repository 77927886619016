import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ManagerAchievementRate = () => {
  const data = {
    labels: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
    datasets: [
      {
        label: "KPI Achievement Rate",
        data: [50, 90, 90, 55, 20],
        borderColor: "#FF8A00",
        backgroundColor: "rgba(79, 70, 229, 0.2)",
        tension: 0.4,
        borderWidth: 1,
        pointBackgroundColor: "#339CFF",
        pointBorderColor: "#fff",
        fill: false,
        pointRadius: 5,
        pointHoverRadius: 5,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        min: 0,
        max: 100,
        ticks: {
          stepSize: 10,
        },
        title: {
          display: false,
        },
      },
      x: {
        title: {
          display: true,
          text: "Last Week",
          padding: { top: 20 },
        },
      },
    },
  };

  return (
    <>
      <div className="">
        <div className="p-4 bg-white rounded-lg shadow-sm">
          <span className="flex items-center justify-between gap-4 mb-4">
            <h2 className="text-md font-bold">KPI Achievement Rate (%)</h2>
          </span>
          <div style={{ height: "400px" }}>
            <Line data={data} options={options} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ManagerAchievementRate;
