import React, { useState, createContext, useContext, useEffect } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import {
  format,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
} from "date-fns";
import axios from "axios";

// Context to manage the period type and current date
const PeriodContext = createContext();

// Custom hook to use the PeriodContext
const usePeriodContext = () => useContext(PeriodContext);

const PeriodContextProvider = ({ children }) => {
  const [periodType, setPeriodType] = useState("week"); // 'week' or 'month'
  const [currentDate, setCurrentDate] = useState(new Date());

  const getPeriodRange = () => {
    if (periodType === "week") {
      return {
        start: startOfWeek(currentDate),
        end: endOfWeek(currentDate),
      };
    } else {
      return {
        start: startOfMonth(currentDate),
        end: endOfMonth(currentDate),
      };
    }
  };

  const navigatePeriod = (direction) => {
    const newDate = new Date(currentDate);
    if (periodType === "week") {
      newDate.setDate(newDate.getDate() + direction * 7);
    } else {
      newDate.setMonth(newDate.getMonth() + direction);
    }
    setCurrentDate(newDate);
  };

  return (
    <PeriodContext.Provider
      value={{
        periodType,
        setPeriodType,
        currentDate,
        setCurrentDate,
        navigatePeriod,
        getPeriodRange,
      }}
    >
      {children}
    </PeriodContext.Provider>
  );
};

const PeriodTypeToggle = () => {
  const { periodType, setPeriodType, setCurrentDate } = usePeriodContext();

  return (
    <div className="flex items-center justify-between p-4 bg-white rounded-lg shadow-sm border mb-4">
      <div className="flex space-x-4">
        <button
          onClick={() => setPeriodType("week")}
          className={`px-4 py-2 rounded-md ${
            periodType === "week"
              ? "bg-blue-100 text-blue-700 font-medium"
              : "text-gray-600 hover:bg-gray-100"
          }`}
        >
          Weekly
        </button>
        <button
          onClick={() => setPeriodType("month")}
          className={`px-4 py-2 rounded-md ${
            periodType === "month"
              ? "bg-blue-100 text-blue-700 font-medium"
              : "text-gray-600 hover:bg-gray-100"
          }`}
        >
          Monthly
        </button>
      </div>

      <div className="flex items-center space-x-2">
        <button
          onClick={() => setCurrentDate(new Date())}
          className="px-3 py-1 text-sm text-blue-600 hover:bg-blue-50 rounded"
        >
          {periodType === "week" ? "This Week" : "This Month"}
        </button>
      </div>
    </div>
  );
};

const PeriodNavigation = () => {
  const { periodType, navigatePeriod, getPeriodRange } = usePeriodContext();

  const getPeriodLabel = () => {
    const { start, end } = getPeriodRange();
    if (periodType === "week") {
      return `Week of ${format(start, "MMM d")} - ${format(
        end,
        "MMM d, yyyy"
      )}`;
    } else {
      return format(start, "MMMM yyyy");
    }
  };

  return (
    <div className="flex items-center justify-between p-4 bg-white rounded-lg mb-[24px] shadow-sm border">
      <button
        onClick={() => navigatePeriod(-1)}
        className="p-2 text-gray-600 hover:bg-gray-100 rounded-full"
      >
        <ChevronLeft className="w-5 h-5" />
      </button>

      <div className="flex flex-col items-center">
        <h2 className="text-lg font-semibold text-gray-900">
          {getPeriodLabel()}
        </h2>
        <p className="text-sm text-gray-500">
          {periodType === "week" ? "Weekly KPIs" : "Monthly KPIs"}
        </p>
      </div>

      <button
        onClick={() => navigatePeriod(1)}
        className="p-2 text-gray-600 hover:bg-gray-100 rounded-full"
      >
        <ChevronRight className="w-5 h-5" />
      </button>
    </div>
  );
};

const PeriodSummary = () => {
  const user = JSON.parse(localStorage.getItem("user"))?.user_type;
  const apiUrl = import.meta.env.VITE_API_URL;
  const token = JSON.parse(localStorage.getItem("user"))?.access;
  const id = JSON.parse(localStorage.getItem("user"))?.user_id;
  const [quickStats, setQuickStats] = useState({
    totalKpis: 0,
    completed: 0,
    undone: 0,
    completionRate: 0,
  });

  useEffect(() => {
    const fetchQuickStats = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/v1/tracker/admin/kpis/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const kpiData = response.data.data;

        const managerKpis =
          user === "MANAGER"
            ? kpiData.filter((kpi) => kpi.user_id === id)
            : kpiData;

        const completedTasks = kpiData.filter(
          (kpi) => kpi.percentage_completed === 100
        ).length;

        const undoneTasks = managerKpis.filter(
          (kpi) => kpi.actual_completed === 0
        ).length;

        const totalExpected = managerKpis.reduce(
          (sum, kpi) => sum + (kpi.total_expected || 0),
          0
        );

        const totalCompleted = managerKpis.reduce(
          (sum, kpi) => sum + (kpi.actual_completed || 0),
          0
        );

        const completionRate =
          totalExpected > 0
            ? ((totalCompleted / totalExpected) * 100).toFixed(2)
            : "0";

        setQuickStats({
          totalKpis: managerKpis.length,
          completed: completedTasks,
          undone: undoneTasks,
          completionRate,
        });
      } catch (error) {
        console.error("Error fetching quick stats:", error);
      }
    };

    if (id) fetchQuickStats();
  }, [apiUrl, token, id]);
  const { getPeriodRange } = usePeriodContext();
  const { start, end } = getPeriodRange();

  return (
    <div className="mt-4 p-4 bg-blue-50 rounded-lg border mb-[24px] border-blue-100">
      <div className="flex justify-between items-center">
        <div>
          <p className="text-sm font-medium text-blue-900">Current Period</p>
          <p className="text-sm text-blue-700">
            {format(start, "MMM d, yyyy")} - {format(end, "MMM d, yyyy")}
          </p>
        </div>
        <div className="text-right">
          <p className="text-sm font-medium text-blue-900">All KPI Status</p>
          <p className="text-sm text-blue-700">
            {quickStats.completed} of {quickStats.totalKpis} Completed
          </p>
        </div>
      </div>
    </div>
  );
};

const PeriodContextSelector = () => {
  return (
    <PeriodContextProvider>
      <div className="w-full">
        {/* Period Type Toggle */}
        <PeriodTypeToggle />

        {/* Period Navigation */}
        <PeriodNavigation />

        {/* Period Summary */}
        <PeriodSummary />
      </div>
    </PeriodContextProvider>
  );
};

export default PeriodContextSelector;
