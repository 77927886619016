import { Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "./Pages/Login";
import ResetPassword from "./Pages/ResetPassword";
import Dashboard from "./Pages/Dashboard";
import UpdatePassword from "./Pages/UpdatePassword";
import OTP from "./Pages/OTP";
import NotFound from "./Routes/NotFound";
import Unauthorized from "./Routes/Auth/Unauthorized";
import PrivateRoute from "./Routes/Auth/PrivateRoute";
import Managers from "./Pages/Managers";
import AuthLayout from "./Layouts/AuthLayout";
import PublicLayout from "./Layouts/PublicLayout";
import ManagerDetails from "./Routes/ManagerDetails";
import Title from "./Components/Title";
import KPIManagement from "./Pages/KpiManagement";
import Tasks from "./Pages/Tasks";
import CarePackages from "./Pages/CarePackages";
import ResourceRequest from "./Pages/ResourceRequest";
import ManagerKpiDetails from "./Pages/ManagerKpiDetails";
import ManagerCarePackage from "./Pages/ManagerCarePackage";
import ManagerResourceRequest from "./Pages/ManagerResourceRequest";
import Profile from "./Pages/Profile";
import EditProfile from "./Routes/EditProfile";
import ChangePassword from "./Routes/ChangePassword";
import ManagerClientFeedback from "./Pages/ManagerClientFeedback";
import ClientFeedback from "./Pages/ClientFeedback";
import Home from "./Pages/Home";
import Kpi from "./Pages/Kpi";
import KpiReport from "./Pages/KpiReport";
import CreateKPIPage from "./Pages/CreateKpiPage";
import EditKPIPage from "./Pages/EditKpiPage";
import ManagerSessions from './Pages/ManagerSessions'

function App() {
  return (
    <>
      <Routes>
        {/* Routes under PublicLayout */}
        <Route element={<PublicLayout />}>
          <Route
            path="/"
            index
            element={
              <Title title="Home - Trakitt">
                <Home />
              </Title>
            }
          />
          <Route
            path="/login"
            index
            element={
              <Title title="Login - Trakitt">
                <Login />
              </Title>
            }
          />
          <Route
            path="/reset_password"
            element={
              <Title title="Reset Password">
                <ResetPassword />
              </Title>
            }
          />
          <Route
            path="/otp_validation"
            element={
              <Title title="OTP Validation">
                <OTP />
              </Title>
            }
          />
          <Route
            path="/update_password"
            element={
              <Title title="Update Password">
                <UpdatePassword />
              </Title>
            }
          />
          <Route
            path="/unauthorized"
            element={
              <Title title="Error">
                <Unauthorized />
              </Title>
            }
          />
        </Route>

        {/* Routes under AuthLayout */}
        <Route element={<AuthLayout />}>
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Title title="Dashboard - Trakitt">
                  <Dashboard />
                </Title>
              </PrivateRoute>
            }
          />
          <Route
            path="/managers"
            element={
              <PrivateRoute>
                <Title title="Managers - Trakitt">
                  <Managers />
                </Title>
              </PrivateRoute>
            }
          />
          <Route
            path="/managers/:id"
            element={
              <PrivateRoute>
                <Title title="Manager Details - Trakitt">
                  <ManagerDetails />
                </Title>
              </PrivateRoute>
            }
          />
          <Route
            path="/managers/:managerId/sessions"
            element={
              <PrivateRoute>
                <Title title="Manager Sessions - Trakitt">
                  <ManagerSessions />
                </Title>
              </PrivateRoute>
            }
          />
          <Route
            path="/kpi"
            element={
              <PrivateRoute>
                <Title title="KPI Dashboard - Trakitt">
                  <Kpi />
                </Title>
              </PrivateRoute>
            }
          />
          <Route
            path="/new_kpi"
            element={
              <PrivateRoute>
                <Title title="New KPI - Trakitt">
                  <CreateKPIPage />
                </Title>
              </PrivateRoute>
            }
          />
          <Route
            path="/kpi_management"
            element={
              <PrivateRoute>
                <Title title="KPI Management - Trakitt">
                  <KPIManagement />
                </Title>
              </PrivateRoute>
            }
          />
          <Route
            path="/edit_kpi/:id"
            element={
              <PrivateRoute>
                <Title title="Edit KPI - Trakitt">
                  <EditKPIPage />
                </Title>
              </PrivateRoute>
            }
          />
          <Route
            path="/report_kpi/:id"
            element={
              <PrivateRoute>
                <Title title="KPI Report - Trakitt">
                  <KpiReport />
                </Title>
              </PrivateRoute>
            }
          />
          <Route
            path="/task"
            element={
              <PrivateRoute>
                <Title title="Tasks Overview - Trakitt">
                  <Tasks />
                </Title>
              </PrivateRoute>
            }
          />
          <Route
            path="/care_packages"
            element={
              <PrivateRoute>
                <Title title="Care Packages - Trakitt">
                  <CarePackages />
                </Title>
              </PrivateRoute>
            }
          />
          <Route
            path="/resource_request"
            element={
              <PrivateRoute>
                <Title title="Resource Request - Trakitt">
                  <ResourceRequest />
                </Title>
              </PrivateRoute>
            }
          />
          <Route
            path="/manager_kpi_details"
            element={
              <PrivateRoute>
                <Title title="KPI Overview - Trakitt">
                  <ManagerKpiDetails />
                </Title>
              </PrivateRoute>
            }
          />
          <Route
            path="/manager_care_package"
            element={
              <PrivateRoute>
                <Title title="Care Packages - Trakitt">
                  <ManagerCarePackage />
                </Title>
              </PrivateRoute>
            }
          />
          <Route
            path="/manager_resource_request"
            element={
              <PrivateRoute>
                <Title title="Resource Request - Trakitt">
                  <ManagerResourceRequest />
                </Title>
              </PrivateRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <Title title="Profile - Trakitt">
                  <Profile />
                </Title>
              </PrivateRoute>
            }
          />
          <Route
            path="/profile/edit_profile"
            element={
              <PrivateRoute>
                <Title title="Edit Profile - Trakitt">
                  <EditProfile />
                </Title>
              </PrivateRoute>
            }
          />
          <Route
            path="/profile/password_update"
            element={
              <PrivateRoute>
                <Title title="Update Password - Trakitt">
                  <ChangePassword />
                </Title>
              </PrivateRoute>
            }
          />
          <Route
            path="/client_feedback"
            element={
              <PrivateRoute>
                <Title title="Client Feedback - Trakitt">
                  <ManagerClientFeedback />
                </Title>
              </PrivateRoute>
            }
          />
          <Route
            path="/admin_client_feedback"
            element={
              <PrivateRoute>
                <Title title="Client Feedback - Trakitt">
                  <ClientFeedback />
                </Title>
              </PrivateRoute>
            }
          />
        </Route>

        {/* Fallback Route */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
