import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Line, Bar, Chart } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { CSSTransition } from "react-transition-group";
import EditPopup from "../Components/EditPopup";
import DeletePopup from "../Components/DeletePopup";
import Popup from "../Components/Popup";
import ManagerTasks from "../Components/ManagerTasks";
import axios from "axios";
import PeriodContextSelector from "../Components/PeriodContextSelector";
import QuickStats from "../Components/QuickStats";
import QuickStatsManager from "../Components/QuickStatsManager";
import QuickTasks from "../Components/QuickTasks";

const ManagerDetails = () => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const { id } = useParams();
  const [selectedOption, setSelectedOption] = useState("Last 7 Days");
  const [popup, setPopup] = useState(false);
  const [edit, setEdit] = useState(false);
  const [erase, setErase] = useState(false);
  const [quickStats, setQuickStats] = useState({
    totalKpis: 0,
    completed: 0,
    undone: 0,
    completionRate: 0,
  });
  const [activeTab, setActiveTab] = useState("KPI");
  const [manager, setManager] = useState("");
  const navigate = useNavigate();
  const apiUrl = import.meta.env.VITE_API_URL;
  const token = JSON.parse(localStorage.getItem("user"))?.access;
  const [loading, setLoading] = useState(true);
  const userId = useParams().id;

  const styles = {
    enter: "transform translate-y-full opacity-0",
    enterActive:
      "transform -translate-y-0 opacity-100 transition-all duration-500 ease-in-out",
    exitActive:
      "transform translate-y-full opacity-0 transition-all duration-500 ease-in-out",
  };

  useEffect(() => {
    if (popup || edit || erase) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [popup, edit, erase]);

  useEffect(() => {
    const fetchManagers = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/api/v1/auth/users/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const filteredManagers = response.data.data.filter(
          (user) => user.user_type === "MANAGER"
        );

        setManager(filteredManagers);
      } catch (error) {
        console.error("Error fetching managers:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchManagers();
  }, []);

  useEffect(() => {
    const fetchQuickStats = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/v1/tracker/admin/kpis/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const kpiData = response.data.data;

        const managerKpiData = kpiData
          .filter((kpi) => kpi?.user_id && kpi.user_id === id)
          .map((kpi) => {
            const totalTargets = kpi.targets.length;

            const completedTargets = kpi.targets.filter((target) => {
              if (target.is_inverted) {
                return target.total_expected === 0;
              }
              return target.total_expected > 0;
            }).length;

            const percentageCompleted = totalTargets
              ? (completedTargets / totalTargets) * 100
              : 0;

            return {
              ...kpi,
              percentage_completed: percentageCompleted,
            };
          });

        const today = new Date();
        const startOfToday = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate()
        );

        const completedTasks = managerKpiData.filter(
          (kpi) => kpi.percentage_completed === 100
        ).length;

        const undoneTasks = managerKpiData.filter((kpi) => {
          const dueDate = new Date(kpi.due_date);

          const dayAfterDueDate = new Date(
            dueDate.getFullYear(),
            dueDate.getMonth(),
            dueDate.getDate() + 1
          );

          return (
            kpi.percentage_completed < 100 && dayAfterDueDate <= startOfToday
          );
        }).length;

        const totalExpected = managerKpiData.length;
        const totalCompleted = completedTasks;

        const completionRate = totalExpected
          ? ((totalCompleted / totalExpected) * 100).toFixed(2)
          : "N/A";

        setQuickStats({
          totalKpis: totalExpected,
          completed: completedTasks,
          undone: undoneTasks,
          completionRate: completionRate,
        });
      } catch (error) {
        console.error("Error fetching quick stats:", error);
      }
    };

    fetchQuickStats();
  }, [apiUrl, token]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="loader border-t-4 border-btn rounded-full w-12 h-12 animate-spin"></div>
      </div>
    );
  }

  if (!manager || manager.length === 0) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p>Manager not found!</p>
      </div>
    );
  }

  if (!manager) {
    return <div>Manager not found!</div>;
  }
  const selectedItem = manager.find((item) => item.id === id);
  return (
    <>
      <div className="bg-[#F8F9FC] md:ml-[350px] p-4">
        <div className="bg-white flex items-center justify-between p-4 mb-3 rounded-md shadow-sm">
          <span className="flex items-center gap-2">
            <FontAwesomeIcon onClick={() => navigate(-1)} icon={faArrowLeft} />
            <h2 className="font-bold text-sm">Manager Performance Details</h2>
          </span>
        </div>

        <div className="mt-6 mb-6 bg-white p-2 rounded-lg shadow-xl">
          <div className="flex w-full gap-2 justify-between">
            <span
              className={`cursor-pointer text-sm font-medium py-3 px-6 rounded-md transition-colors duration-300 w-full text-center ${
                activeTab === "KPI"
                  ? "bg-blue-600 text-white shadow-lg"
                  : "text-gray-700 hover:bg-blue-50"
              }`}
              onClick={() => setActiveTab("KPI")}
            >
              KPI
            </span>
            <span
              className={`cursor-pointer text-sm font-medium py-3 px-6 rounded-md transition-colors duration-300 w-full text-center ${
                activeTab === "TASKS"
                  ? "bg-blue-600 text-white shadow-lg"
                  : "text-gray-700 hover:bg-blue-50"
              }`}
              onClick={() => setActiveTab("TASKS")}
            >
              TASKS
            </span>
          </div>
        </div>

        {selectedItem ? (
          <>
            <PeriodContextSelector />
            <div className="">
              <div className="bg-white p-6 rounded-md shadow-md">
                <span className="flex justify-between items-center">
                  <h2 className="text-xl font-bold">
                    {selectedItem.last_name} {selectedItem.first_name}
                  </h2>
                  <FontAwesomeIcon
                    onClick={() => setPopup(true)}
                    icon={faEllipsis}
                  />
                </span>
                <p>
                  <strong>Email:</strong> {selectedItem.email}
                </p>
                <p>
                  <strong>Phone Number:</strong> {selectedItem.phone}
                </p>
              </div>
            </div>

            <div className="mb-24 mt-4">
              {activeTab === "TASKS" && <QuickTasks />}
              {activeTab === "KPI" && (
                <QuickStatsManager quickStats={quickStats} />
              )}
              {activeTab === "TASKS" && <ManagerTasks />}
            </div>
            <EditPopup
              selectedItem={selectedItem}
              setEdit={setEdit}
              styles={styles}
              edit={edit}
            />
          </>
        ) : (
          <div className="text-center mt-4">Manager details not found!</div>
        )}
      </div>

      <Popup
        userId={userId}
        setEdit={setEdit}
        setErase={setErase}
        styles={styles}
        popup={popup}
        setPopup={setPopup}
      />

      <DeletePopup
        CSSTransition={CSSTransition}
        apiUrl={apiUrl}
        toke={token}
        user_id={id}
        erase={erase}
        setErase={setErase}
        styles={styles}
      />
    </>
  );
};

export default ManagerDetails;
