import { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import axios from "axios";
import Navbar from "../Components/Navbar";
import BottomNav from "../Components/BottomNav";
import { CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import LogoutTimer from "../Components/LogoutTimer";
import { logoutUser } from "../lib/auth-utils";

const AuthLayout = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const apiUrl = import.meta.env.VITE_API_URL;
  const token = JSON.parse(localStorage.getItem("user"))?.access;
  const user_id = JSON.parse(localStorage.getItem("user"))?.user_id;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNotifications = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${apiUrl}/api/v1/notifications/all?recipient=${user_id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        // Filter unread notifications
        const unreadNotifications = response.data.data.filter(
          (notif) => !notif.is_read
        );

        // Set unread notifications
        setNotifications(unreadNotifications);

        // Show popup if there are unread notifications
        if (unreadNotifications.length > 0) {
          setShowPopup(true);
          setTimeout(() => {
            setShowPopup(false);
          }, 4000);
        }

        setLoading(false);
      } catch (error) {
        console.error(error.response?.data || error.message);
        setLoading(false);
      }
    };

    fetchNotifications();
  }, [apiUrl, token, user_id]);

  const styles = {
    enter: "transform -translate-y-full opacity-0",
    enterActive:
      "transform translate-y-0 opacity-100 transition-all duration-500 ease-in-out",
    exitActive:
      "transform -translate-y-full opacity-0 transition-all duration-500 ease-in-out",
  };

  return (
    <div>
      <LogoutTimer
        onLogout={() => {
          logoutUser().then(() => navigate("/login"));
        }}
        inactivityTimeout={1_800_000}
        warningDuration={30}
      />
      <Navbar />
      <CSSTransition
        in={showPopup}
        timeout={500}
        classNames={styles}
        unmountOnExit
      >
        <div className="fixed top-10 left-1/2 transform -translate-x-1/2 z-50 bg-white shadow-xl rounded-lg border border-gray-200 p-6 w-[90%]">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-bold text-gray-800">
              Unread Notifications
            </h3>
            <button
              onClick={() => setShowPopup(false)}
              className="text-gray-500 hover:text-gray-700 transition-colors"
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          {loading ? (
            <button type="submit" disabled className="mt-8">
              <section className="dots-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </section>
            </button>
          ) : (
            <div className="text-sm text-gray-700">
              You have{" "}
              <span className="font-bold text-blue-500">
                {notifications.length}
              </span>{" "}
              unread notification(s).
            </div>
          )}
        </div>
      </CSSTransition>

      <main>
        <Outlet />
      </main>

      <BottomNav />
    </div>
  );
};

export default AuthLayout;
