import { Edit, GripVertical, Trash } from 'lucide-react';
import { useState } from 'react';
import { useTemplate, Template } from '@/Contexts/TemplateContext';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { NewTemplateDialog } from './NewTemplateDialog';
import { Dialog, DialogTrigger } from '@/components/ui/dialog';

export const TemplateItem = ({ template, provided }: { template: Template; provided: any }) => {
  const { deleteTemplate } = useTemplate();
  const [showDialog, setShowDialog] = useState(false);

  return (
    <>
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        className="p-3 bg-white border rounded-lg shadow-sm hover:shadow"
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <GripVertical className="h-4 w-4 text-gray-400" />
            <div>
              <div className="flex justify-between items-center">
                <p className="font-medium text-sm mr-2">{template.name}</p>
                {template.is_inverted && (
                  <span className="text-xs bg-black text-white px-1.5 rounded-full">
                    inverted
                  </span>
                )}
              </div>
              <p className="text-xs text-gray-500">{template.description}</p>
            </div>
          </div>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" size="icon" className="h-8 w-8">
                <Edit className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem onClick={() => setShowDialog(true)}>
                <Edit className="h-4 w-4 mr-2" />
                Edit
              </DropdownMenuItem>
              <DropdownMenuItem
                className="text-red-600"
                onClick={() => deleteTemplate(template.id)}
              >
                <Trash className="h-4 w-4 mr-2" />
                Delete
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        <Dialog open={showDialog} onOpenChange={setShowDialog}>
          <NewTemplateDialog
            isOpen={showDialog}
            onClose={() => setShowDialog(false)}
            template={template}
          />
        </Dialog>
      </div>
    </>
  );
};
