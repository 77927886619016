import axios from "axios";
import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faCheckCircle,
  faTimes,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { CSSTransition } from "react-transition-group";
import { Link, useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [errorNotification, setErrorNotification] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const apiUrl = import.meta.env.VITE_API_URL;
  const token = JSON.parse(localStorage.getItem("user"))?.access;
  const mail = localStorage.getItem("email");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchResources = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/v1/auth/me`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUser(response.data);
        localStorage.setItem("email", response.data.email);
      } catch (err) {
        console.error("API Error:", err.response?.data || err.message);

        if (err.response) {
          const { status, data } = err.response;

          const errorMessage =
            data?.message ||
            data?.error ||
            "An error occurred. Please try again.";

          switch (status) {
            case 400:
              setErrorNotification(
                errorMessage ||
                  "Invalid OTP. Please check the code you entered."
              );
              break;
            case 401:
              setErrorNotification(
                errorMessage ||
                  "Unauthorized access. Please verify your identity."
              );
              break;
            case 404:
              setErrorNotification(
                errorMessage || "OTP not found or has expired."
              );
              break;
            case 500:
              setErrorNotification(
                errorMessage || "Server error. Please try again later."
              );
              break;
            default:
              setErrorNotification(errorMessage);
              break;
          }
        } else if (err.request) {
          setErrorNotification(
            "No response from the server. Please check your internet connection."
          );
        } else {
          setErrorNotification("An unexpected error occurred.");
        }

        setTimeout(() => {
          setErrorNotification(false);
        }, 5000);
      } finally {
        setLoading(false);
      }
    };

    fetchResources();
  }, [apiUrl, token]);

  const initialValues = {
    password: "",
    confirm_password: "",
    email: mail || "",
  };

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters long"),
    confirm_password: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
    email: yup
      .string("Please use a valid email address")
      .email()
      .required("Email is required"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    setIsSubmitting(true);
    try {
      const response = await axios.post(
        `${apiUrl}/api/v1/auth/reset-password/`,
        values,
        {
          withCredentials: true,
        }
      );
      resetForm();
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 8000);
      navigate("/login");
      localStorage.removeItem("email");
    } catch (err) {
      console.error("API Error:", err.response?.data || err.message);
      setIsSubmitting(false);

      if (err.response) {
        const { status, data } = err.response;

        const errorMessage =
          data?.message ||
          data?.error ||
          "An error occurred. Please try again.";

        switch (status) {
          case 400:
            setErrorNotification(
              errorMessage || "Invalid OTP. Please check the code you entered."
            );
            break;
          case 401:
            setErrorNotification(
              errorMessage ||
                "Unauthorized access. Please verify your identity."
            );
            break;
          case 404:
            setErrorNotification(
              errorMessage || "OTP not found or has expired."
            );
            break;
          case 500:
            setErrorNotification(
              errorMessage || "Server error. Please try again later."
            );
            break;
          default:
            setErrorNotification(errorMessage);
            break;
        }
      } else if (err.request) {
        setErrorNotification(
          "No response from the server. Please check your internet connection."
        );
      } else {
        setErrorNotification("An unexpected error occurred.");
      }

      setTimeout(() => {
        setErrorNotification(false);
      }, 3000);
    } finally {
      if (isSubmitting) {
        setIsSubmitting(false);
      }
    }
  };

  const styles = {
    enter: "transform translate-x-full opacity-0",
    enterActive:
      "transform -translate-x-0 opacity-100 transition-all duration-500 ease-in-out",
    exitActive:
      "transform translate-x-full opacity-0 transition-all duration-500 ease-in-out",
  };

  return (
    <>
      {/* Loading Popup */}
      {loading && (
        <div className="fixed inset-0 z-50 flex justify-center items-center bg-slate-200/20 bg-opacity-50 backdrop-blur-sm">
          <div className="z-50 flex justify-center items-center h-12">
            <div className="loader border-t-4 border-btn rounded-full w-4 h-4 animate-spin"></div>
          </div>
        </div>
      )}

      <div className="p-4 flex justify-center flex-col md:ml-[350px] items-center">
        <h2 className="text-3xl font-bold p-4">Set New Password</h2>
        <div className="w-full max-w-md p-4">
          {user ? (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form className="space-y-4">
                  {/* Password Field */}
                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Password
                    </label>
                    <div className="relative">
                      <Field
                        type={showPassword ? "text" : "password"}
                        name="password"
                        id="password"
                        placeholder="Enter new password"
                        className="mt-1 block w-full px-3 py-2 border rounded-md focus:ring-primary focus:border-primary sm:text-sm"
                      />
                      <FontAwesomeIcon
                        icon={showPassword ? faEyeSlash : faEye}
                        onClick={() => setShowPassword(!showPassword)}
                        className="absolute right-3 top-3 text-gray-500 cursor-pointer"
                      />
                    </div>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>

                  {/* Confirm Password Field */}
                  <div>
                    <label
                      htmlFor="confirm_password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Confirm Password
                    </label>
                    <div className="relative">
                      <Field
                        type={showConfirmPassword ? "text" : "password"}
                        name="confirm_password"
                        id="confirm_password"
                        placeholder="Confirm new password"
                        className="mt-1 block w-full px-3 py-2 border rounded-md focus:ring-primary focus:border-primary sm:text-sm"
                      />
                      <FontAwesomeIcon
                        icon={showConfirmPassword ? faEyeSlash : faEye}
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        className="absolute right-3 top-3 text-gray-500 cursor-pointer"
                      />
                    </div>
                    <ErrorMessage
                      name="confirmPassword"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>
                  <Field
                    type="email"
                    name="email"
                    placeholder="Email"
                    readOnly="true"
                    className="mt-1 block w-full px-3 py-2 border rounded-md focus:ring-primary focus:border-primary sm:text-sm"
                  />

                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="w-full py-2 px-4 bg-btn text-white font-semibold rounded-md transition duration-300"
                  >
                    {isSubmitting ? "Saving..." : "Change Password"}
                  </button>
                </Form>
              )}
            </Formik>
          ) : (
            <p className="text-center text-gray-500">Loading user data...</p>
          )}
        </div>
      </div>

      {/* Notifications */}
      <CSSTransition
        in={showNotification}
        classNames={styles}
        timeout={500}
        unmountOnExit
      >
        <div className="p-4 shadow-xl z-50 bg-white fixed right-3 top-10 border-s-2 border-success rounded-md flex items-center gap-4">
          <span className="flex items-center gap-4">
            <FontAwesomeIcon className="text-success" icon={faCheckCircle} />
            <p className="font-light">
              Your password has been successfully updated!{" "}
              <Link to="/">
                <u>Please login again</u>
              </Link>
            </p>
          </span>
        </div>
      </CSSTransition>

      <CSSTransition
        in={errorNotification}
        classNames={styles}
        timeout={500}
        unmountOnExit
      >
        <div className="p-4 shadow-xl z-50 bg-white fixed right-3 top-10 border-s-2 border-danger rounded-md flex items-center gap-4">
          <span className="flex items-center gap-4">
            <FontAwesomeIcon className="text-danger" icon={faTimes} />
            <p className="font-light">{errorNotification}</p>
          </span>
        </div>
      </CSSTransition>
    </>
  );
};

export default ChangePassword;
