import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faPlus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Select from "react-select";
import AddClientsForm from "./AddClientsForm";
import { CSSTransition } from "react-transition-group";

const AddCarePackage = ({ addCarePackage, setAddCarePackage }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [errorNotification, setErrorNotification] = useState(false);
  const apiUrl = import.meta.env.VITE_API_URL;
  const token = JSON.parse(localStorage.getItem("user"))?.access;
  const id = JSON.parse(localStorage.getItem("user"))?.user_id;
  const recordedBy = JSON.parse(localStorage.getItem("user"))?.user_id;

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/v1/tracker/clients/all?manager=${id}&logged_by=${id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setClients(response.data.data);
      } catch (error) {
        console.error(
          "Error fetching clients:",
          error.response?.data || error.message
        );
      }
    };

    fetchClients();
  }, [apiUrl, token]);

  const handleClientAdded = (newClient) => {
    setClients((prevClients) => [...prevClients, newClient]);
  };

  const formik = useFormik({
    initialValues: {
      client: "",
      start_date: "",
      hours_of_care_received: "",
    },
    validationSchema: Yup.object({
      client: Yup.string().required("Client is required"),
      start_date: Yup.date().required("Start Date is required"),
      hours_of_care_received: Yup.number()
        .nullable()
        .typeError("Hours of care must be a number"),
    }),
    onSubmit: async (values) => {
      const payload = {
        ...values,
        recorded_by: recordedBy,
      };

      try {
        setLoading(true);
        const response = await axios.post(
          `${apiUrl}/api/v1/tracker/care-plans/new`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
            withCredentials: true,
          }
        );
        setLoading(false);

        setShowNotification("Care Package added!");
        setTimeout(() => {
          setShowNotification(null);
        }, 2000);
        setAddCarePackage(false);
        window.location.reload();
      } catch (error) {
        setLoading(false);

        let errorMessage = "There was an error adding this care package";
        if (
          Array.isArray(error.response?.data) &&
          error.response.data[0]?.error
        ) {
          errorMessage = error.response.data[0].error;
        }
        setErrorNotification(errorMessage);
        setTimeout(() => {
          setErrorNotification(null);
        }, 4000);

        console.error(
          "Error saving data:",
          error.response?.data || error.message
        );
      }
    },
  });

  const clientOptions = clients.map((client) => ({
    value: client.id,
    label: `${client.last_name} ${client.first_name}`,
  }));

  const styles = {
    enter: "transform translate-x-full opacity-0",
    enterActive:
      "transform -translate-x-0 opacity-100 transition-all duration-500 ease-in-out",
    exitActive:
      "transform translate-x-full opacity-0 transition-all duration-500 ease-in-out",
  };

  return (
    <>
      <div
        className="fixed inset-0 overflow-scroll z-50 flex items-center justify-center bg-black/70 backdrop-blur-sm"
        onClick={() => setAddCarePackage(false)}
      >
        <div
          className="relative bg-white rounded-lg shadow-lg w-[90%] max-w-lg animate-fadeIn p-8"
          onClick={(e) => e.stopPropagation()}
        >
          <button
            onClick={() => setAddCarePackage(false)}
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
          >
            <FontAwesomeIcon icon={faTimes} className="h-6 w-6" />
          </button>
          <h3 className="text-3xl font-bold text-gray-800 mb-6 text-center">
            Add Care Package
          </h3>

          <form onSubmit={formik.handleSubmit} className="space-y-6">
            <div>
              <label
                htmlFor="client"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Client
              </label>
              <div className="flex items-center gap-3">
                <Select
                  id="client"
                  name="client"
                  options={clientOptions}
                  onChange={(selectedOption) =>
                    formik.setFieldValue("client", selectedOption.value)
                  }
                  value={clientOptions.find(
                    (option) => option.value === formik.values.client
                  )}
                  className="w-full"
                />

                {formik.touched.client && formik.errors.client && (
                  <div className="text-red-600 text-sm mt-1">
                    {formik.errors.client}
                  </div>
                )}
                <FontAwesomeIcon
                  onClick={() => setIsOpen(true)}
                  className="bg-btn text-white p-2 rounded-full"
                  icon={faPlus}
                />
              </div>
            </div>

            {[
              {
                name: "start_date",
                label: "Start Date",
                type: "date",
              },
              {
                name: "hours_of_care_received",
                label: "Hours/Week",
                type: "number",
              },
            ].map(({ name, label, type }) => (
              <div key={name}>
                <label
                  htmlFor={name}
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  {label}
                </label>
                <input
                  type={type}
                  id={name}
                  name={name}
                  className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-400 focus:outline-none"
                  value={formik.values[name]}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched[name] && formik.errors[name] && (
                  <div className="text-red-600 text-sm mt-1">
                    {formik.errors[name]}
                  </div>
                )}
              </div>
            ))}

            <button
              type="submit"
              className="w-full bg-btn text-white py-3 rounded-lg font-medium hover:bg-orange-600 transition"
            >
              {loading ? "Saving..." : "Save Care Package"}
            </button>
          </form>
        </div>
        {isOpen && (
          <AddClientsForm
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            onClientAdded={handleClientAdded}
          />
        )}

        {/* Notifications */}
        <CSSTransition
          in={showNotification}
          classNames={styles}
          timeout={500}
          unmountOnExit
        >
          <div className="p-4 shadow-xl z-50 bg-white fixed right-3 top-10 border-s-2 border-success rounded-md flex items-center gap-4">
            <span className="flex items-center gap-4">
              <FontAwesomeIcon className="text-success" icon={faCheckCircle} />
              <p className="font-light">{showNotification}</p>
            </span>
          </div>
        </CSSTransition>

        <CSSTransition
          in={errorNotification}
          classNames={styles}
          timeout={500}
          unmountOnExit
        >
          <div className="p-4 shadow-xl z-50 bg-white fixed right-3 top-10 border-s-2 border-danger rounded-md flex items-center gap-4">
            <span className="flex items-center gap-4">
              <FontAwesomeIcon className="text-danger" icon={faTimes} />
              <p className="font-light">{errorNotification}</p>
            </span>
          </div>
        </CSSTransition>
      </div>
    </>
  );
};

export default AddCarePackage;
