// PeriodContext.jsx
import React, { createContext, useState, useContext } from "react";
import { startOfWeek, endOfWeek, startOfMonth, endOfMonth } from "date-fns";

// Create Context
const PeriodContext = createContext();

// Custom Hook to use the Context
export const usePeriodContext = () => useContext(PeriodContext);

// Period Provider Component
export const PeriodProvider = ({ children }) => {
  const [periodType, setPeriodType] = useState("week"); // 'week' or 'month'
  const [currentDate, setCurrentDate] = useState(new Date());

  // Helper functions to get period range
  const getPeriodRange = () => {
    if (periodType === "week") {
      return {
        start: startOfWeek(currentDate),
        end: endOfWeek(currentDate),
      };
    } else {
      return {
        start: startOfMonth(currentDate),
        end: endOfMonth(currentDate),
      };
    }
  };

  const navigatePeriod = (direction) => {
    const newDate = new Date(currentDate);
    if (periodType === "week") {
      newDate.setDate(newDate.getDate() + direction * 7);
    } else {
      newDate.setMonth(newDate.getMonth() + direction);
    }
    setCurrentDate(newDate);
  };

  const resetToCurrent = () => setCurrentDate(new Date());

  return (
    <PeriodContext.Provider
      value={{
        periodType,
        setPeriodType,
        currentDate,
        setCurrentDate,
        getPeriodRange,
        navigatePeriod,
        resetToCurrent,
        usePeriodContext
      }}
    >
      {children}
    </PeriodContext.Provider>
  );
};
