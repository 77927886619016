import {
  faArrowLeft,
  faClipboardCheck,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import axios from "axios";
import KpiReportForm from "../Components/KpiReportForm";

const KpiReport = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { manager } = location.state || {};
  const [report, setReport] = useState(false);
  const date = Date.now();

  if (!manager) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="bg-white p-6 rounded-md shadow-md text-center">
          <h1 className="text-xl font-semibold text-gray-700">
            No data passed for the selected manager.
          </h1>
          <button
            onClick={() => navigate(-1)}
            className="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
          >
            Go Back
          </button>
        </div>
      </div>
    );
  }

  const getStatusClass = (status) => {
    switch (status) {
      case "SUBMITTED":
        return "bg-green-100 text-green-700";
      case "Undone":
        return "bg-red-100 text-red-700";
      case "PENDING":
        return "bg-yellow-100 text-yellow-700";
      default:
        return "bg-gray-100 text-gray-700";
    }
  };

  const myClassNames = {
    enter: "opacity-0",
    enterActive: "opacity-100 transition-opacity duration-500 ease-in-out",
    exitActive: "opacity-0 transition-opacity duration-500 ease-in-out",
  };

  return (
    <>
      <div className="p-4 md:ml-[350px] mb-24 bg-[#F8F9FC]">
        <div className="p-4 bg-white rounded-md shadow-md mb-4">
          <span className="flex items-center gap-3">
            <FontAwesomeIcon onClick={() => navigate(-1)} icon={faArrowLeft} />
            <h2 className="text-md font-bold">KPI Report</h2>
          </span>
        </div>
        <div className="grid gap-4">
          <div>
            <div
              key={manager.id}
              className="p-4 border rounded-md shadow-md hover:shadow-lg cursor-pointer transition-shadow"
            >
              <div className="flex justify-between items-center mb-2">
                <h4 className="text-lg font-semibold text-gray-700">
                  {manager.name}
                </h4>
                <span
                  className={`inline-block mt-2 px-3 py-1 rounded-full text-xs font-medium whitespace-nowrap ${getStatusClass(
                    manager.status || "In Progress"
                  )}`}
                >
                  {manager.status || "In Progress"}
                </span>
              </div>
              <h4 className="text-[13px] text-gray-600">
                <strong>Descrition:</strong> {manager.description}
              </h4>
              <span className="flex justify-between items-center mt-2 mb-2">
                <p className="text-[13px] text-gray-600">
                  <strong>Start Date:</strong> {manager.start_date}
                </p>
                <p className="text-[13px] text-gray-600">
                  <strong>Due Date:</strong> {manager.due_date}
                </p>
              </span>
              {manager.targets && manager.targets.length > 0 && (
                <div className="mb-4">
                  <h4 className="text-[13px] text-gray-600 mb-2">Targets</h4>
                  <div className="space-y-4">
                    {manager.targets.map((target, index) => (
                      <div
                        key={target.id || index}
                        className="border rounded-lg p-4 shadow-sm bg-gray-50 hover:bg-gray-100 transition duration-200"
                      >
                        <h5 className="text-md font-bold text-blue-600 mb-2">
                          {target.name}
                        </h5>
                        <p className="text-sm text-gray-700">
                          {target.description}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          <KpiReportForm
            report={report}
            setReport={setReport}
            selectedManager={manager}
          />
        </div>
      </div>
    </>
  );
};

export default KpiReport;
