import { faBell } from "@fortawesome/free-regular-svg-icons";
import {
  faBarsStaggered,
  faCheckCircle,
  faEnvelope,
  faHome,
  faSignOut,
  faTimes,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { Formik } from "formik";
import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { logoutUser } from "../lib/auth-utils";

const Navbar = () => {
  const [showNav, setShowNav] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [signingOut, setSigningOut] = useState(false);
  const [hasNotification, setHasNotification] = useState(false);
  const [activeLink, setActiveLink] = useState("overview");
  const navRef = useRef(null);
  const mobileNav = useRef(null);
  const apiUrl = import.meta.env.VITE_API_URL;
  const token = JSON.parse(localStorage.getItem("user"))?.access;
  const user_id = JSON.parse(localStorage.getItem("user"))?.user_id;
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"))?.user_type;
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const notificationRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();
  const active = location.pathname;

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/v1/notifications/all?recipient=${user_id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        const unreadNotifications = response.data.data.filter(
          (notif) => !notif.is_read
        );
        setNotifications(response.data.data);
        setHasNotification(unreadNotifications.length > 0 ? true : false);
        setLoading(false);
      } catch (error) {
        console.error(error.response?.data || error.message);
        setLoading(false);
      }
    };
    fetchNotifications();
  }, []);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();

    window.addEventListener("resize", checkMobile);

    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const markAsRead = async (id) => {
    try {
      await axios.put(
        `${apiUrl}/api/v1/notifications/${id}`,
        {
          is_read: true,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setNotifications((prev) =>
        prev.map((notif) =>
          notif.id === id ? { ...notif, is_read: true } : notif
        )
      );
    } catch (error) {
      console.error(error.response?.data || error.message);
    }
  };

  const deleteNotification = async (id) => {
    try {
      const response = await axios.delete(
        `${apiUrl}/api/v1/notifications/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setNotifications((prev) => prev.filter((notif) => notif.id !== id));
    } catch (error) {
      console.error("Error:", error.response?.data || error.message);
    }
  };

  const handleLogout = () => {
    setSigningOut(true);
    logoutUser().then(() => {
      setTimeout(() => {
        navigate("/login");
        setSigningOut(false);
      }, 1000);
    });
  };

  const handleNotificationClick = () => {
    setShowNotification((prev) => !prev);
  };

  const styles = {
    enter: "transform -translate-x-full opacity-0",
    enterActive:
      "transform translate-x-0 opacity-100 transition-all duration-500 ease-in-out",
    exitActive:
      "transform -translate-x-full opacity-0 transition-all duration-500 ease-in-out",
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (mobileNav.current && !mobileNav.current.contains(event.target)) {
        setShowNav(false);
      }
    };
    window.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, [mobileNav]);

  const checkNavVisibility = () => {
    setShowNav((prev) => !prev);
  };

  const handleNavLinkClick = (link) => {
    setActiveLink(link);
    setShowNav(false);
  };

  return (
    <>
      <nav
        ref={navRef}
        className="p-8 inset-x-0 top-0 z-10 flex md:justify-end justify-between items-center"
      >
        {isMobile ? (
          <button className="block cursor-pointer" onClick={checkNavVisibility}>
            <FontAwesomeIcon
              icon={showNav ? faTimes : faBarsStaggered}
              className="text-2xl"
            />
          </button>
        ) : (
          <></>
        )}

        {isMobile && <img className="w-[120px]" src="/logo-black.png" />}
        <span className="flex gap-[50px] items-center">
          <div className="relative">
            <button onClick={handleNotificationClick}>
              <FontAwesomeIcon icon={faBell} className="text-xl" />
            </button>
            {hasNotification && (
              <div className="absolute top-0 right-0 bg-red-500 w-2 h-2 rounded-full"></div>
            )}
          </div>
        </span>
      </nav>

      {isMobile ? (
        user === "ADMIN" ? (
          <>
            <CSSTransition
              classNames={styles}
              in={showNav}
              timeout={700}
              unmountOnExit
            >
              <div
                ref={mobileNav}
                className="bg-white fixed z-40 top-0 w-full h-full p-8"
              >
                <button
                  className="md:hidden block cursor-pointer fixed top-5 right-5"
                  onClick={checkNavVisibility}
                >
                  <FontAwesomeIcon icon={faTimes} className="text-2xl" />
                </button>
                <div className="mt-4 flex-col flex items-center">
                  <img className="w-[120px] mb-6" src="/logo-black.png" />
                  <div className="w-full h-[0.1px] bg-gray-400"></div>
                </div>
                <div className="flex flex-col h-[90%] justify-between">
                  <div>
                    <Link
                      to="/care_packages"
                      onClick={() => handleNavLinkClick("care_packages")}
                    >
                      <div className="mt-6">
                        <span
                          className={`cursor-pointer flex items-center gap-4 transition ease-in duration-300 ${
                            activeLink === "care_packages"
                              ? "font-bold text-slate-400"
                              : ""
                          }`}
                        >
                          <img src="/care.svg" />
                          Care Packages
                        </span>
                      </div>
                    </Link>
                    <Link
                      to="/resource_request"
                      onClick={() => handleNavLinkClick("resource_request")}
                    >
                      <div className="mt-6">
                        <span
                          className={`cursor-pointer flex items-center gap-4 transition ease-in duration-300 ${
                            activeLink === "resource_request"
                              ? "font-bold text-slate-400"
                              : ""
                          }`}
                        >
                          <img src="/resource.svg" />
                          Resource Request
                        </span>
                      </div>
                    </Link>

                    <Link
                      to="/admin_client_feedback"
                      onClick={() =>
                        handleNavLinkClick("admin_client_feedback")
                      }
                    >
                      <div className="mt-6">
                        <span
                          className={`cursor-pointer flex items-center gap-4 transition ease-in duration-300 ${
                            activeLink === "feedback"
                              ? "font-bold text-slate-400"
                              : ""
                          }`}
                        >
                          <img src="/feedback.svg" />
                          Client Feedback
                        </span>
                      </div>
                    </Link>
                  </div>

                  <button
                    onClick={handleLogout}
                    className="flex items-center hover:bg-red-700 bg-danger px-4 py-2 text-white rounded-md gap-4"
                  >
                    <FontAwesomeIcon icon={faSignOut} />
                    {signingOut ? "Signing Out..." : "Sign Out"}
                  </button>
                </div>
              </div>
            </CSSTransition>
          </>
        ) : (
          <>
            <CSSTransition
              classNames={styles}
              in={showNav}
              timeout={700}
              unmountOnExit
            >
              <div
                ref={mobileNav}
                className="bg-white fixed z-40 top-0 w-full h-full p-8"
              >
                <button
                  className="md:hidden block cursor-pointer fixed top-5 right-5"
                  onClick={checkNavVisibility}
                >
                  <FontAwesomeIcon icon={faTimes} className="text-2xl" />
                </button>
                <div className="mt-4 flex-col flex items-center">
                  <img className="w-[120px] mb-6" src="/logo-black.png" />
                  <div className="w-full h-[0.1px] bg-gray-400"></div>
                </div>
                <div className="flex flex-col h-[90%] justify-between">
                  <div>
                    <Link
                      to="/manager_care_package"
                      onClick={() => handleNavLinkClick("manager_care_package")}
                    >
                      <div className="mt-6">
                        <span
                          className={`cursor-pointer flex items-center gap-4 transition ease-in duration-300 ${
                            activeLink === "manager_care_package"
                              ? "font-bold text-slate-400"
                              : ""
                          }`}
                        >
                          <img src="/care.svg" />
                          Care Packages
                        </span>
                      </div>
                    </Link>
                    <Link
                      to="/manager_resource_request"
                      onClick={() =>
                        handleNavLinkClick("manager_resource_request")
                      }
                    >
                      <div className="mt-6">
                        <span
                          className={`cursor-pointer flex items-center gap-4 transition ease-in duration-300 ${
                            activeLink === "manager_resource_request"
                              ? "font-bold text-slate-400"
                              : ""
                          }`}
                        >
                          <img src="/resource.svg" />
                          Resource Request
                        </span>
                      </div>
                    </Link>

                    <Link
                      to="/client_feedback"
                      onClick={() => handleNavLinkClick("client_feedback")}
                    >
                      <div className="mt-6">
                        <span
                          className={`cursor-pointer flex items-center gap-4 transition ease-in duration-300 ${
                            activeLink === "client_feedback"
                              ? "font-bold text-slate-400"
                              : ""
                          }`}
                        >
                          <img src="/feedback.svg" />
                          Client Feedback
                        </span>
                      </div>
                    </Link>
                  </div>

                  <button
                    onClick={handleLogout}
                    className="flex items-center hover:bg-red-700 bg-danger px-4 py-2 text-white rounded-md gap-4"
                  >
                    <FontAwesomeIcon icon={faSignOut} />
                    {signingOut ? "Signing Out..." : "Sign Out"}
                  </button>
                </div>
              </div>
            </CSSTransition>
          </>
        )
      ) : (
        <>
          {user === "ADMIN" ? (
            <div className="bg-white overflow-scroll shadow-md fixed top-0 w-[350px] h-full p-8">
              <div className="mt-4 flex-col flex items-center">
                <img
                  className="w-[120px] mb-6"
                  src="/logo-black.png"
                  alt="Logo"
                />
              </div>
              <div className="flex flex-col h-[90%] justify-between">
                <div>
                  <small className="text-slate-700">Dashboard</small>
                  <Link to="/dashboard">
                    <div className="mt-2">
                      <span
                        className={`cursor-pointer px-3 py-2 rounded-md flex items-center gap-4 transition ease-in duration-300 ${
                          active === "/dashboard"
                            ? "bg-gray-100 text-slate-800 font-semibold border-s border-btn"
                            : "hover:bg-gray-100 text-slate-600"
                        }`}
                      >
                        <FontAwesomeIcon
                          className="w-[17px] h-[17px] text-black"
                          icon={faHome}
                        />
                        Home
                      </span>
                    </div>
                  </Link>

                  <Link to="/kpi">
                    <div className="mt-2">
                      <span
                        className={`cursor-pointer px-3 py-2 rounded-md flex items-center gap-4 transition ease-in duration-300 ${
                          active === "/kpi"
                            ? "bg-gray-100 text-slate-800 font-semibold border-s border-btn"
                            : "hover:bg-gray-100 text-slate-600"
                        }`}
                      >
                        <img src="/bullseye.svg" alt="KPI Icon" />
                        KPIs
                      </span>
                    </div>
                  </Link>

                  <Link to="/managers">
                    <div className="mt-2">
                      <span
                        className={`cursor-pointer px-3 py-2 rounded-md flex items-center gap-4 transition ease-in duration-300 ${
                          active === "/managers"
                            ? "bg-gray-100 text-slate-800 font-semibold border-s border-btn"
                            : "hover:bg-gray-100 text-slate-600"
                        }`}
                      >
                        <img src="/managers.svg" alt="Managers Icon" />
                        Managers
                      </span>
                    </div>
                  </Link>

                  <Link to="/profile">
                    <div className="mt-2 mb-4">
                      <span
                        className={`cursor-pointer px-3 py-2 rounded-md flex items-center gap-4 transition ease-in duration-300 ${
                          active === "/profile"
                            ? "bg-gray-100 text-slate-800 font-semibold border-s border-btn"
                            : "hover:bg-gray-100 text-slate-600"
                        }`}
                      >
                        <img src="/profile.svg" alt="Profile Icon" />
                        Profile
                      </span>
                    </div>
                  </Link>

                  <small className="text-slate-700">Actions</small>
                  <Link to="/care_packages">
                    <div className="mt-2">
                      <span
                        className={`cursor-pointer px-3 py-2 rounded-md flex items-center gap-4 transition ease-in duration-300 ${
                          active === "/care_packages"
                            ? "bg-gray-100 text-slate-800 font-semibold border-s border-btn"
                            : "hover:bg-gray-100 text-slate-600"
                        }`}
                      >
                        <img src="/care.svg" alt="Care Packages" />
                        Care Packages
                      </span>
                    </div>
                  </Link>

                  <Link to="/resource_request">
                    <div className="mt-2">
                      <span
                        className={`cursor-pointer px-3 py-2 rounded-md flex items-center gap-4 transition ease-in duration-300 ${
                          active === "/resource_request"
                            ? "bg-gray-100 text-slate-800 font-semibold border-s border-btn"
                            : "hover:bg-gray-100 text-slate-600"
                        }`}
                      >
                        <img src="/resource.svg" alt="Resource Request" />
                        Resource Request
                      </span>
                    </div>
                  </Link>

                  <Link to="/admin_client_feedback">
                    <div className="mt-2">
                      <span
                        className={`cursor-pointer px-3 py-2 rounded-md flex items-center gap-4 transition ease-in duration-300 ${
                          active === "/admin_client_feedback"
                            ? "bg-gray-100 text-slate-800 font-semibold border-s border-btn"
                            : "hover:bg-gray-100 text-slate-600"
                        }`}
                      >
                        <img src="/feedback.svg" alt="Client Feedback" />
                        Client Feedback
                      </span>
                    </div>
                  </Link>
                </div>

                <button
                  onClick={handleLogout}
                  className="flex items-center hover:bg-red-700 bg-danger px-4 py-2 text-white rounded-md gap-4"
                >
                  <FontAwesomeIcon icon={faSignOut} />
                  {signingOut ? "Signing Out..." : "Sign Out"}
                </button>
              </div>
            </div>
          ) : (
            <>
              <div className="bg-white overflow-scroll shadow-md fixed top-0 w-[350px] h-full p-8">
                <div className="mt-4 flex-col flex items-center">
                  <img
                    className="w-[120px] mb-6"
                    src="/logo-black.png"
                    alt="Logo"
                  />
                </div>
                <div className="flex flex-col h-[90%] justify-between">
                  <div>
                    <small className="text-slate-700">Dashboard</small>
                    <Link to="/dashboard">
                      <div className="mt-2">
                        <span
                          className={`cursor-pointer px-3 py-2 rounded-md flex items-center gap-4 transition ease-in duration-300 ${
                            active === "/dashboard"
                              ? "bg-gray-100 text-slate-800 font-semibold border-s border-btn"
                              : "hover:bg-gray-100 text-slate-600"
                          }`}
                        >
                          <FontAwesomeIcon
                            className="w-[17px] h-[17px] text-black"
                            icon={faHome}
                          />
                          Home
                        </span>
                      </div>
                    </Link>

                    <Link to="/manager_kpi_details">
                      <div className="mt-2">
                        <span
                          className={`cursor-pointer px-3 py-2 rounded-md flex items-center gap-4 transition ease-in duration-300 ${
                            active === "/manager_kpi_details"
                              ? "bg-gray-100 text-slate-800 font-semibold border-s border-btn"
                              : "hover:bg-gray-100 text-slate-600"
                          }`}
                        >
                          <img src="/bullseye.svg" alt="KPI Icon" />
                          KPIs
                        </span>
                      </div>
                    </Link>

                    <Link to="/task">
                      <div className="mt-2">
                        <span
                          className={`cursor-pointer px-3 py-2 rounded-md flex items-center gap-4 transition ease-in duration-300 ${
                            active === "/task"
                              ? "bg-gray-100 text-slate-800 font-semibold border-s border-btn"
                              : "hover:bg-gray-100 text-slate-600"
                          }`}
                        >
                          <img src="/task.svg" alt="Task Icon" />
                          Tasks
                        </span>
                      </div>
                    </Link>

                    <Link to="/profile">
                      <div className="mt-2 mb-4">
                        <span
                          className={`cursor-pointer px-3 py-2 rounded-md flex items-center gap-4 transition ease-in duration-300 ${
                            active === "/profile"
                              ? "bg-gray-100 text-slate-800 font-semibold border-s border-btn"
                              : "hover:bg-gray-100 text-slate-600"
                          }`}
                        >
                          <img src="/profile.svg" alt="Profile Icon" />
                          Profile
                        </span>
                      </div>
                    </Link>

                    <small className="text-slate-700">Actions</small>
                    <Link to="/manager_care_package">
                      <div className="mt-2">
                        <span
                          className={`cursor-pointer px-3 py-2 rounded-md flex items-center gap-4 transition ease-in duration-300 ${
                            active === "/manager_care_package"
                              ? "bg-gray-100 text-slate-800 font-semibold border-s border-btn"
                              : "hover:bg-gray-100 text-slate-600"
                          }`}
                        >
                          <img src="/care.svg" alt="Care Packages" />
                          Care Packages
                        </span>
                      </div>
                    </Link>

                    <Link to="/manager_resource_request">
                      <div className="mt-2">
                        <span
                          className={`cursor-pointer px-3 py-2 rounded-md flex items-center gap-4 transition ease-in duration-300 ${
                            active === "/manager_resource_request"
                              ? "bg-gray-100 text-slate-800 font-semibold border-s border-btn"
                              : "hover:bg-gray-100 text-slate-600"
                          }`}
                        >
                          <img src="/resource.svg" alt="Resource Request" />
                          Resource Request
                        </span>
                      </div>
                    </Link>

                    <Link to="/client_feedback">
                      <div className="mt-2">
                        <span
                          className={`cursor-pointer px-3 py-2 rounded-md flex items-center gap-4 transition ease-in duration-300 ${
                            active === "/client_feedback"
                              ? "bg-gray-100 text-slate-800 font-semibold border-s border-btn"
                              : "hover:bg-gray-100 text-slate-600"
                          }`}
                        >
                          <img src="/feedback.svg" alt="Client Feedback" />
                          Client Feedback
                        </span>
                      </div>
                    </Link>
                  </div>

                  <button
                    onClick={handleLogout}
                    className="flex items-center hover:bg-red-700 bg-danger px-4 py-2 text-white rounded-md gap-4"
                  >
                    <FontAwesomeIcon icon={faSignOut} />
                    {signingOut ? "Signing Out..." : "Sign Out"}
                  </button>
                </div>
              </div>
            </>
          )}
        </>
      )}

      <CSSTransition
        classNames={styles}
        in={showNotification}
        timeout={700}
        unmountOnExit
      >
        <div
          ref={notificationRef}
          className="bg-white fixed z-40 top-0 w-full h-full p-8 shadow-2xl rounded-lg overflow-y-auto mx-auto"
        >
          <button
            className="absolute top-5 right-5 p-2 rounded-full hover:bg-gray-200"
            onClick={() => setShowNotification(false)}
          >
            <FontAwesomeIcon icon={faTimes} className="text-gray-600 text-xl" />
          </button>
          <div className="mt-4 flex-col flex items-center">
            <h5 className="mb-4 text-lg font-bold text-gray-800">
              Notifications
            </h5>
            <div className="w-full h-[1px] bg-gray-300"></div>
          </div>
          {loading ? (
            <div className="mt-8 text-center font-bold text-gray-600">
              Loading...
            </div>
          ) : notifications.length > 0 ? (
            <div className="mt-6 space-y-4">
              {notifications.map((notification) => (
                <div
                  key={notification.id}
                  className={`p-4 border rounded-md shadow-sm transition-all duration-300 ${
                    notification.is_read ? "bg-gray-100" : "bg-white"
                  }`}
                >
                  <div className="flex justify-between items-center">
                    <div className="flex items-center gap-4">
                      <FontAwesomeIcon
                        className={`p-3 rounded-full text-white ${
                          notification.is_read ? "bg-green-500" : "bg-blue-500"
                        }`}
                        icon={faEnvelope}
                      />
                      <div>
                        <p className="font-semibold text-gray-800">
                          {notification.title}
                        </p>
                        <p className="text-sm text-gray-600">
                          {notification.message}
                        </p>
                        <p className="text-xs text-gray-400 mt-1">
                          {new Date(notification.created_at).toUTCString()}
                        </p>
                      </div>
                    </div>
                    <div className="flex gap-2">
                      {!notification.is_read && (
                        <button
                          onClick={() => markAsRead(notification.id)}
                          className="text-green-600 hover:text-green-800 transition"
                          title="Mark as read"
                        >
                          <FontAwesomeIcon icon={faCheckCircle} />
                        </button>
                      )}
                      {/* <button
                        onClick={() => deleteNotification(notification.id)}
                        className="text-red-600 hover:text-red-800 transition"
                        title="Delete"
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="mt-8 text-center text-gray-600 font-bold">
              Nothing here yet!
            </div>
          )}
        </div>
      </CSSTransition>
    </>
  );
};

export default Navbar;
