import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Search,
  Book,
  HelpCircle,
  Flag,
  Lightbulb,
  MessageSquare,
  ChevronRight,
  BarChart,
  Users,
  Clock,
  Shield,
} from "lucide-react";

const InternalLanding = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="bg-gradient-to-b from-white to-gray-50 border-b">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-16 pb-20">
          <div className="text-center max-w-3xl mx-auto">
            <h1 className="text-4xl font-bold text-primary mb-6">
              Welcome to Trakitt Support
            </h1>
            <p className="text-xl text-gray-600 mb-8">
              Your comprehensive healthcare KPI and task management platform.
              Track, manage, and optimize your healthcare facility&apos;s
              performance.
            </p>
            <div className="flex gap-4 justify-center">
              <Button size="lg" className="bg-btn hover:bg-btn/90 text-white" asChild>
                <a href="/login">
                  Log In
                  <ChevronRight className="ml-2 w-4 h-4" />
                </a>
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* Feature Highlights */}
      <div className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center mb-12">
            Platform Features
          </h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="text-center">
              <div className="bg-btn/10 w-12 h-12 rounded-lg flex items-center justify-center mx-auto mb-4">
                <BarChart className="w-6 h-6 text-btn" />
              </div>
              <h3 className="font-semibold mb-2">KPI Tracking</h3>
              <p className="text-gray-600">
                Monitor and manage performance metrics with real-time tracking
                and reporting.
              </p>
            </div>
            <div className="text-center">
              <div className="bg-focus/10 w-12 h-12 rounded-lg flex items-center justify-center mx-auto mb-4">
                <Users className="w-6 h-6 text-focus" />
              </div>
              <h3 className="font-semibold mb-2">Care Management</h3>
              <p className="text-gray-600">
                Streamline client care packages and track service delivery
                effectively.
              </p>
            </div>
            <div className="text-center">
              <div className="bg-success/10 w-12 h-12 rounded-lg flex items-center justify-center mx-auto mb-4">
                <Clock className="w-6 h-6 text-success" />
              </div>
              <h3 className="font-semibold mb-2">Task Management</h3>
              <p className="text-gray-600">
                Organize daily tasks and track completion with our intuitive
                interface.
              </p>
            </div>
            <div className="text-center">
              <div className="bg-danger/10 w-12 h-12 rounded-lg flex items-center justify-center mx-auto mb-4">
                <Shield className="w-6 h-6 text-danger" />
              </div>
              <h3 className="font-semibold mb-2">Quality Assurance</h3>
              <p className="text-gray-600">
                Maintain high standards with feedback tracking and quality
                monitoring.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Search Section */}
      <div className="bg-white border-b">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="flex flex-col gap-4">
            <h2 className="text-2xl font-bold text-primary">
              Support Resources
            </h2>
            <div className="relative max-w-2xl">
              <Input
                type="text"
                placeholder="Search for guides, FAQs, or documentation..."
                className="pl-10 pr-4 py-2 w-full"
              />
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4" />
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {/* Guides */}
          <Card className="hover:shadow-lg transition-shadow">
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <Book className="w-5 h-5 text-btn" />
                <span>Guides</span>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-gray-600 mb-4">
                Comprehensive guides for KPI tracking, task management, and care
                package administration.
              </p>
              <Button className="w-full bg-btn hover:bg-btn/90 text-white">
                View Guides
              </Button>
            </CardContent>
          </Card>

          {/* FAQs */}
          <Card className="hover:shadow-lg transition-shadow">
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <HelpCircle className="w-5 h-5 text-btn" />
                <span>FAQs</span>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-gray-600 mb-4">
                Find answers to common questions about Trakitt&apos;s features
                and functionality.
              </p>
              <Button className="w-full bg-btn hover:bg-btn/90 text-white">
                Browse FAQs
              </Button>
            </CardContent>
          </Card>

          {/* Report Incident */}
          <Card className="hover:shadow-lg transition-shadow">
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <Flag className="w-5 h-5 text-danger" />
                <span>Report Incident</span>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-gray-600 mb-4">
                Report technical issues or service-related incidents for
                immediate attention.
              </p>
              <Button className="w-full bg-danger hover:bg-danger/90 text-white">
                Report Issue
              </Button>
            </CardContent>
          </Card>

          {/* Feature Requests */}
          <Card className="hover:shadow-lg transition-shadow">
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <Lightbulb className="w-5 h-5 text-focus" />
                <span>Feature Requests</span>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-gray-600 mb-4">
                Share your ideas for new features or improvements to enhance
                Trakitt.
              </p>
              <Button className="w-full bg-focus hover:bg-focus/90 text-white">
                Submit Request
              </Button>
            </CardContent>
          </Card>

          {/* Knowledge Base */}
          <Card className="hover:shadow-lg transition-shadow">
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <Book className="w-5 h-5 text-success" />
                <span>Knowledge Base</span>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-gray-600 mb-4">
                Access detailed documentation on KPI management and best
                practices.
              </p>
              <Button className="w-full bg-success hover:bg-success/90 text-white">
                Explore
              </Button>
            </CardContent>
          </Card>

          {/* Support Chat */}
          <Card className="hover:shadow-lg transition-shadow">
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <MessageSquare className="w-5 h-5 text-btn" />
                <span>Support Chat</span>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-gray-600 mb-4">
                Connect with our support team for real-time assistance and
                guidance.
              </p>
              <Button className="w-full bg-btn hover:bg-btn/90 text-white">
                Start Chat
              </Button>
            </CardContent>
          </Card>
        </div>

        {/* Quick Links */}
        <div className="mt-12">
          <h2 className="text-xl font-semibold mb-4">Quick Links</h2>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            <Button variant="outline" className="justify-start">
              <Book className="w-4 h-4 mr-2" />
              User Manual
            </Button>
            <Button variant="outline" className="justify-start">
              <HelpCircle className="w-4 h-4 mr-2" />
              Getting Started
            </Button>
            <Button variant="outline" className="justify-start">
              <MessageSquare className="w-4 h-4 mr-2" />
              Contact Admin
            </Button>
            <Button variant="outline" className="justify-start">
              <Flag className="w-4 h-4 mr-2" />
              System Status
            </Button>
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="bg-white border-t mt-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="text-center text-gray-600">
            <p>
              &copy; {new Date().getFullYear()} Trakitt. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InternalLanding;
