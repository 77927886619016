import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const AddClientsForm = ({ isOpen, setIsOpen, onClientAdded }) => {
  const [loading, setLoading] = useState(false);
  const apiUrl = import.meta.env.VITE_API_URL;
  const token = JSON.parse(localStorage.getItem("user"))?.access;

  const validationSchema = Yup.object({
    first_name: Yup.string()
      .required("First Name is required")
      .min(2, "First Name must be at least 2 characters"),
    last_name: Yup.string()
      .required("Last Name is required")
      .min(2, "Last Name must be at least 2 characters"),
    other_name: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      other_name: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const payload = {
          first_name: values.first_name,
          last_name: values.last_name,
        };

        const response = await axios.post(
          `${apiUrl}/api/v1/tracker/clients/new`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        const newClient = response.data;
        onClientAdded(newClient);
        setIsOpen(false);
      } catch (error) {
        setLoading(false);
        console.error(
          "Error saving client details:",
          error.response?.data || error.message
        );
      }
    },
  });

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black/70 backdrop-blur-sm"
      onClick={() => setIsOpen(false)}
    >
      <div
        className="relative bg-white rounded-lg shadow-lg w-[90%] max-w-lg animate-fadeIn p-8"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          onClick={() => setIsOpen(false)}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <FontAwesomeIcon icon={faTimes} className="h-6 w-6" />
        </button>
        <h3 className="text-2xl font-bold text-gray-800 mb-6 text-center">
          Add Client Details
        </h3>

        <form onSubmit={formik.handleSubmit} className="space-y-6">
          {[
            { name: "first_name", label: "First Name", type: "text" },
            { name: "last_name", label: "Last Name", type: "text" },
            { name: "other_name", label: "Other Names", type: "text" },
          ].map(({ name, label, type }) => (
            <div key={name}>
              <label
                htmlFor={name}
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                {label}
              </label>
              <input
                type={type}
                id={name}
                name={name}
                className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-400 focus:outline-none"
                value={formik.values[name]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched[name] && formik.errors[name] && (
                <div className="text-red-600 text-sm mt-1">
                  {formik.errors[name]}
                </div>
              )}
            </div>
          ))}

          <button
            type="submit"
            className="w-full bg-btn text-white py-3 rounded-lg font-medium hover:bg-orange-600 transition"
          >
            {loading ? "Adding..." : "Add Client"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddClientsForm;
