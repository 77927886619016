import React, { createContext, useContext, useReducer, useCallback, useState, useEffect } from 'react';
import axios from '@/lib/axios';

export type Template = {
    id: string;
    name: string;
    description: string;
    is_inverted: boolean;
};

type State = {
    templates: Template[];
    loading: boolean;
    error: string | null;
    searchQuery: string;
};

type Action =
    | { type: 'SET_TEMPLATES'; payload: Template[] }
    | { type: 'SET_LOADING'; payload: boolean }
    | { type: 'SET_ERROR'; payload: string | null }
    | { type: 'SET_SEARCH_QUERY'; payload: string };

const initialState: State = {
    templates: [],
    loading: false,
    error: null,
    searchQuery: '',
};

const TemplateContext = createContext<{
    state: State;
    fetchTemplates: (search?: string) => Promise<void>;
    createTemplate: (template: Omit<Template, 'id'>) => Promise<void>;
    updateTemplate: (id: string, template: Omit<Template, 'id'>) => Promise<void>;
    deleteTemplate: (id: string) => Promise<void>;
    setSearchQuery: (query: string) => void;
} | null>(null);

function templateReducer(state: State, action: Action): State {
    switch (action.type) {
        case 'SET_TEMPLATES':
            return { ...state, templates: action.payload };
        case 'SET_LOADING':
            return { ...state, loading: action.payload };
        case 'SET_ERROR':
            return { ...state, error: action.payload };
        case 'SET_SEARCH_QUERY':
            return { ...state, searchQuery: action.payload };
        default:
            return state;
    }
}

export function TemplateProvider({ children }: { children: React.ReactNode }) {
    const [state, dispatch] = useReducer(templateReducer, initialState);

    const fetchTemplates = useCallback(async (search?: string) => {
        dispatch({ type: 'SET_LOADING', payload: true });
        try {
            let url = '/tracker/target-templates/';
            if (search) url += `?search=${search}`;
            const response = await axios.get(url);
            dispatch({ type: 'SET_TEMPLATES', payload: response.data.data });
        } catch (error) {
            dispatch({ type: 'SET_ERROR', payload: 'Failed to fetch templates' });
        } finally {
            dispatch({ type: 'SET_LOADING', payload: false });
        }
    }, []);

    const createTemplate = useCallback(async (template: Omit<Template, 'id'>) => {
        try {
            await axios.post('/tracker/target-templates/', template);
            fetchTemplates(state.searchQuery);
        } catch (error) {
            dispatch({ type: 'SET_ERROR', payload: 'Failed to create template' });
        }
    }, [state.searchQuery]);

    const updateTemplate = useCallback(async (id: string, template: Omit<Template, 'id'>) => {
        try {
            await axios.put(`/tracker/target-template/${id}`, template);
            fetchTemplates(state.searchQuery);
        } catch (error) {
            dispatch({ type: 'SET_ERROR', payload: 'Failed to update template' });
        }
    }, [state.searchQuery]);

    const deleteTemplate = useCallback(async (id: string) => {
        try {
            await axios.delete(`/tracker/target-template/${id}`);
            fetchTemplates(state.searchQuery);
        } catch (error) {
            dispatch({ type: 'SET_ERROR', payload: 'Failed to delete template' });
        }
    }, [state.searchQuery]);

    const setSearchQuery = useCallback((query: string) => {
        dispatch({ type: 'SET_SEARCH_QUERY', payload: query });
    }, []);

    useEffect(() => {
        fetchTemplates(state.searchQuery);
    }, [fetchTemplates, state.searchQuery]);

    return (
        <TemplateContext.Provider value={{
            state,
            fetchTemplates,
            createTemplate,
            updateTemplate,
            deleteTemplate,
            setSearchQuery,
        }}>
            {children}
        </TemplateContext.Provider>
    );
}

export function useTemplate() {
    const context = useContext(TemplateContext);
    if (!context) throw new Error('useTemplate must be used within TemplateProvider');
    return context;
}