import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import axios from "axios";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
);

const ManagerTaskCompletion = () => {
  const apiUrl = import.meta.env.VITE_API_URL;
  const token = JSON.parse(localStorage.getItem("user"))?.access;
  const id = JSON.parse(localStorage.getItem("user"))?.user_id;
  const [barData, setBarData] = useState({
    labels: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
    datasets: [
      { label: "Task Completed", data: [], backgroundColor: "#FF8A00" },
      {
        label: "Task Created",
        data: [],
        backgroundColor: "rgba(102, 153, 255, 0.6)",
      },
    ],
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTasks = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${apiUrl}/api/v1/tracker/tasks/manager/all?manager=${id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setLoading(false);

        const tasks = response.data.data || [];

        const totalKpis = tasks.length;

        const completedTasks = tasks.filter((task) => task.is_completed).length;

        const undoneTasks = tasks.filter((task) => !task.is_completed).length;

        const completionRate = totalKpis
          ? ((completedTasks / totalKpis) * 100).toFixed(2)
          : "0.00";

        const taskCompletionByDay = [0, 0, 0, 0, 0];
        const taskUndoneByDay = [0, 0, 0, 0, 0];

        tasks.forEach((task) => {
          const dayIndex = new Date(task.start_date).getDay();
          if (dayIndex > 0 && dayIndex <= 5) {
            if (task.is_completed) {
              taskCompletionByDay[dayIndex - 1] += 1;
            } else {
              taskUndoneByDay[dayIndex - 1] += 1;
            }
          } else {
            console.warn(
              "Task has an invalid or non-weekday start_date:",
              task
            );
          }
        });

        setBarData({
          labels: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
          datasets: [
            {
              label: "Tasks Completed",
              data: taskCompletionByDay,
              backgroundColor: "#4CAF50",
              borderColor: "#388E3C",
              borderWidth: 1,
            },
            {
              label: "Tasks Undone",
              data: taskUndoneByDay,
              backgroundColor: "#F44336",
              borderColor: "#D32F2F",
              borderWidth: 1,
            },
          ],
        });
      } catch (error) {
        console.error("Error fetching tasks:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTasks();
  }, [apiUrl, token, id]);

  const barOptions = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: "x",
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          padding: 24,
        },
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        max: 25,
        title: {
          display: true,
          text: "Percentage",
        },
      },
    },
  };

  return loading ? (
    <div className="flex justify-center items-center h-12">
      <div className="loader border-t-4 border-btn rounded-full w-4 h-4 animate-spin"></div>
    </div>
  ) : error ? (
    <p className="text-red-500 text-sm">{error}</p>
  ) : (
    <div className="bg-white p-4 mb-3 rounded-md shadow-sm">
      <h2 className="font-bold text-sm mb-6">Task Completion</h2>
      <div style={{ width: "100%", height: "400px" }}>
        <Bar data={barData} options={barOptions} />
      </div>
    </div>
  );
};

export default ManagerTaskCompletion;
