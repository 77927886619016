import React, { useEffect, useState } from "react";
import PeriodContextSelector from "../Components/PeriodContextSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faCheck,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { CSSTransition } from "react-transition-group";

const CarePackages = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = 5;
  const [selectedPackage, setSelectedPackage] = useState(null);
  const navigate = useNavigate();
  const totalPages = Math.ceil(clients.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentClients = clients.slice(startIndex, startIndex + itemsPerPage);
  const apiUrl = import.meta.env.VITE_API_URL;
  const token = JSON.parse(localStorage.getItem("user"))?.access;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const closePopup = () => {
    setSelectedPackage(null);
  };

  useEffect(() => {
    const fetchCarePackages = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${apiUrl}/api/v1/tracker/care-plans/all`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setLoading(false);
        setClients(response.data.data);
      } catch (error) {
        console.error(error.response?.data || error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCarePackages();
  }, [apiUrl, token]);

  const myClassNames = {
    enter: "opacity-0",
    enterActive: "opacity-100 transition-opacity duration-500 ease-in-out",
    exitActive: "opacity-0 transition-opacity duration-500 ease-in-out",
  };

  return (
    <>
      <div className="p-4 bg-[#F8F9FC] md:ml-[350px] mb-24">
        <div className="p-4 bg-white rounded-md shadow-md">
          <span className="flex items-center gap-3">
            <FontAwesomeIcon onClick={() => navigate(-1)} icon={faArrowLeft} />
            <h2 className="text-md font-bold">All Care Packages</h2>
          </span>
        </div>
        <PeriodContextSelector />

        {loading ? (
          <div className="flex justify-center items-center mt-4">
            <div className="text-sm text-slate-500">Loading...</div>
          </div>
        ) : (
          <div className="overflow-x-auto bg-white mt-4">
            <table className="rounded-md shadow-md w-full">
              <thead className="shadow-md rounded-lg bg-white">
                <tr>
                  <th className="px-6 py-3 text-left text-primary text-[14px] font-light whitespace-nowrap">
                    Client Name
                  </th>
                  <th className="px-6 py-3 text-left text-primary text-[14px] font-light whitespace-nowrap">
                    Start Date
                  </th>
                  <th className="px-6 py-3 text-left text-primary text-[14px] font-light whitespace-nowrap">
                    Status
                  </th>
                  <th className="px-6 py-3 text-left text-primary text-[14px] font-light whitespace-nowrap">
                    Hours/Week
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {currentClients.length > 0 ? (
                  currentClients.map((client) => (
                    <tr
                      key={client.id}
                      onClick={() => setSelectedPackage(client)}
                      className="rounded-md shadow-sm cursor-pointer hover:bg-gray-100"
                      >
                      <td className="px-6 py-4 text-[13px] font-semibold whitespace-nowrap">
                        {client.client.fullname}
                      </td>
                      <td className="px-6 py-4 text-[13px] font-semibold whitespace-nowrap">
                        {moment(client.start_date).format("MMMM Do YYYY")}
                      </td>
                      <td
                        className={`inline-block mt-3 px-3 py-1 text-[13px] whitespace-nowrap font-semibold rounded-full ${
                          client.status === "active"
                            ? "bg-green-100 text-green-700"
                            : "bg-red-100 text-red-700"
                        }`}
                      >
                        {client.status || "No Status"}
                      </td>
                      <td className="px-6 py-4 text-[13px] font-semibold whitespace-nowrap">
                        {client.hours_of_care_received}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="4"
                      className="text-center px-6 py-4 text-[13px] text-gray-500"
                    >
                      No Available Care Package.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}

        {clients.length > itemsPerPage && (
          <div className="flex justify-center items-center mt-4">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="px-3 py-1 mx-1 text-sm font-semibold text-gray-700 bg-gray-200 rounded hover:bg-gray-300 disabled:opacity-50"
            >
              Previous
            </button>
            {[...Array(totalPages)].map((_, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                className={`px-3 py-1 mx-1 text-sm font-semibold ${
                  currentPage === index + 1
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                } rounded`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="px-3 py-1 mx-1 text-sm font-semibold text-gray-700 bg-gray-200 rounded hover:bg-gray-300 disabled:opacity-50"
            >
              Next
            </button>
          </div>
        )}

        <CSSTransition
          in={selectedPackage}
          timeout={300}
          classNames={myClassNames}
          unmountOnExit
        >
          <>
            {selectedPackage && (
              <>
                <div
                  className="fixed inset-0 overflow-scroll z-50 flex items-center justify-center bg-black/70 backdrop-blur-sm"
                  onClick={closePopup}
                >
                  <div
                    className="relative bg-white rounded-lg shadow-lg w-[90%] max-w-lg animate-fadeIn p-8"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <button
                      onClick={closePopup}
                      className="flex justify-self-end mb-4"
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                    <div className="text-center">
                      <h3 className="text-3xl font-bold text-gray-800 mb-4">
                        {selectedPackage.client.fullname}
                      </h3>
                      <span
                        className={`inline-block px-4 py-1 text-sm font-semibold rounded-full ${
                          selectedPackage.status === "active"
                            ? "bg-green-100 text-green-700"
                            : "bg-red-100 text-red-700"
                        }`}
                      >
                        {selectedPackage.status || "No Status"}
                      </span>
                    </div>

                    <div className="mt-6 space-y-4">
                      <div className="flex justify-between items-center">
                        <span className="font-medium text-gray-600">
                          Created By:
                        </span>
                        <span className="text-gray-800">
                          {selectedPackage.recorded_by.last_name}{" "}
                          {selectedPackage.recorded_by.first_name}
                        </span>
                      </div>

                      <div className="flex justify-between items-center">
                        <span className="font-medium text-gray-600">
                          Start Date:
                        </span>
                        <span className="text-gray-800">
                          {moment(selectedPackage.start_date).format(
                            "MMMM Do YYYY"
                          )}
                        </span>
                      </div>

                      <div className="flex justify-between items-center">
                        <span className="font-medium text-gray-600">
                          Hours/Week:
                        </span>
                        <span className="text-gray-800">
                          {selectedPackage.hours_of_care_received}
                        </span>
                      </div>

                      {selectedPackage.end_date ? (
                        <div className="flex justify-between items-center">
                          <span className="font-medium text-gray-600">
                            End Date:
                          </span>
                          <span className="text-gray-800">
                            {moment(selectedPackage.end_date).format(
                              "MMMM Do YYYY"
                            )}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                      {selectedPackage.ended_reason ? (
                        <div className="flex justify-between items-center">
                          <span className="font-medium text-gray-600">
                            Ended Reason:
                          </span>
                          <span className="text-gray-800">
                            {selectedPackage.ended_reason}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        </CSSTransition>
      </div>
    </>
  );
};

export default CarePackages;
