import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuthenticateContext } from "../Contexts/AuthContext";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Eye, EyeOff, ArrowRight, XCircle, MapPin, Info } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import useGPSLocation from "@/Hooks/gps";

interface LoginFormValues {
  email: string;
  password: string;
}

interface LocationStatusProps {
  status: string;
  error: string | null;
}

export const LocationStatusAlert = ({ status, error }: LocationStatusProps) => {
  return (
    <Alert variant={error ? "destructive" : "default"} className="mb-4">
      <MapPin className="h-4 w-4" />
      <AlertDescription className="!translate-y-0">{error || status}</AlertDescription>
    </Alert>
  );
};

const LocationDisclaimerDialog = () => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <button className="text-sm text-muted-foreground hover:text-primary underline">
          Why do we need your location?
        </button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2">
            <Info className="h-5 w-5" />
            Location Access Information
          </DialogTitle>
        </DialogHeader>
        <div className="mt-4 space-y-4">
          <Alert className="border-blue-200 bg-blue-50">
            <MapPin className="h-4 w-4 text-blue-600" />
            <AlertDescription className="text-blue-600">
              <div className="space-y-2">
                <p className="font-medium">
                  This application requires location access for:
                </p>
                <ul className="list-disc pl-4 space-y-1">
                  <li>Verifying login attempts from approved locations</li>
                  <li>Protecting your account from unauthorized access</li>
                  <li>Security audit and compliance purposes</li>
                </ul>
                <p className="text-sm mt-4">
                  Your location data is encrypted and only used during the authentication process.
                  We do not store or share your precise location data.
                </p>
              </div>
            </AlertDescription>
          </Alert>
        </div>
      </DialogContent>
    </Dialog>
  );
};



const Login = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [logoutReason, setLogoutReason] = useState("");
  const [showNotification, setShowNotification] = useState<string | null>(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { login } = useAuthenticateContext();
  const navigate = useNavigate();
  const apiUrl = import.meta.env.VITE_API_URL;
  const currentYear = new Date().getFullYear();
  const isLocalhost = ["localhost", "127.0.0.1"].includes(window.location.hostname);

  // GPS Location hook with 20 meters accuracy requirement
  const { coordinates, error: locationError, loading: locationLoading } = useGPSLocation(20);

  useEffect(() => {
    const reason = localStorage.getItem("logoutReason");
    if (reason) {
      setLogoutReason(reason);
      localStorage.removeItem("logoutReason");

      setTimeout(() => {
        setLogoutReason("");
      }, 4000);
    }
  }, []);

  const initialValues: LoginFormValues = {
    email: "",
    password: "",
  };

  const Validation = yup.object().shape({
    email: yup
      .string()
      .email("Please use a valid email address")
      .required("Email is required"),
    password: yup.string().required("Password is required"),
  });

  const submitForm = async (values: LoginFormValues, { resetForm }: { resetForm: () => void }) => {
    if (!isLocalhost && !coordinates) {
      setShowNotification("Accurate location is required to continue.");
      return;
    }
    const data: { [key: string]: any } = {
      ...values
    };

    if (!isLocalhost) {
      data["latitude"] = coordinates!.latitude;
      data["longitude"] = coordinates!.longitude;
      data["accuracy"] = coordinates!.accuracy;
    }

    setIsSubmitting(true);
    try {
      const response = await axios.post(
        `${apiUrl}/api/v1/auth/login/`,
        data,
        { withCredentials: true }
      );

      setShowNotification(null);
      setLogoutReason("");
      login(response.data);
      navigate(`/dashboard`);
      resetForm();
    } catch (err: any) {
      console.error("Error:", err);
      if (err.response) {
        const { status, data } = err.response;
        if (status === 400) {
          setShowNotification(
            data.message || "Invalid input. Please check your form data."
          );
        } else if (status === 401) {
          setShowNotification(
            data.message || "Invalid credentials. Please try again."
          );
        } else if (status === 500) {
          setShowNotification(
            data.message || "Server error. Please try again later."
          );
        } else {
          setShowNotification(
            data.message || "An error occurred. Please try again."
          );
        }
      } else if (err.request) {
        setShowNotification(
          "No response from the server. Please check your internet connection."
        );
      } else {
        setShowNotification("An unexpected error occurred.");
      }
      setTimeout(() => {
        setShowNotification(null);
      }, 5000);
      resetForm();
    } finally {
      setIsSubmitting(false);
    }
  };

  const isLoginDisabled = (!isLocalhost && (locationLoading || !coordinates)) || isSubmitting;

  return (
    <>
      <div className="flex justify-center items-center w-full h-screen">
        <Formik
          initialValues={initialValues}
          validationSchema={Validation}
          onSubmit={submitForm}
        >
          <Form className="flex items-center flex-col md:w-[50%] w-[100%]">
            <img className="mb-8 w-[200px]" src="/logo-black.png" alt="Logo" />
            <h5 className="text-primary text-2xl font-bold mb-8">LOG IN</h5>

            {/* Location status and disclaimer */}
            <div className="w-[70%] mb-6">
              <LocationStatusAlert
                status={locationLoading ? "Getting your location..." : "Location acquired"}
                error={locationError}
              />
              <div className="text-center">
                <LocationDisclaimerDialog />
              </div>
            </div>

            <span className="h-[1px] bg-slate-200/100"></span>

            <div className="mt-8 w-full flex items-center flex-col">
              <Field
                type="email"
                name="email"
                placeholder="Email"
                className="bg-transparent border flex items-center focus:invalid:border-destructive focus:invalid:ring-destructive invalid:border-destructive invalid:text-destructive focus:border-primary focus:ring-1 focus:ring-primary justify-between w-[70%] outline-none rounded-md p-2"
              />

              <div className="relative mt-4 w-[70%]">
                <Field
                  type={passwordVisible ? "text" : "password"}
                  name="password"
                  placeholder="Password"
                  className="bg-transparent border flex items-center w-full focus:invalid:border-destructive focus:invalid:ring-destructive invalid:border-destructive invalid:text-destructive focus:border-primary focus:ring-1 focus:ring-primary justify-between outline-none rounded-md p-2"
                />
                <button
                  type="button"
                  className="absolute right-3 top-1/2 transform -translate-y-1/2"
                  onClick={() => setPasswordVisible(!passwordVisible)}
                >
                  {passwordVisible ? (
                    <EyeOff className="h-4 w-4 text-gray-500" />
                  ) : (
                    <Eye className="h-4 w-4 text-gray-500" />
                  )}
                </button>
              </div>

              <button
                type="submit"
                disabled={isLoginDisabled}
                className={`mt-8 w-[70%] transition border duration-300 ease-in-out flex items-center justify-center rounded-lg py-2
                  ${isLoginDisabled
                    ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                    : 'bg-btn text-white hover:bg-btn/70'}`}
              >
                {isSubmitting ? (
                  <div className="flex space-x-2 py-2">
                    <div className="w-2 h-2 bg-white rounded-full animate-bounce" />
                    <div className="w-2 h-2 bg-white rounded-full animate-bounce" style={{ animationDelay: '0.2s' }} />
                    <div className="w-2 h-2 bg-white rounded-full animate-bounce" style={{ animationDelay: '0.4s' }} />
                  </div>
                ) : (
                  <div className="flex items-center space-x-2">
                    <span>Login</span>
                    <ArrowRight className="h-4 w-4" />
                  </div>
                )}
              </button>

              <Link
                className="mt-8 text-primary cursor-pointer"
                to="/reset_password"
              >
                <small>
                  Don't remember your password? <u>Reset It.</u>
                </small>
              </Link>

              <small className="mt-12 text-primary cursor-pointer">
                &copy; Trakitt HQ, {currentYear}.
              </small>
            </div>
          </Form>
        </Formik>
      </div>

      {/* Notifications */}
      {(showNotification || logoutReason) && (
        <Alert variant="destructive" className="fixed right-4 top-4 w-96 z-50">
          <XCircle className="h-4 w-4" />
          <AlertDescription>
            {showNotification || logoutReason}
          </AlertDescription>
        </Alert>
      )}
    </>
  );
};

export default Login;
