import React, { useEffect, useState } from "react";

const QuickStats = ({ quickStats }) => {
  return (
    <>
      <div className="grid grid-cols-2 gap-4 mt-[24px]">
        <div className="flex bg-gray-100 p-2 border-dashed border rounded-md flex-col items-center">
          <span className="text-gray-500 text-xs">Total KPIs</span>
          <span className="text-gray-900 text-2xl font-bold">
            {quickStats.totalKpis}
          </span>
        </div>

        <div className="flex bg-gray-100 p-2 border-dashed border rounded-md flex-col items-center">
          <span className="text-gray-500 text-xs">Completed</span>
          <span className="text-gray-900 text-2xl font-bold">
            {quickStats.completed}
          </span>
        </div>

        <div className="flex bg-gray-100 p-2 border-dashed border rounded-md flex-col items-center">
          <span className="text-gray-500 text-xs">Undone</span>
          <span className="text-gray-900 text-2xl font-bold">
            {quickStats.undone}
          </span>
        </div>

        <div className="flex bg-gray-100 p-2 border-dashed border rounded-md flex-col items-center">
          <span className="text-gray-500 text-xs text-center">
            Completion Rate
          </span>
          <span className="text-gray-900 text-2xl font-bold">
            {quickStats.completionRate}%
          </span>
        </div>
      </div>
    </>
  );
};

export default QuickStats;
