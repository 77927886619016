import _axios from "axios";

const axios = _axios.create({
  // @ts-ignore
  baseURL: import.meta.env.VITE_API_URL + '/api/v1',
  headers: {"Content-Type": "application/json"},
  withCredentials: true,
});

axios.interceptors.request.use((config) => {
  const token = JSON.parse(localStorage.getItem("user") || "")?.access;
  if (token) {
    config.headers
        ? (config.headers.Authorization = `Bearer ${token}`)
        // @ts-ignore
        : (config.headers = { Authorization: `Bearer ${token}` });
    }
    return config;
});

export default axios;