import { useState, useEffect, useCallback } from "react";
import { X, AlertCircle } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";

interface LogoutTimerProps {
  onLogout: () => void;
  inactivityTimeout?: number;
  warningDuration?: number;
}

const LogoutTimer: React.FC<LogoutTimerProps> = ({
  onLogout,
  inactivityTimeout = 120000,
  warningDuration = 10,
}) => {
  const [lastActivity, setLastActivity] = useState<number>(Date.now());
  const [countdown, setCountdown] = useState(warningDuration);
  const [showWarning, setShowWarning] = useState(false);

  // Reset activity timestamp and hide warning
  const resetActivity = useCallback(() => {
    setLastActivity(Date.now());
    setShowWarning(false);
    setCountdown(warningDuration);
  }, [warningDuration]);

  // Handle user activity events
  const handleActivity = useCallback(() => {
    if (!showWarning) {
      resetActivity();
    }
  }, [showWarning, resetActivity]);

  // Set up activity monitoring
  useEffect(() => {
    const events = [
      "mousedown",
      "mousemove",
      "keydown",
      "scroll",
      "touchstart",
      "wheel",
    ];

    // Add all event listeners
    events.forEach((event) => {
      window.addEventListener(event, handleActivity);
    });

    // Check for inactivity - runs regardless of tab focus
    const inactivityCheck = setInterval(() => {
      const currentTime = Date.now();
      if (
        !showWarning &&
        currentTime - lastActivity >= inactivityTimeout
      ) {
        setShowWarning(true);
      }
    }, 1000);

    // Countdown timer when warning is shown - runs regardless of tab focus
    const countdownTimer = setInterval(() => {
      if (showWarning) {
        setCountdown((prev) => {
          if (prev <= 1) {
            onLogout();
            return 0;
          }
          return prev - 1;
        });
      }
    }, 1000);

    // Cleanup
    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, handleActivity);
      });
      clearInterval(inactivityCheck);
      clearInterval(countdownTimer);
    };
  }, [
    handleActivity,
    lastActivity,
    inactivityTimeout,
    showWarning,
    onLogout,
  ]);

  if (!showWarning) {
    return null;
  }

  return (
    <Alert
      variant="default"
      className="fixed right-4 top-4 w-96 z-50 border-blue-500"
    >
      <AlertCircle className="h-4 w-4" />
      <AlertTitle>Session Timeout Warning</AlertTitle>
      <AlertDescription className="flex items-center justify-between">
        <span>
          You will be logged out in {countdown} seconds due to inactivity
        </span>
        <Button
          variant="ghost"
          size="sm"
          className="h-8 w-8 p-0"
          onClick={resetActivity}
        >
          <X className="h-4 w-4" />
        </Button>
      </AlertDescription>
    </Alert>
  );
};

export default LogoutTimer;
