import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { faTimes, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import CreatePopup from "../Components/CreatePopup";
import { CSSTransition } from "react-transition-group";

const Managers = () => {
  const [edit, setEdit] = useState(false);
  const [managers, setManagers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showNotification, setshowNotification] = useState(false);
  const editRef = useRef(null);
  const apiUrl = import.meta.env.VITE_API_URL;
  const token = JSON.parse(localStorage.getItem("user"))?.access;

  const styles = {
    enter: "transform translate-y-full opacity-0",
    enterActive:
      "transform -translate-y-0 opacity-100 transition-all duration-500 ease-in-out",
    exitActive:
      "transform translate-y-full opacity-0 transition-all duration-500 ease-in-out",
  };

  const navigate = useNavigate();

  useEffect(() => {
    const fetchManagers = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${apiUrl}/api/v1/auth/users/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          timeout: 7000,
        });
        const filteredManagers = response.data.data.filter(
          (user) => user.user_type === "MANAGER"
        );
        setManagers(filteredManagers);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.error("Request canceled:", error.message);
        } else if (error.code === "ECONNABORTED") {
          console.error("Request timed out:", error.message);
          setshowNotification("Request timed out. Please try again.");
          setTimeout(() => {
            setshowNotification(false);
          }, 3000);
        } else {
          console.error("Error fetching managers:", error.message);
          setshowNotification("An error occurred. Please try again.");
          setTimeout(() => {
            setshowNotification(false);
          }, 3000);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchManagers();
    const source = axios.CancelToken.source();
    return () => source.cancel("Operation canceled by the user.");
  }, [apiUrl, token]);

  const handleRowClick = (id) => {
    navigate(`/managers/${id}`);
  };

  return (
    <>
      <div className="bg-[#F8F9FC] md:ml-[350px] p-4 h-screen">
        <div className="bg-white flex items-center justify-between p-4 mb-3 rounded-md shadow-sm">
          <h2 className="font-bold text-md">All Managers</h2>
          <button
            onClick={() => setEdit(true)}
            className="bg-btn text-white px-3 flex font-bold text-sm items-center gap-2 py-2 rounded-md"
          >
            Add Manager
            <FontAwesomeIcon icon={faUserPlus} />
          </button>
        </div>

        {loading ? (
          <div className="flex justify-center items-center h-64">
            <div className="loader border-t-4 border-btn rounded-full w-12 h-12 animate-spin"></div>
          </div>
        ) : managers.length === 0 ? (
          <div className="flex justify-center mt-4">
            <p>You have not added any managers yet.</p>
          </div>
        ) : (
          <div className="overflow-auto">
            <table className="rounded-md shadow-md w-full">
              <thead className="bg-white">
                <tr>
                  <th className="px-6 py-3 text-left text-primary text-[14px] font-light">
                    Name
                  </th>
                  <th className="px-6 py-3 text-left text-primary text-[14px] font-light">
                    Email
                  </th>
                  <th className="px-6 py-3 text-left text-primary text-[14px] font-light">
                    Phone Number
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {managers.map((manager) => (
                  <tr
                    key={manager.id}
                    className="cursor-pointer hover:bg-gray-100"
                    onClick={() => handleRowClick(manager.id)}
                  >
                    <td className="px-6 py-4 text-[13px] font-semibold whitespace-nowrap">
                      {manager.last_name} {manager.first_name}
                    </td>
                    <td className="px-6 py-4 text-[13px] font-semibold whitespace-nowrap">
                      {manager.email}
                    </td>
                    <td className="px-6 py-4 text-[13px] font-semibold whitespace-nowrap">
                      {manager.phone}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <CreatePopup
        styles={styles}
        edit={edit}
        editRef={editRef}
        setEdit={setEdit}
      />

      <CSSTransition
        in={showNotification}
        classNames={styles}
        timeout={500}
        unmountOnExit
      >
        <div className="p-4 shadow-xl z-50 bg-white fixed right-3 top-10 border-s-2 border-danger rounded-md flex items-center gap-4">
          <span className="flex items-center gap-4">
            <FontAwesomeIcon className="text-danger" icon={faTimes} />
            <p className="font-light">{showNotification}</p>
          </span>
        </div>
      </CSSTransition>
    </>
  );
};

export default Managers;
