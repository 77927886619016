import axios from "axios";

export async function logoutUser() {
  const apiUrl = import.meta.env.VITE_API_URL;
  const token = JSON.parse(localStorage.getItem("user"))?.access;

  try {
    const response = await axios.post(
      `${apiUrl}/api/v1/auth/logout/`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    if ([200, 204].includes(response.status)) {
      localStorage.removeItem("user");
      localStorage.removeItem("expiryTime");
      localStorage.removeItem("email");
    }
  } catch (error) {
    console.error("Error during logout:", error);
  }
}
