import React, { useEffect, useState } from "react";
import { Bar, Chart } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";

const PerformingManagers = () => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const [carePackages, setCarePackages] = useState([]);
  const [resources, setResources] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const apiUrl = import.meta.env.VITE_API_URL;
  const token = JSON.parse(localStorage.getItem("user"))?.access || "";
  const totalPages = Math.ceil(resources.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentResources = resources.slice(
    startIndex,
    startIndex + itemsPerPage
  );
  const [barData, setBarData] = useState({
    labels: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
    datasets: [
      {
        label: "Active Care Packages",
        data: [],
        backgroundColor: "rgba(102, 204, 102, 0.6)",
        borderColor: "rgba(102, 204, 102, 1)",
        borderWidth: 1,
      },
      {
        label: "Cancelled",
        data: [],
        backgroundColor: "rgba(255, 102, 102, 0.6)",
        borderColor: "rgba(255, 102, 102, 1)",
        borderWidth: 1,
      },
    ],
  });

  const paginatedData = resources.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const barOptions = {
    maintainAspectRatio: false,
    responsive: true,
    indexAxis: "y",
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          padding: 24,
        },
      },
      datalabels: {
        color: "#000",
        anchor: "end",
        align: "top",
        font: {
          weight: "bold",
        },
        formatter: (value) => value,
      },
    },
    scales: {
      x: {
        min: 0,
        max: 25,
        ticks: {
          stepSize: 5,
        },
        title: {
          display: false,
        },
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  useEffect(() => {
    const fetchCarePackages = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${apiUrl}/api/v1/tracker/care-plans/all`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setCarePackages(response.data.data);
      } catch (error) {
        console.error(error.response?.data || error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCarePackages();
  }, [apiUrl, token]);

  useEffect(() => {
    const fetchResources = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/v1/tracker/resource-requests/all`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setResources(response.data.data);
      } catch (error) {
        console.error("Error fetching resources:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchResources();
  }, [apiUrl, token]);

  useEffect(() => {
    if (carePackages.length > 0) {
      const activeCarePackages = [0, 0, 0, 0, 0, 0, 0];
      const cancelledPackages = [0, 0, 0, 0, 0, 0, 0];

      carePackages.forEach((pack) => {
        const dayIndex = new Date(pack.start_date).getDay();

        if (pack.status === "active") {
          activeCarePackages[dayIndex] += 1;
        } else if (pack.status === "cancelled") {
          cancelledPackages[dayIndex] += 1;
        }
      });

      setBarData({
        labels: [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ],
        datasets: [
          {
            label: "Active Care Packages",
            data: activeCarePackages,
            backgroundColor: "rgba(102, 204, 102, 0.6)",
            borderColor: "rgba(102, 204, 102, 1)",
            borderWidth: 1,
          },
          {
            label: "Cancelled Care Packages",
            data: cancelledPackages,
            backgroundColor: "rgba(255, 102, 102, 0.6)",
            borderColor: "rgba(255, 102, 102, 1)",
            borderWidth: 1,
          },
        ],
      });
    }
  }, [carePackages]);

  return (
    <>
      <div className="p-4 mb-24">
        <div className="bg-white p-4 rounded-md shadow-md">
          <span className="flex justify-between">
            <h2 className="font-bold mb-2">Care Package Summary</h2>
            <Link to="/care_packages">
              <h5 className="text-btn underline">View Details</h5>
            </Link>
          </span>
          <div className="h-[400px]">
            <Bar data={barData} options={barOptions} />
          </div>
        </div>
        <div className="bg-white mt-4">
          <span className="flex items-center p-4 justify-between">
            <h2 className="text-md font-bold">Resource Requests Overview</h2>
            <Link to="/resource_request">
              <h5 className="text-btn whitespace-nowrap underline">
                View Full Table
              </h5>
            </Link>
          </span>
          <div className="overflow-x-auto bg-white mt-4">
            <table className="rounded-md shadow-md w-full">
              <thead className="shadow-md rounded-lg bg-white">
                <tr>
                  <th className="px-6 py-3 text-left text-primary text-[14px] font-light whitespace-nowrap">
                    Item Requested
                  </th>
                  <th className="px-6 py-3 text-left text-primary text-[14px] font-light whitespace-nowrap">
                    Date Needed
                  </th>
                  <th className="px-6 py-3 text-left text-primary text-[14px] font-light whitespace-nowrap">
                    Quantity
                  </th>
                  <th className="px-6 py-3 text-left text-primary text-[14px] font-light whitespace-nowrap">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {loading ? (
                  <tr>
                    <td colSpan="4" className="text-center py-4">
                      Loading resources...
                    </td>
                  </tr>
                ) : resources.length === 0 ? (
                  <tr>
                    <td
                      colSpan="4"
                      className="text-center text-slate-400 text-sm py-4"
                    >
                      No resource requests available.
                    </td>
                  </tr>
                ) : paginatedData.length > 0 ? (
                  paginatedData.map((resource) => (
                    <tr
                      key={resource.id}
                      className="rounded-md shadow-sm cursor-pointer hover:bg-gray-100"
                      onClick={() => setSelectedResource(resource)}
                    >
                      <td className="px-6 py-4 text-[13px] font-semibold whitespace-nowrap">
                        {resource.name}
                      </td>
                      <td className="px-6 py-4 text-[13px] font-semibold whitespace-nowrap">
                        {moment(resource.due_date).format("MMMM Do YYYY")}
                      </td>
                      <td className="px-6 py-4 text-[13px] font-semibold whitespace-nowrap">
                        {resource.qty}
                      </td>
                      <td
                        className={`inline-block mt-3 px-3 py-1 text-[13px] whitespace-nowrap font-semibold rounded-full ${
                          resource.status === "accepted"
                            ? "bg-green-100 text-green-700"
                            : resource.status === "rejected"
                            ? "bg-red-100 text-red-700"
                            : "bg-yellow-100 text-yellow-700"
                        }`}
                      >
                        {resource.status}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="4"
                      className="text-center text-slate-400 text-sm py-4"
                    >
                      No paginated data available.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {resources.length > itemsPerPage && (
            <div className="flex justify-center items-center mt-4">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="px-3 py-1 mx-1 text-sm font-semibold text-gray-700 bg-gray-200 rounded hover:bg-gray-300 disabled:opacity-50"
              >
                Previous
              </button>
              {[...Array(totalPages)].map((_, index) => (
                <button
                  key={index}
                  onClick={() => handlePageChange(index + 1)}
                  className={`px-3 py-1 mx-1 text-sm font-semibold ${
                    currentPage === index + 1
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                  } rounded`}
                >
                  {index + 1}
                </button>
              ))}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="px-3 py-1 mx-1 text-sm font-semibold text-gray-700 bg-gray-200 rounded hover:bg-gray-300 disabled:opacity-50"
              >
                Next
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PerformingManagers;
