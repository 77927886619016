import React, { useState } from 'react';
import { KPIProvider, useKPI } from '@/Contexts/KpiContext';
import { Card, CardHeader, CardContent, CardTitle } from '@/components/ui/card';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Plus, User2Icon, CalendarIcon, Target, Edit } from 'lucide-react';
import { Progress } from '@/components/ui/progress';
import { Link, useNavigate } from 'react-router-dom';

// Type for the selected KPI to show in dialog
type SelectedKPI = {
  id: string;
  name: string;
  description: string;
  assignee_name: string;
  period: 'WEEKLY' | 'MONTHLY';
  targets: Array<{
    id: string;
    name: string;
    description: string;
    total_expected: number;
    is_inverted: boolean;
  }>;
} | null;

const KPIOverviewView = () => {
  const { state } = useKPI();
  const [selectedKPI, setSelectedKPI] = useState<SelectedKPI>(null);

  const handleKPIClick = (kpi: SelectedKPI) => {
    setSelectedKPI(kpi);
  };
  const router = useNavigate()

  const KPISummaryCard = ({ kpi }: { kpi: SelectedKPI }) => (
    <Card
      className="cursor-pointer hover:shadow-lg transition-shadow"
      onClick={() => handleKPIClick(kpi)}
    >
      <CardHeader className="pb-2">
        <div className="flex justify-between items-start">
          <CardTitle className="text-lg font-semibold">{kpi!.name}</CardTitle>
          <div className="flex items-center gap-2">
            <Button
              variant="ghost"
              size="icon"
              className="h-8 w-8"
              onClick={(e) => {
                e.stopPropagation();
                router(`/edit_kpi/${kpi!.id}`)
              }}
            >
              <Edit className="h-4 w-4" />
            </Button>
            <span className="px-2 py-1 text-xs rounded-full bg-gray-100">
              {kpi!.period.toLowerCase()}
            </span>
          </div>
        </div>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <div className="flex items-center gap-2 text-sm text-gray-600">
            <User2Icon size={16} />
            <span>{kpi!.assignee_name}</span>
          </div>
          <div className="flex items-center gap-2 text-sm text-gray-600">
            <Target size={16} />
            <span>{kpi!.targets.length} targets</span>
          </div>
        </div>
      </CardContent>
    </Card>
  );

  const KPISummaryDialog = ({ kpi, onClose }: { kpi: SelectedKPI; onClose: () => void }) => {
    if (!kpi) return null;

    return (
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <DialogTitle className="text-xl font-bold">{kpi.name}</DialogTitle>
        </DialogHeader>
        <div className="space-y-6 py-4">
          {/* Description */}
          <div>
            <h3 className="font-medium mb-2">Description</h3>
            <p className="text-gray-600">{kpi.description || 'No description provided'}</p>
          </div>

          {/* Meta Information */}
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <div className="flex items-center gap-2">
                <User2Icon size={16} className="text-gray-500" />
                <span>Assignee: {kpi.assignee_name}</span>
              </div>
              <div className="flex items-center gap-2">
                <CalendarIcon size={16} className="text-gray-500" />
                <span>Period: {kpi.period.toLowerCase()}</span>
              </div>
            </div>
          </div>

          {/* Targets */}
          <div>
            <h3 className="font-medium mb-4">Targets ({kpi.targets.length})</h3>
            <div className="space-y-4">
              {kpi.targets.map((target) => (
                <div key={target.id} className="bg-gray-50 p-4 rounded-lg">
                  <div className="flex justify-between items-start mb-2">
                    <h4 className="font-medium">{target.name}</h4>
                    <span className="text-sm text-gray-500">
                      Target: {target.total_expected}
                      {target.is_inverted && ' (inverted)'}
                    </span>
                  </div>
                  <p className="text-sm text-gray-600">{target.description}</p>
                  <Progress value={33} className="mt-2" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </DialogContent>
    );
  };

  return (
    <div className="max-w-7xl mx-auto p-6 md:ml-[350px] bg-[#F8F9FC] mb-24">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Key Performance Indicators</h1>
        <Button className="bg-btn" asChild>
          <Link to="/new_kpi">
            <Plus className="h-4 w-4 mr-2" />
            Create KPI
          </Link>
        </Button>
      </div>

      {/* KPI Grid */}
      {state.loading ? (
        <div className="text-center py-8">Loading KPIs...</div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {state.kpis.map((kpi) => (
            // @ts-ignore
            <KPISummaryCard key={kpi.id} kpi={kpi} />
          ))}
        </div>
      )}

      {/* KPI Summary Dialog */}
      <Dialog open={!!selectedKPI} onOpenChange={() => setSelectedKPI(null)}>
        <KPISummaryDialog kpi={selectedKPI} onClose={() => setSelectedKPI(null)} />
      </Dialog>
    </div>
  );
};

export default function KpiManagement() {
  return (
    <KPIProvider>
      <KPIOverviewView />
    </KPIProvider>
  )
}