import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '@/lib/axios';
import { KPI, KPIProvider, useKPI } from '@/Contexts/KpiContext';
import { TemplateProvider, useTemplate } from '@/Contexts/TemplateContext';
import TaskTemplateLibrary from '@/Components/TargetTemplateLibrary';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { DragDropContext, Droppable, DropResult } from '@hello-pangea/dnd';
import { TargetList } from '@/Components/TargetList';

type AssigneeOption = {
  value: string;
  label: string;
};

const EditKPIView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { fetchKpis, saveKpi, state } = useKPI();
  const { state: templateState } = useTemplate();
  const [assigneeOptions, setAssigneeOptions] = useState<AssigneeOption[]>([]);
  const [kpi, setKpi] = useState<KPI | null>(null);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    period: 'WEEKLY' as 'WEEKLY' | 'MONTHLY',
    assignee: '',
  });

  // Fetch KPI data
  useEffect(() => {
    const currentKpi = state.kpis.find(k => k.id === id);
    if (currentKpi) {
      setKpi(currentKpi);
      setFormData({
        name: currentKpi.name,
        description: currentKpi.description || '',
        period: currentKpi.period || 'WEEKLY',
        assignee: currentKpi.assignee || '',
      });
    }
  }, [id, state.kpis]);

  const handleDragEnd = useCallback((result: DropResult) => {
    if (!result.destination || !kpi) return;

    const { source, destination } = result;

    if (source.droppableId === 'template-library' && destination.droppableId !== 'template-library') {
      const targetTemplate = templateState.templates?.[source.index];

      if (!targetTemplate) return;

      setKpi(prev => {
        if (!prev) return prev;
        return {
          ...prev,
          targets: [...prev.targets, {
            ...targetTemplate,
            total_expected: 0,
          }]
        };
      });
    }
  }, [templateState.templates, kpi]);

  const fetchAssignees = useCallback(async () => {
    try {
      const response = await axios.get('/auth/users/?user_type=MANAGER');
      if (response.status !== 200) throw new Error('Failed to fetch assignees');

      const options = response.data.data.map((user: any) => ({
        value: user.manager_profile,
        label: `${user.first_name} ${user.last_name}`
      }));
      setAssigneeOptions(options);
    } catch (error) {
      console.error('Error fetching assignees:', error);
    }
  }, []);

  const handleSave = async () => {
    if (!kpi) return;

    try {
      const updatedKpi = {
        ...kpi,
        ...formData
      };
      await saveKpi(updatedKpi);
      navigate('/kpi_management');
    } catch (error) {
      console.error('Error updating KPI:', error);
    }
  };

  useEffect(() => {
    fetchAssignees();
  }, [fetchAssignees]);

  if (!kpi) {
    return <div className="p-6">Loading KPI data...</div>;
  }

  return (
    <div className="max-w-7xl mx-auto p-6 md:ml-[350px] bg-[#F8F9FC] mb-24">
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {/* KPI Edit Form */}
          <Card>
            <CardHeader>
              <CardTitle>Edit KPI</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <div>
                  <label className="text-sm font-medium">Name</label>
                  <Input
                    value={formData.name}
                    onChange={(e) => setFormData({
                      ...formData,
                      name: e.target.value
                    })}
                    className="mt-1"
                  />
                </div>
                <div>
                  <label className="text-sm font-medium">Description</label>
                  <Input
                    value={formData.description}
                    onChange={(e) => setFormData({
                      ...formData,
                      description: e.target.value
                    })}
                    className="mt-1"
                  />
                </div>
                <div>
                  <label className="text-sm font-medium">Period</label>
                  <div className="flex gap-2 mt-1">
                    <Button
                      type="button"
                      variant={formData.period === 'WEEKLY' ? 'default' : 'outline'}
                      onClick={() => setFormData({
                        ...formData,
                        period: 'WEEKLY'
                      })}
                    >
                      Weekly
                    </Button>
                    <Button
                      type="button"
                      variant={formData.period === 'MONTHLY' ? 'default' : 'outline'}
                      onClick={() => setFormData({
                        ...formData,
                        period: 'MONTHLY'
                      })}
                    >
                      Monthly
                    </Button>
                  </div>
                </div>
                <div>
                  <label className="text-sm font-medium">Assignee</label>
                  <Select
                    value={formData.assignee}
                    onValueChange={(value) => setFormData({
                      ...formData,
                      assignee: value
                    })}
                  >
                    <SelectTrigger>
                      <SelectValue>
                        {assigneeOptions.find(option => option.value === formData.assignee)?.label || 'Select Assignee'}
                      </SelectValue>
                    </SelectTrigger>
                    <SelectContent>
                      {assigneeOptions.map(option => (
                        <SelectItem key={option.value} value={option.value}>
                          {option.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
                <Droppable droppableId={kpi.id}>
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef} className="space-y-4">
                      <TargetList kpi={kpi} targets={kpi.targets} setKPI={setKpi} />
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
                <div className="flex gap-2 pt-4">
                  <Button
                    variant="outline"
                    onClick={() => navigate('/kpi_management')}
                    className="w-full"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSave}
                    className="w-full bg-btn"
                  >
                    Save Changes
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>

          {/* Template Library */}
          <TaskTemplateLibrary />
        </div>
      </DragDropContext>
    </div>
  );
};

export default function EditKPIPage() {
  return (
    <KPIProvider>
      <TemplateProvider>
        <EditKPIView />
      </TemplateProvider>
    </KPIProvider>
  );
}