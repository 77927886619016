import { Draggable, Droppable } from '@hello-pangea/dnd';
import { useTemplate } from '@/Contexts/TemplateContext';
import { TemplateItem } from './TemplateItem';

export const TemplateList = () => {
  const { state } = useTemplate();

  if (state.loading) {
    return <div className="text-center py-4">Loading templates...</div>;
  }

  if (state.error) {
    return <div className="text-center py-4 text-red-500">{state.error}</div>;
  }

  return (
    <Droppable droppableId="template-library">
      {(provided) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          className="space-y-2"
        >
          {state.templates.map((template, index) => (
            <Draggable
              key={template.id}
              draggableId={template.id.toString()}
              index={index}
            >
              {(provided) => (
                <TemplateItem
                  template={template}
                  provided={provided}
                />
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};