import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import axios from "axios";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faCheckCircle,
  faEdit,
  faPlusCircle,
  faTimes,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { CSSTransition } from "react-transition-group";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import UpdateTaskForm from "../Components/UpdateTaskForm";
import { text } from "@fortawesome/fontawesome-svg-core";

const Tasks = () => {
  const localizer = momentLocalizer(moment);
  const [tasks, setTasks] = useState([]);
  const [popup, setPopup] = useState(false);
  const [loading, setLoading] = useState(true);
  const [del, setDel] = useState(false);
  const [update, setUpdate] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [errorNotification, setErrorNotification] = useState(false);
  const apiUrl = import.meta.env.VITE_API_URL;
  const token = JSON.parse(localStorage.getItem("user"))?.access;
  const id = JSON.parse(localStorage.getItem("user"))?.user_id;

  const validationSchema = Yup.object({
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
    start_date: Yup.date()
      .required("Start date is required")
      .nullable()
      .test(
        "not-in-past",
        "Start date must be today or in the future",
        (value) => {
          return value
            ? new Date(value).getTime() >= new Date().setHours(0, 0, 0, 0)
            : true;
        }
      )
      .test("not-weekend", "Start date cannot be on a weekend", (value) => {
        if (!value) return true;
        const day = new Date(value).getDay();
        return day !== 0 && day !== 6;
      }),
    due_date: Yup.date()
      .required("Due date is required")
      .min(Yup.ref("start_date"), "Due date must be after start date")
      .nullable()
      .test("not-weekend", "Due date cannot be on a weekend", (value) => {
        if (!value) return true;
        const day = new Date(value).getDay();
        return day !== 0 && day !== 6;
      }),
    is_completed: Yup.boolean(),
  });

  const handleTaskSubmit = async (values, { setSubmitting, resetForm }) => {
    const taskData = {
      ...values,
      created_by: parseInt(id),
      start_date: new Date(values.start_date).toISOString(),
      due_date: new Date(values.due_date).toISOString(),
    };

    try {
      const response = await axios.post(
        `${apiUrl}/api/v1/tracker/tasks/new`,
        taskData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const newTask = {
        ...response.data,
        start: new Date(response.data.start_date),
        end: new Date(response.data.due_date),
      };
      setTasks([...tasks, newTask]);
      window.location.reload();
      setShowNotification("Task created successfully!");
      setTimeout(() => setShowNotification(false), 3000);
      resetForm();
      setPopup(false);
    } catch (error) {
      setErrorNotification("Error creating task!");
      setTimeout(() => setErrorNotification(false), 3000);
      console.error("API Error:", error.response?.data || error.message);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (selectedTask || update || popup) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [selectedTask, popup, update]);

  const fetchKpis = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${apiUrl}/api/v1/tracker/tasks/manager/all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const kpiData = response.data.data;
      const filteredKpis = kpiData.filter((kpi) => kpi.created_by.id === id);

      const mappedTasks = filteredKpis.map((kpi) => ({
        id: kpi.id,
        title: kpi.title,
        start: new Date(kpi.start_date),
        end: new Date(kpi.due_date),
        description: kpi.description,
        status: kpi.is_completed === true ? "completed" : "in-progress",
        percentage_completed: kpi.percentage_completed,
        challenges: kpi.challenges,
      }));

      setTasks(mappedTasks);
      setPopup(false);
    } catch (error) {
      console.error("Error fetching KPIs:", error);
    } finally {
      setLoading(false);
    }
  };

  const styles = {
    enter: "transform translate-x-full opacity-0",
    enterActive:
      "transform -translate-x-0 opacity-100 transition-all duration-500 ease-in-out",
    exitActive:
      "transform translate-x-full opacity-0 transition-all duration-500 ease-in-out",
  };

  useEffect(() => {
    fetchKpis();
  }, []);

  const handleSelectEvent = (event) => {
    setSelectedTask(event);
  };

  const closePopup = () => {
    setSelectedTask(null);
  };

  const handleDelete = async (taskId) => {
    setDel(true);
    try {
      const response = await axios.delete(
        `${apiUrl}/api/v1/tracker/tasks/${taskId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }
      );
      setDel(false);
      window.location.reload();
    } catch (err) {
      setDel(false);
      console.error("Delete Error:", err);

      if (err.response) {
        const { status, data } = err.response;
        switch (status) {
          case 404:
            setErrorNotification("Task not found.");
            setTimeout(() => {
              setErrorNotification(false);
            }, 3000);
            break;
          case 401:
            setErrorNotification("Unauthorized. Please log in again.");
            navigate("/login");
            break;
          case 500:
            setErrorNotification("Server error. Please try again later.");
            setTimeout(() => {
              setErrorNotification(false);
            }, 3000);
            break;
          default:
            setErrorNotification("Failed to delete task. Please try again.");
            setTimeout(() => {
              setErrorNotification(false);
            }, 3000);
            break;
        }
      } else if (err.request) {
        setErrorNotification(
          "No response from server. Please check your connection."
        );
      } else {
        setErrorNotification("An unexpected error occurred.");
      }

      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 3000);
    }
  };

  //CSS TRANSITION EFFECT
  const myClassNames = {
    enter: "opacity-0",
    enterActive: "opacity-100 transition-opacity duration-500 ease-in-out",
    exitActive: "opacity-0 transition-opacity duration-500 ease-in-out",
  };

  const navigate = useNavigate();

  return (
    <>
      <div className="mb-24 md:ml-[350px] p-4 bg-white rounded-md shadow-sm">
        <div className="p-4 flex items-center justify-between bg-white rounded-md shadow-md">
          <span className="flex items-center gap-3">
            <FontAwesomeIcon onClick={() => navigate(-1)} icon={faArrowLeft} />
            <h2 className="text-md font-bold">Tasks</h2>
          </span>
          <button
            onClick={() => setPopup(true)}
            className="bg-btn text-white px-3 py-1 rounded-md"
          >
            <FontAwesomeIcon icon={faPlusCircle} />
            &nbsp; Create Task
          </button>
        </div>
        <div className="mt-8">
          {loading ? (
            <div className="flex justify-center items-center h-12">
              <div className="loader border-t-4 border-btn rounded-full w-4 h-4 animate-spin"></div>
            </div>
          ) : (
            <>
              <Calendar
                localizer={localizer}
                events={tasks}
                startAccessor="start"
                endAccessor="end"
                style={{ height: "80vh" }}
                onSelectEvent={handleSelectEvent}
                eventPropGetter={(event) => {
                  const statusColors = {
                    completed: "#34d399",
                    "in-progress": "#fbbf24",
                  };
                  return {
                    style: {
                      backgroundColor: statusColors[event.status] || "#60a5fa",
                      color: "white",
                      borderRadius: "4px",
                      padding: "4px",
                    },
                  };
                }}
              />
            </>
          )}
        </div>

        {/* POPUP */}
        <CSSTransition
          in={popup}
          timeout={300}
          classNames={myClassNames}
          unmountOnExit
        >
          <>
            <div
              className="fixed inset-0 overflow-scroll z-50 flex items-center justify-center bg-black/70 backdrop-blur-sm"
              onClick={() => setPopup(false)}
            >
              <div
                className="relative bg-white rounded-lg shadow-lg w-[90%] max-w-lg animate-fadeIn p-8"
                onClick={(e) => e.stopPropagation()}
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  onClick={() => setPopup(false)}
                  className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 cursor-pointer transition duration-300"
                  size="lg"
                />

                <Formik
                  initialValues={{
                    title: "",
                    description: "",
                    start_date: "",
                    due_date: "",
                    is_completed: false,
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleTaskSubmit}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <h3 className="text-2xl font-semibold text-gray-800 mb-6 text-center">
                        Create New Task
                      </h3>

                      {/* Title */}
                      <div className="mb-5">
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                          Title
                        </label>
                        <Field
                          type="text"
                          name="title"
                          className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 transition duration-300"
                        />
                        <ErrorMessage
                          name="title"
                          component="div"
                          className="text-red-500 text-sm mt-1"
                        />
                      </div>

                      {/* Description */}
                      <div className="mb-5">
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                          Description
                        </label>
                        <Field
                          as="textarea"
                          name="description"
                          className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 transition duration-300"
                          rows="4"
                        />
                        <ErrorMessage
                          name="description"
                          component="div"
                          className="text-red-500 text-sm mt-1"
                        />
                      </div>

                      {/* Date Fields */}
                      <div className="w-full">
                        {/* Start Date */}
                        <div className="w-full">
                          <label className="block text-sm font-medium text-gray-700 mb-2">
                            Start Date
                          </label>
                          <Field
                            type="datetime-local"
                            name="start_date"
                            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 transition duration-300"
                          />
                          <ErrorMessage
                            name="start_date"
                            component="div"
                            className="text-red-500 text-sm mt-1"
                          />
                        </div>

                        {/* Due Date */}
                        <div className="w-full mt-4">
                          <label className="block text-sm font-medium text-gray-700 mb-2">
                            Due Date
                          </label>
                          <Field
                            type="datetime-local"
                            name="due_date"
                            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 transition duration-300"
                          />
                          <ErrorMessage
                            name="due_date"
                            component="div"
                            className="text-red-500 text-sm mt-1"
                          />
                        </div>
                      </div>

                      {/* Submit Button */}
                      <div className="text-center mt-4">
                        <button
                          type="submit"
                          className="w-full py-3 bg-btn text-white rounded-md font-medium text-lg hover:bg-orange-600 focus:outline-none focus:ring-2 transition duration-300"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? "Creating..." : "Create Task"}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </>
        </CSSTransition>

        {/* SELECTED ITEM  */}
        <CSSTransition
          in={selectedTask}
          timeout={300}
          classNames={myClassNames}
          unmountOnExit
        >
          <>
            {selectedTask && (
              <div
                className="fixed inset-0 overflow-scroll z-50 flex items-center justify-center bg-black/70 backdrop-blur-sm"
                onClick={closePopup}
              >
                <div
                  className="relative bg-white rounded-lg shadow-lg w-[90%] max-w-lg animate-fadeIn p-8"
                  onClick={(e) => e.stopPropagation()}
                >
                  <button
                    onClick={closePopup}
                    className="flex justify-self-end mb-4"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                  <div className="text-center">
                    <h3 className="text-3xl font-bold text-gray-800 mb-4">
                      {selectedTask.title}
                    </h3>
                    <span
                      className={`inline-block px-4 py-1 text-sm font-semibold rounded-full ${
                        selectedTask.status === "completed"
                          ? "bg-green-100 text-green-700"
                          : selectedTask.status === "in-progress"
                          ? "bg-yellow-100 text-yellow-700"
                          : "bg-red-100 text-red-700"
                      }`}
                    >
                      {selectedTask.status || "in-progress"}
                    </span>
                  </div>

                  <div className="mt-6 space-y-4">
                    <div className="flex justify-between items-center">
                      <span className="font-medium text-gray-600">
                        Description:
                      </span>
                      <span className="text-gray-800 text-right">
                        {selectedTask.description || "N/A"}
                      </span>
                    </div>

                    <div className="flex justify-between items-center">
                      <span className="font-medium text-gray-600">
                        Completion:
                      </span>
                      <span className="text-gray-800">
                        {selectedTask.status === "completed"
                          ? "100%"
                          : "In Progress"}
                      </span>
                    </div>

                    <div className="flex justify-between items-center">
                      <span className="font-medium text-gray-600">
                        Start Date:
                      </span>
                      <span className="text-gray-800 text-right">
                        {moment(selectedTask.start).format(
                          "MMMM Do YYYY, h:mm"
                        )}
                      </span>
                    </div>

                    <div className="flex justify-between items-center">
                      <span className="font-medium text-gray-600">
                        End Date:
                      </span>
                      <span className="text-gray-800 text-right">
                        {moment(selectedTask.end).format("MMMM Do YYYY, h:mm")}
                      </span>
                    </div>

                    {selectedTask.challenges && (
                      <>
                        <div className="flex justify-between items-center">
                          <span className="font-medium text-gray-600">
                            Challenges:
                          </span>
                          <span className="text-gray-800">
                            {selectedTask.challenges}
                          </span>
                        </div>
                      </>
                    )}

                    <div className="flex gap-4 items-center">
                      <button
                        disabled={selectedTask.status === "completed"}
                        onClick={() => setUpdate(true)}
                        className="bg-btn w-full text-white disabled:opacity-50 px-4 py-2 rounded-full"
                      >
                        <FontAwesomeIcon icon={faEdit} />
                        &nbsp; Update
                      </button>
                      <button
                        onClick={() => handleDelete(selectedTask.id)}
                        className="bg-danger w-full text-white px-4 py-2 rounded-full"
                      >
                        <FontAwesomeIcon icon={faTrashCan} />
                        &nbsp; {del ? "Deleting..." : "Delete"}
                      </button>
                    </div>
                  </div>
                </div>
                <CSSTransition
                  in={update}
                  timeout={300}
                  classNames={myClassNames}
                  unmountOnExit
                >
                  <>
                    <UpdateTaskForm
                      update={update}
                      selectedTask={selectedTask}
                      setUpdate={setUpdate}
                    />
                  </>
                </CSSTransition>
              </div>
            )}
          </>
        </CSSTransition>

        {/* ERROR NOTIFICATION  */}
        <CSSTransition
          in={showNotification}
          classNames={styles}
          timeout={500}
          unmountOnExit
        >
          <div className="p-4 shadow-xl z-50 bg-white fixed right-3 top-10 border-s-2 border-success rounded-md flex items-center gap-4">
            <span className="flex items-center gap-4">
              <FontAwesomeIcon className="text-success" icon={faCheckCircle} />
              <p className="font-light">{showNotification}</p>
            </span>
          </div>
        </CSSTransition>

        {/* SUCCESS NOTIFICATION  */}
        <CSSTransition
          in={errorNotification}
          classNames={styles}
          timeout={500}
          unmountOnExit
        >
          <div className="p-4 shadow-xl z-50 bg-white fixed right-3 top-10 border-s-2 border-danger rounded-md flex items-center gap-4">
            <span className="flex items-center gap-4">
              <FontAwesomeIcon className="text-danger" icon={faTimes} />
              <p className="font-light">{errorNotification}</p>
            </span>
          </div>
        </CSSTransition>
      </div>
    </>
  );
};

export default Tasks;
