import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { CSSTransition } from "react-transition-group";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";

const CreatePopup = ({ styles, edit, setEdit, editRef }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showNotification, setshowNotification] = useState(false);
  const apiUrl = import.meta.env.VITE_API_URL;
  const token = JSON.parse(localStorage.getItem("user"))?.access;

  const styles2 = {
    enter: "transform translate-x-full opacity-0",
    enterActive:
      "transform -translate-x-0 opacity-100 transition-all duration-500 ease-in-out",
    exitActive:
      "transform translate-x-full opacity-0 transition-all duration-500 ease-in-out",
  };

  useEffect(() => {
    if (edit == true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [edit]);

  const initialValues = {
    email: "",
    first_name: "",
    last_name: "",
    other_name: "",
    phone: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format")
      .max(254, "Email is too long")
      .required("Email is required"),
    first_name: Yup.string()
      .max(150, "First name is too long")
      .required("First name is required"),
    last_name: Yup.string()
      .max(150, "Last name is too long")
      .required("Last name is required"),
    other_name: Yup.string()
      .max(150, "Other name is too long")
      .required("Other name is required"),
    phone: Yup.string().required("Phone number is required"),
  });

  const handleSubmit = (values, { resetForm }) => {
    setIsSubmitting(true);

    let formattedPhone = values.phone.replace(/\D/g, "").trim();

    if (!formattedPhone.startsWith("+")) {
      formattedPhone = `+${formattedPhone}`;
    }

    axios
      .post(
        `${apiUrl}/api/v1/auth/create/`,
        {
          ...values,
          phone: formattedPhone,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        setshowNotification(null);
        window.location.reload();
        resetForm();
        setIsSubmitting(false);
      })
      .catch((err) => {
        console.log("Error", err.response);
        const errorMessage =
          err.response?.data?.phone || "An error occurred. Please try again.";
        setshowNotification(errorMessage);
        setTimeout(() => setshowNotification(false), 5000);
        setIsSubmitting(false);
      });
  };

  const myClassNames = {
    enter: "opacity-0",
    enterActive: "opacity-100 transition-opacity duration-500 ease-in-out",
    exitActive: "opacity-0 transition-opacity duration-500 ease-in-out",
  };

  return (
    <>
      <CSSTransition
        in={edit}
        classNames={myClassNames}
        timeout={300}
        unmountOnExit
      >
        <div
          className="fixed inset-0 overflow-scroll z-50 flex items-center justify-center bg-black/70 backdrop-blur-sm"
          onClick={() => setEdit(false)}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="bg-white w-96 p-6 rounded-md shadow-lg relative"
          >
            <button
              onClick={() => setEdit(false)}
              className="absolute top-4 right-4"
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <h2 className="text-lg font-bold mb-4">New Manager</h2>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue, errors, touched }) => (
                <Form>
                  <div className="mb-4">
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <Field
                      type="email"
                      id="email"
                      name="email"
                      className={`border w-full rounded-md p-2 ${
                        errors.email && touched.email
                          ? "border-danger"
                          : "border-gray-300"
                      }`}
                    />
                  </div>

                  <div className="mb-4">
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="first_name"
                    >
                      First Name
                    </label>
                    <Field
                      type="text"
                      id="first_name"
                      name="first_name"
                      className={`border w-full rounded-md p-2 ${
                        errors.first_name && touched.first_name
                          ? "border-danger"
                          : "border-gray-300"
                      }`}
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="last_name"
                    >
                      Last Name
                    </label>
                    <Field
                      type="text"
                      id="last_name"
                      name="last_name"
                      className={`border w-full rounded-md p-2 ${
                        errors.last_name && touched.last_name
                          ? "border-danger"
                          : "border-gray-300"
                      }`}
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="other_name"
                    >
                      Other Name
                    </label>
                    <Field
                      type="text"
                      id="other_name"
                      name="other_name"
                      className={`border w-full rounded-md p-2 ${
                        errors.other_name && touched.other_name
                          ? "border-danger"
                          : "border-gray-300"
                      }`}
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="phone"
                    >
                      Phone Number
                    </label>
                    <PhoneInput
                      country={"gb"}
                      value={values.phone}
                      onChange={(phone) => setFieldValue("phone", phone)}
                      inputStyle={{
                        width: "100%",
                        border:
                          errors.phone && touched.phone
                            ? "1px solid #E53E3E"
                            : "1px solid #D1D5DB",
                      }}
                      enableSearch
                    />
                  </div>

                  {isSubmitting ? (
                    <button type="submit" disabled className="mt-8 w-[100%]">
                      <section className="dots-container">
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                      </section>
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="bg-btn hover:bg-orange-600 mt-8 w-full text-white rounded-lg py-2"
                    >
                      Save
                    </button>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </CSSTransition>

      <CSSTransition
        in={showNotification}
        classNames={styles2}
        timeout={500}
        unmountOnExit
      >
        <div className="p-4 shadow-xl z-50 bg-white fixed right-3 top-10 border-s-2 border-danger rounded-md flex items-center gap-4">
          <span className="flex items-center gap-4">
            <FontAwesomeIcon className="text-danger" icon={faTimes} />
            <p className="font-light">{showNotification}</p>
          </span>
        </div>
      </CSSTransition>
    </>
  );
};

export default CreatePopup;
