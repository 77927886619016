import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";

const UpdateFeedbackForm = ({ setUpdateForm, selectedFeedback }) => {
  const [clients, setClients] = useState([]);
  const [submitting, setIsSubmitting] = useState(false);
  const id = JSON.parse(localStorage.getItem("user"))?.user_id || "";
  const apiUrl = import.meta.env.VITE_API_URL;
  const token = JSON.parse(localStorage.getItem("user"))?.access;

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/v1/tracker/clients/all?manager=${id}&logged_by=${id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        const clientOptions = response.data.data.map((client) => ({
          value: client.id,
          label: `${client.last_name} ${client.first_name}`,
        }));
        setClients(clientOptions);
      } catch (error) {
        console.error(
          "Error fetching clients:",
          error.response?.data || error.message
        );
      }
    };

    fetchClients();
  }, []);

  const feedbackSchema = Yup.object().shape({
    client: Yup.string().required("Please select a client"),
    complaint: Yup.string(),
    compliment: Yup.string(),
    improvement_plan: Yup.string(),
  });

  return (
    <div
      className="fixed inset-0 overflow-scroll z-50 flex items-center justify-center bg-black/70 backdrop-blur-sm"
      onClick={() => setUpdateForm(false)}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="bg-white w-96 p-6 rounded-md shadow-lg relative"
      >
        <button
          onClick={() => setUpdateForm(false)}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-800"
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2 className="text-2xl font-bold mb-4">Update Feedback</h2>
        <Formik
          initialValues={{
            client: selectedFeedback?.client_name || "",
            complaint: selectedFeedback?.complaint || "",
            compliment: selectedFeedback?.compliment || "",
            improvement_plan: selectedFeedback?.improvement_plan || "",
          }}
          validationSchema={feedbackSchema}
          onSubmit={async (values, { setSubmitting, setFieldError }) => {
            const formData = Object.fromEntries(
              Object.entries(values)
                .filter(([key, value]) => {
                  if (key === "client") {
                    return value && value.value ? true : false;
                  }
                  return value !== "" && value !== null;
                })
                .map(([key, value]) => {
                  if (key === "client") {
                    return [key, value?.value || ""];
                  }
                  if (
                    key === "compliment" &&
                    !values.complaint &&
                    value === ""
                  ) {
                    return [key, null];
                  }
                  return [key, value];
                })
            );
            setIsSubmitting(true);

            try {
              await axios.patch(
                `${apiUrl}/api/v1/tracker/feedbacks/${selectedFeedback.id}`,
                formData,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                  },
                  withCredentials: true,
                }
              );
              setUpdateForm(false);
              setIsSubmitting(false);
              window.location.reload();
            } catch (error) {
              setIsSubmitting(false);

              const errorData = error.response?.data;
              console.log(errorData);

              if (errorData?.field_name === "non_field_errors") {
                setFieldError("form", errorData.error);
              } else if (errorData?.field_name) {
                setFieldError(errorData.field_name, errorData.error);
              } else {
                setFieldError(
                  "form",
                  "An error occurred while updating feedback."
                );
              }

              setSubmitting(false);
            }
          }}
        >
          {({ values, setFieldValue, errors, touched }) => (
            <Form>
              <div className="mb-4">
                <label className="block font-medium" htmlFor="client">
                  Select Client
                </label>
                <Field
                  name="client"
                  placeholder="Choose a client"
                  className="w-full border p-2 rounded"
                  disabled
                />
                {errors.client && touched.client && (
                  <div className="text-red-500 text-sm">{errors.client}</div>
                )}
              </div>
              <div className="mb-4">
                <label className="block font-medium" htmlFor="complaint">
                  Complaint
                </label>
                <Field
                  name="complaint"
                  as="textarea"
                  placeholder="Enter complaint details"
                  className="w-full border p-2 rounded"
                  disabled={!selectedFeedback?.complaint}
                />
                <ErrorMessage
                  name="complaint"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div className="mb-4">
                <label className="block font-medium" htmlFor="compliment">
                  Compliment
                </label>
                <Field
                  name="compliment"
                  as="textarea"
                  placeholder="Enter compliment details"
                  className="w-full border p-2 rounded"
                  disabled={!selectedFeedback?.compliment}
                />
                <ErrorMessage
                  name="compliment"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div className="mb-4">
                <label className="block font-medium" htmlFor="improvement_plan">
                  Improvement Plan
                </label>
                <Field
                  name="improvement_plan"
                  as="textarea"
                  placeholder="Enter improvement plan"
                  className="w-full border p-2 rounded"
                  disabled={!selectedFeedback?.improvement_plan}
                />
                <ErrorMessage
                  name="improvement_plan"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div className="w-full">
                <button
                  type="submit"
                  className="w-full bg-btn text-white py-3 rounded-lg font-medium hover:bg-orange-600 transition"
                  disabled={submitting}
                >
                  {submitting ? "Submitting..." : "Submit"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default UpdateFeedbackForm;
