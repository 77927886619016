import React from "react";
import PerformingManagers from "../Components/PerformingManagers";
import KpiAchievementRate from "../Components/KpiAchievementRate";
import FeebackSentiment from "../Components/FeebackSentiment";
import PeriodContextSelector from "../Components/PeriodContextSelector";
import ManagerTaskCompletion from "../Components/ManagerTaskCompletion";
import ManagerAchievementRate from "../Components/ManagerAchievementRate";
import ManagerKpis from "../Components/ManagerKpis";

const Dashboard = () => {
  const user = JSON.parse(localStorage.getItem("user"))?.user_type;

  return (
    <>
      <div className="bg-[#F8F9FC] md:ml-[350px]">
        {user === "ADMIN" ? (
          <>
            <div className="p-4">
              <PeriodContextSelector />
            </div>
            <KpiAchievementRate />
            <FeebackSentiment />
            <PerformingManagers />
          </>
        ) : (
          <>
            <div className="p-4 pb-24">
              <PeriodContextSelector />
              <ManagerTaskCompletion />
              <ManagerAchievementRate />
              <ManagerKpis />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Dashboard;
