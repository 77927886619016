import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCheckCircle,
  faPaperPlane,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { CSSTransition } from "react-transition-group";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const OTP = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showNotification, setshowNotification] = useState(false);
  const [success, setSuccess] = useState(false);
  const [seconds, setSeconds] = useState(59);
  const [countdownComplete, setCountdownComplete] = useState(false);
  const apiUrl = import.meta.env.VITE_API_URL;
  const savedEmail = localStorage.getItem("Email");
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  const initialValues = {
    email: savedEmail || "",
    otp: "",
  };

  //VALIDATION SCHEMA
  const Validation = yup.object().shape({
    email: yup
      .string()
      .email("Please use a valid email address")
      .required("Email is required"),
    otp: yup.number().required("OTP is required"),
  });

  //FORM SUBMISSION FUNCTION
  const submitForm = (values, { resetForm }) => {
    setIsSubmitting(true);
    axios
      .post(`${apiUrl}/api/v1/auth/verify-otp/`, values, {
        withCredentials: true,
      })
      .then((response) => {
        setSuccess("OTP verified successfully!");
        setIsSubmitting(false);
        setTimeout(() => {
          setSuccess(false);
          navigate(`/update_password`);
        }, 3000);
        resetForm();
      })
      .catch((err) => {
        console.error("Error:", err);
        console.error("API Error:", err.response?.data || err.message);

        if (err.response) {
          const { status, data } = err.response;
          if (status === 400) {
            setshowNotification(
              data.message || "Invalid OTP. Please check the code you entered."
            );
          } else if (status === 401) {
            setshowNotification(
              data.message ||
                "Unauthorized access. Please verify your identity."
            );
          } else if (status === 404) {
            setshowNotification(
              data.message || "OTP not found or has expired."
            );
          } else if (status === 500) {
            setshowNotification(
              data.message || "Server error. Please try again later."
            );
          } else {
            setshowNotification(
              data.message || "An error occurred. Please try again."
            );
          }
        } else if (err.request) {
          setshowNotification(
            "No response from the server. Please check your internet connection."
          );
        } else {
          setshowNotification("An unexpected error occurred.");
        }

        setTimeout(() => {
          setshowNotification(false);
        }, 5000);
        setIsSubmitting(false);
        resetForm();
      });
  };

  const formatTime = (time) => {
    return time < 10 ? `0${time}` : time;
  };

  //FUCTION TO RESEND OTP ALONG WITH THE COUNTDOWN
  useEffect(() => {
    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(intervalId);
          setCountdownComplete(true);
          return 0;
        } else {
          return prevSeconds - 1;
        }
      });
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const handleResendOTP = () => {
    setSeconds(59);
    setCountdownComplete(false);
    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(intervalId);
          setCountdownComplete(true);
          return 0;
        } else {
          return prevSeconds - 1;
        }
      });
    }, 1000);

    axios
      .post(
        `${apiUrl}/api/v1/auth/request-otp/`,
        { email: savedEmail },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        setSuccess("Your OTP has been resent to your email.");
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
      })
      .catch((err) => {
        console.error("Error:", err);
        setshowNotification("Failed to resend OTP. Please try again.");
        setTimeout(() => {
          setshowNotification(false);
        }, 3000);
      });
  };

  // CSS TRANSITION STYLES
  const styles = {
    enter: "transform translate-x-full opacity-0",
    enterActive:
      "transform -translate-x-0 opacity-100 transition-all duration-500 ease-in-out",
    exitActive:
      "transform translate-x-full opacity-0 transition-all duration-500 ease-in-out",
  };

  return (
    <>
      {/* THE FORM USING FORMIK AND YUP FOR VALIDATION */}
      <div className="flex justify-center items-center w-full h-screen">
        <Formik
          initialValues={initialValues}
          validationSchema={Validation}
          onSubmit={submitForm}
        >
          <Form className="flex items-center flex-col md:w-[50%] w-[100%]">
            <img className="mb-8 w-[200px]" src="/logo-black.png" />
            <h5 className="text-primary text-2xl font-bold mb-3">
              OTP VALIDATION
            </h5>
            <h5 className="text-primary mb-8">
              Input the OTP sent to your email address.
            </h5>
            <span className="h-[1px] bg-slate-200/100"></span>

            <div className="mt-8 w-full flex items-center flex-col">
              <Field
                type="email"
                name="email"
                placeholder="Email"
                readOnly="true"
                className="bg-transparent border flex items-center focus:invalid:border-danger focus:invalid:ring-danger invalid:border-danger invalid:text-danger focus:border-focus focus:ring-1 focus:ring-focus justify-between w-[70%] outline-none mb-4 rounded-md p-2"
              />

              <Field
                type="number"
                name="otp"
                placeholder="OTP"
                className="bg-transparent border flex items-center focus:invalid:border-danger focus:invalid:ring-danger invalid:border-danger invalid:text-danger focus:border-focus focus:ring-1 focus:ring-focus justify-between w-[70%] outline-none rounded-md p-2"
              />
              {isSubmitting ? (
                <button type="submit" disabled className="mt-8">
                  <section className="dots-container">
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                  </section>
                </button>
              ) : (
                <button
                  type="submit"
                  className="bg-btn mt-8 w-[70%] transition border-btn border duration-300 ease-in-out text-white hover:border hover:bg-transparent hover:text-btn flex items-center justify-center cursor-pointer rounded-lg py-2"
                >
                  <div>
                    Continue &nbsp;
                    <FontAwesomeIcon icon={faArrowRight} />
                  </div>
                </button>
              )}
            </div>

            {/* OTP COUNTDOWN */}
            <div className="flex flex-col items-center mt-8 gap-4">
              <small>Didn't receive an OTP?</small>
              {countdownComplete && (
                <button
                  className="cursor-pointer items-center hover:bg-transparent border transition ease-in duration-300 border-btn py-2 px-6 rounded-md"
                  onClick={handleResendOTP}
                >
                  <FontAwesomeIcon icon={faPaperPlane} /> &nbsp; Resend OTP
                </button>
              )}
              {!countdownComplete && (
                <p>
                  Resend OTP in {formatTime(Math.floor(seconds / 60))}:
                  {formatTime(seconds % 60)}
                </p>
              )}
            </div>

            <small className="mt-12 text-primary cursor-pointer">
              &copy; Trakitt HQ, {currentYear}.
            </small>
          </Form>
        </Formik>
      </div>

      {/* THE NOTIFICATION FOR ERRORS */}
      <CSSTransition
        in={showNotification}
        classNames={styles}
        timeout={500}
        unmountOnExit
      >
        <div className="p-4 shadow-xl z-50 bg-white fixed right-3 top-10 border-s-2 border-danger rounded-md flex items-center gap-4">
          <span className="flex items-center gap-4">
            <FontAwesomeIcon className="text-danger" icon={faTimes} />
            <p className="font-light">{showNotification}</p>
          </span>
        </div>
      </CSSTransition>

      <CSSTransition
        in={success}
        classNames={styles}
        timeout={500}
        unmountOnExit
      >
        <div className="p-4 shadow-xl z-50 bg-white fixed right-3 top-10 border-s-2 border-success rounded-md flex items-center gap-4">
          <span className="flex items-center gap-4">
            <FontAwesomeIcon className="text-success" icon={faCheckCircle} />
            <p className="font-light">{success}</p>
          </span>
        </div>
      </CSSTransition>
    </>
  );
};

export default OTP;
