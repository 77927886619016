import React, { useEffect } from "react";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";

const AdminNotesPopup = ({
  selectedResource,
  setResolveFalse,
  setResources,
}) => {
  const apiUrl = import.meta.env.VITE_API_URL;
  const token = JSON.parse(localStorage.getItem("user"))?.access || "";

  const validationSchema = Yup.object({
    admin_notes: Yup.string()
      .required("Notes are required.")
      .min(1, "Notes must be at least 1 character."),
    status: Yup.string()
      .oneOf(["accepted", "rejected"], "Invalid status selection")
      .required("Status is required."),
  });

  const initialValues = {
    admin_notes: selectedResource?.admin_notes || "",
    status: "",
  };

  const updateResolvedStatus = async (id, resolvedStatus, adminNotes) => {
    try {
      const response = await axios.patch(
        `${apiUrl}/api/v1/tracker/resource-requests/${id}/resolve`,
        {
          status: resolvedStatus,
          admin_notes: adminNotes,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      window.location.reload();
      if (setResources) {
        setResources((prev) =>
          prev.map((resource) =>
            resource.id === id
              ? { ...resource, status: resolvedStatus, admin_notes: adminNotes }
              : resource
          )
        );
      }

      setResolveFalse(false);
    } catch (error) {
      console.error(
        "Error updating resource status:",
        error.response?.data || error.message
      );
    }
  };

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black/70 backdrop-blur-sm"
      onClick={() => setResolveFalse(false)}
    >
      <div
        className="relative bg-white rounded-lg shadow-lg w-[90%] max-w-lg p-8"
        onClick={(e) => e.stopPropagation()}
      >
        <h3 className="text-xl font-bold text-gray-800 mb-4 text-center">
          {selectedResource.name}
        </h3>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              await updateResolvedStatus(
                selectedResource.id,
                values.status,
                values.admin_notes
              );
            } catch (error) {
              console.error(
                "API Error:",
                error.response?.data || error.message
              );
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ values, handleChange }) => (
            <Form className="space-y-4">
              <div>
                <label htmlFor="status" className="font-medium text-gray-600">
                  Status
                </label>
                <Field
                  as="select"
                  name="status"
                  id="status"
                  className="w-full p-2 border rounded mt-1"
                  value={values.status}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    Select a status
                  </option>
                  <option value="accepted">Accepted</option>
                  <option value="rejected">Rejected</option>
                </Field>
                <ErrorMessage
                  name="status"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>

              <div>
                <label
                  htmlFor="admin_notes"
                  className="font-medium text-gray-600"
                >
                  Admin Notes
                </label>
                <Field
                  as="textarea"
                  name="admin_notes"
                  id="admin_notes"
                  className="w-full p-2 border rounded mt-1"
                  placeholder="Enter your notes..."
                  value={values.admin_notes}
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="admin_notes"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>

              <div className="flex gap-4">
                <button
                  type="submit"
                  className="px-4 py-2 w-full rounded bg-green-500 text-white hover:bg-green-600"
                >
                  Submit
                </button>
                <button
                  type="button"
                  className="px-4 py-2 w-full rounded bg-red-500 text-white hover:bg-red-600"
                  onClick={() => setResolveFalse(false)}
                >
                  Cancel
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AdminNotesPopup;
