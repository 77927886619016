import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from '@/lib/axios';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { format } from 'date-fns';
import { ArrowLeft, Laptop2, Loader2, Monitor, Smartphone, Tablet, XCircle } from "lucide-react";
import LocationDialog from '@/Components/LocationDialog';

interface Location {
  ipaddress: string;
  city: string;
  region: string;
  country: string;
  lat: number | null;
  lon: number | null;
}

interface Session {
  logged_in: string;
  logged_out: string | null;
  duration: string;
  is_active: boolean;
  hours_spent: number;
  location: Location | null;
}

interface User {
  id: string;
  first_name: string;
  last_name: string;
  user_type: string;
}

interface Device {
  id: string;
  user: User;
  browser: string;
  sessions: Session[];
  created_at: string;
  device_type: 'mobile' | 'tablet' | 'desktop';
  operating_system: string;
}

const EmptyDevices: React.FC = () => (
  <div className="flex flex-col items-center justify-center p-8 text-center">
    <Laptop2 className="w-16 h-16 text-gray-300 mb-4" />
    <h3 className="text-xl font-semibold text-gray-700 mb-2">No Devices Found</h3>
    <p className="text-gray-500 max-w-sm">
      This user hasn't logged in from any devices yet. Devices will appear here once they start using the system.
    </p>
  </div>
);

const EmptySessions: React.FC = () => (
  <div className="flex flex-col items-center justify-center p-8 text-center">
    <XCircle className="w-16 h-16 text-gray-300 mb-4" />
    <h3 className="text-xl font-semibold text-gray-700 mb-2">No Sessions Found</h3>
    <p className="text-gray-500 max-w-sm">
      There are no recorded sessions for this device yet. Sessions will be listed here once the device is used.
    </p>
  </div>
);

const ManagerDevices: React.FC = () => {
  const [devices, setDevices] = useState<Device[]>([]);
  const [selectedDevice, setSelectedDevice] = useState<Device | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { managerId } = useParams<{ managerId: string }>();

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const response = await axios.get<{data: Device[]}>(`/auth/user-devices/${managerId}`);
        setDevices(response.data.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch device data');
        setLoading(false);
      }
    };

    fetchDevices();
  }, [managerId]);

  const getDeviceIcon = (deviceType: Device['device_type']) => {
    switch (deviceType) {
      case 'mobile':
        return <Smartphone className="w-6 h-6" />;
      case 'tablet':
        return <Tablet className="w-6 h-6" />;
      case 'desktop':
        return <Monitor className="w-6 h-6" />;
      default:
        return <Monitor className="w-6 h-6" />;
    }
  };

  const formatDuration = (hours: number): string => {
    if (hours < 1) {
      return `${Math.round(hours * 60)} minutes`;
    }
    return `${Math.round(hours)} hours`;
  };

  const getLastActiveTime = (sessions: Session[]): string => {
    if (sessions.length === 0) return 'Never';
    const lastSession = sessions[0]; // Assuming sessions are sorted by logged_in
    return format(new Date(lastSession.logged_in), 'MMM d, yyyy h:mm a');
  };

  const getActiveSessionCount = (sessions: Session[]): number => {
    return sessions.filter(session => session.is_active).length;
  };

  if (loading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 text-danger">{error}</div>
    );
  }

  if (selectedDevice) {
    return (
      <div className="bg-[#F8F9FC] md:ml-[350px] h-screen p-6">
        <button
          onClick={() => setSelectedDevice(null)}
          className="flex items-center gap-2 text-focus hover:text-focus/80 mb-6"
        >
          <ArrowLeft className="w-4 h-4" />
          Back to Devices
        </button>

        <div className="flex items-center gap-4 mb-6">
          {getDeviceIcon(selectedDevice.device_type)}
          <div>
            <h1 className="text-2xl font-bold">{selectedDevice.browser}</h1>
            <p className="text-gray-500">{selectedDevice.operating_system}</p>
          </div>
        </div>

        {selectedDevice.sessions.length > 0 ? (
          <Card>
            <CardContent className="p-0">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Login Time</TableHead>
                    <TableHead>Duration</TableHead>
                    <TableHead>Location</TableHead>
                    <TableHead>Status</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {selectedDevice.sessions.map((session) => (
                    <TableRow key={session.logged_in}>
                      <TableCell>
                        {format(new Date(session.logged_in), 'MMM d, yyyy h:mm a')}
                      </TableCell>
                      <TableCell>
                        {formatDuration(session.hours_spent)}
                      </TableCell>
                      <TableCell>
                        {session.location ? (
                          <div>
                            {session.location.lat && session.location.lon ? (
                              // @ts-ignore
                              <LocationDialog location={session.location} />
                            ) : (
                              `${session.location.city}, ${session.location.country}`
                            )}
                            <div className="text-sm text-gray-500">
                              {session.location.ipaddress}
                            </div>
                          </div>
                        ) : (
                          "Location not available"
                        )}
                      </TableCell>
                      <TableCell>
                        <span className={`px-2 py-1 rounded-full text-sm ${session.is_active
                            ? 'bg-green-100 text-green-800'
                            : 'bg-gray-100 text-gray-800'
                          }`}>
                          {session.is_active ? 'Active' : 'Inactive'}
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        ) : (
          <Card>
            <CardContent>
              <EmptySessions />
            </CardContent>
          </Card>
        )}
      </div>
    );
  }

  return (
    <div className="bg-[#F8F9FC] md:ml-[350px] h-screen p-6">
      <h1 className="text-2xl font-bold mb-6">Devices</h1>

      {devices.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {devices.map((device) => (
            <Card
              key={device.id}
              className="cursor-pointer hover:shadow-lg transition-shadow"
              onClick={() => setSelectedDevice(device)}
            >
              <CardHeader className="flex flex-row items-center gap-4">
                {getDeviceIcon(device.device_type)}
                <div>
                  <CardTitle className="text-lg">{device.browser}</CardTitle>
                  <p className="text-sm text-gray-500">{device.operating_system}</p>
                </div>
              </CardHeader>
              <CardContent>
                <div className="space-y-2">
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-500">Last Active:</span>
                    <span>{getLastActiveTime(device.sessions)}</span>
                  </div>
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-500">Active Sessions:</span>
                    <span className="font-medium">
                      {getActiveSessionCount(device.sessions)}
                    </span>
                  </div>
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-500">Total Sessions:</span>
                    <span>{device.sessions.length}</span>
                  </div>
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      ) : (
        <Card>
          <CardContent>
            <EmptyDevices />
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default ManagerDevices;