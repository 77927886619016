import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { AuthProvider } from "./Contexts/AuthContext.jsx";
import App from "./App.jsx";
import { BrowserRouter } from "react-router-dom";
import { PeriodProvider } from "./Contexts/PeriodContext.jsx";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://56415835a72753bb7f6d27bfc3ffa5a6@o4506900881670144.ingest.us.sentry.io/4508340778696704",
  integrations: [Sentry.replayIntegration()],
  enabled: import.meta.env.MODE === "production",
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

createRoot(document.getElementById("root")).render(
  <BrowserRouter>
    <AuthProvider>
      <PeriodProvider>
        <StrictMode>
          <App />
        </StrictMode>
      </PeriodProvider>
    </AuthProvider>
  </BrowserRouter>
);
