import React, { useState, useEffect } from 'react';
import { Draggable } from '@hello-pangea/dnd';
import { KPI, useKPI } from '@/Contexts/KpiContext';
import { Input } from '@/components/ui/input';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion"
import { Trash, Trash2 } from 'lucide-react';


type Target = {
  id?: string;
  name: string;
  description: string;
  total_expected: number;
  is_inverted: boolean;
};

export const TargetForm = ({
  target,
  onUpdate
}: {
  target: Target;
  onUpdate: (target: Target) => void;
}) => {
  const [targetData, setTargetData] = useState(target);

  useEffect(() => {
    onUpdate(targetData);
  }, [targetData]);

  return (
    <div className="p-4 bg-white border rounded-lg shadow-sm">
      <div className="space-y-4">
        <div>
          <label className="text-sm font-medium">Name</label>
          <Input
            value={targetData.name}
            onChange={(e) => setTargetData({
              ...targetData,
              name: e.target.value
            })}
            className="mt-1"
          />
        </div>
        <div>
          <label className="text-sm font-medium">Description</label>
          <Input
            value={targetData.description}
            onChange={(e) => setTargetData({
              ...targetData,
              description: e.target.value
            })}
            className="mt-1"
          />
        </div>
        <div>
          <label className="text-sm font-medium">Expected Target</label>
          <Input
            type="number"
            value={targetData.total_expected}
            onChange={(e) => setTargetData({
              ...targetData,
              total_expected: parseInt(e.target.value)
            })}
            className="mt-1"
          />
        </div>
        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            checked={targetData.is_inverted}
            onChange={(e) => setTargetData({
              ...targetData,
              is_inverted: e.target.checked
            })}
            className="rounded border-gray-300"
          />
          <label className="text-sm">Invert target</label>
        </div>
      </div>
    </div>
  );
}

export const TargetList = ({ kpi, targets, setKPI }: { kpi: string | KPI; targets: Target[], setKPI: (kpi: KPI) => void }) => {
  const { updateKpiTargets } = useKPI();

  const handleUpdateTarget = (updatedTarget: Target) => {
    const newTargets = targets.map(target =>
      target.id === updatedTarget.id ? updatedTarget : target
    );
    if (typeof kpi === 'string') {
      updateKpiTargets(kpi, newTargets);
    } else {
      setKPI({ ...kpi, targets: newTargets });
    }
  };

  const handleDeleteTarget = (targetId: string) => {
    const newTargets = targets.filter(target => target.id !== targetId);
    if (typeof kpi === 'string') {
      updateKpiTargets(kpi, newTargets);
    } else {
      setKPI({ ...kpi, targets: newTargets });
    }
  };

  if (targets.length === 0) {
    return (
      <div className="text-center p-4 border-2 border-dashed rounded-lg">
        <p className="text-gray-500">
          Drag templates here to add targets
        </p>
      </div>
    );
  }

  return (
    <Accordion type="single" collapsible className="space-y-2">
      {targets.map((target, index) => (
      <Draggable
        key={target.id}
        draggableId={index.toString()}
        index={index}
      >
        {(provided) => (
        <AccordionItem
          value={`item-${index}`}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <AccordionTrigger className="hover:no-underline">
            <div className="flex justify-between items-center w-full">
              <span className="text-sm font-medium">{target.name}</span>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteTarget(target.id!);
                }}
                className="px-2 py-1 text-sm text-red-600 hover:text-red-800"
              >
                <Trash2 className="w-4 h-4" />
              </button>
            </div>
          </AccordionTrigger>
          <AccordionContent>
          <TargetForm
            target={target}
            onUpdate={handleUpdateTarget}
          />
          </AccordionContent>
        </AccordionItem>
        )}
      </Draggable>
      ))}
    </Accordion>
  );
};