import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { faCamera, faUserAlt } from "@fortawesome/free-solid-svg-icons";
import * as yup from "yup";
import axios from "axios";
import {
  faArrowRight,
  faPenAlt,
  faUser,
  faEye,
  faEyeSlash,
  faCheckCircle,
  faTimes,
  faPaperPlane,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { CSSTransition } from "react-transition-group";
import { logoutUser } from "../lib/auth-utils";

const Profile = () => {
  const [signingOut, setSigningOut] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [seconds, setSeconds] = useState(59);
  const [countdownComplete, setCountdownComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [user, setUser] = useState("");
  const [intervalId, setIntervalId] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [errorNotification, setErrorNotification] = useState(false);
  const apiUrl = import.meta.env.VITE_API_URL;
  const token = JSON.parse(localStorage.getItem("user"))?.access || "";
  const navigate = useNavigate();
  const [profilePicture, setProfilePicture] = useState(null);
  const [uploading, setUploading] = useState(false);
  const currentYear = new Date().getFullYear();

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("profile_picture", file);

    try {
      setUploading(true);
      const response = await axios.patch(
        `${apiUrl}/api/v1/auth/me/profile-picture`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          maxRedirects: 5, // Allow up to 5 redirects
        }
      );
      if (response.data.data.profile_picture_url) {
        const imageResponse = await fetch(
          response.data.data.profile_picture_url
        );
        setProfilePicture(imageResponse.url); // Use the final URL after redirects
      }
      console.log(response.data.data);
    } catch (error) {
      console.error(error.response?.data || error.message);
    } finally {
      setUploading(false);
    }
  };

  const clearCountdown = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  };

  const startCountdown = () => {
    clearCountdown();
    setSeconds(59);
    setCountdownComplete(false);

    const id = setInterval(() => {
      setSeconds((prev) => {
        if (prev <= 1) {
          clearInterval(id);
          setCountdownComplete(true);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    setIntervalId(id);
  };

  const handleLogout = () => {
    setSigningOut(true);
    logoutUser().then(() => {
      setTimeout(() => {
        navigate("/login");
        setSigningOut(false);
      }, 1000);
    });
  };

  const showOtpForm = async () => {
    setOtpSent(true);
    startCountdown();
    const email = localStorage.getItem("email");

    if (!email) {
      clearCountdown();
      setErrorNotification("No email found in local storage. Please log in.");
      return;
    }

    try {
      setLoading(true);
      await axios.post(
        `${apiUrl}/api/v1/auth/request-otp/`,
        { email },
        { withCredentials: true }
      );
      setShowNotification("An OTP has been sent to your email!");
      setTimeout(() => {
        setShowNotification(false);
      }, 3000);
    } catch (err) {
      clearCountdown();
      setErrorNotification("Failed to request OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleResendOTP = async () => {
    startCountdown();
    const email = localStorage.getItem("email");

    if (!email) {
      clearCountdown();
      setErrorNotification("No email found in local storage.");
      setTimeout(() => {
        setErrorNotification(false);
      }, 3000);
      return;
    }

    try {
      await axios.post(
        `${apiUrl}/api/v1/auth/request-otp/`,
        { email },
        {
          withCredentials: true,
        }
      );
      setShowNotification("OTP has been resent to your email");
      setTimeout(() => {
        setShowNotification(false);
      }, 3000);
    } catch (err) {
      clearCountdown(); // Stop countdown on error
      setErrorNotification("Failed to resend OTP. Please try again.");
      setTimeout(() => {
        setErrorNotification(false);
      }, 3000);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${apiUrl}/api/v1/auth/me`, {
          headers: { Authorization: `Bearer ${token}` },
          maxRedirects: 5,
        });
        setUser(response.data);
        let profilePicUrl = response.data.profile_picture;
        profilePicUrl = profilePicUrl.replace("l", "");
        setProfilePicture(profilePicUrl);
        localStorage.setItem("email", response.data.email);
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
    return clearCountdown;
  }, [apiUrl, token]);

  const otpValidationSchema = yup.object().shape({
    otp: yup
      .string()
      .min(6, "OTP must be exactly 6 digits")
      .max(6, "OTP must be exactly 6 digits")
      .matches(/^\d+$/, "OTP must contain only digits")
      .required("OTP is required"),
  });

  const handleOtpSubmit = async (values, { resetForm }) => {
    const email = localStorage.getItem("email");
    if (!email) {
      setErrorNotification("Email not found in local storage.");
      return;
    }

    const payload = { email, ...values };
    setIsSubmitting(true);
    try {
      const response = await axios.post(
        `${apiUrl}/api/v1/auth/verify-otp/`,
        payload,
        {
          withCredentials: true,
        }
      );
      setIsSubmitting(false);
      setShowNotification("OTP verified successfully!");
      navigate("/profile/password_update");
      setTimeout(() => {
        setShowNotification(false);
      }, 3000);

      resetForm();
    } catch (err) {
      setIsSubmitting(false);
      console.error("API Error:", err.response?.data || err.message);

      if (err.response) {
        const { status, data } = err.response;

        const errorMessage =
          data?.message ||
          data?.error ||
          "An error occurred. Please try again.";

        switch (status) {
          case 400:
            setErrorNotification(
              errorMessage || "Invalid OTP. Please check the code you entered."
            );
            break;
          case 401:
            setErrorNotification(
              errorMessage ||
                "Unauthorized access. Please verify your identity."
            );
            break;
          case 404:
            setErrorNotification(
              errorMessage || "OTP not found or has expired."
            );
            break;
          case 500:
            setErrorNotification(
              errorMessage || "Server error. Please try again later."
            );
            break;
          default:
            setErrorNotification(errorMessage);
            break;
        }
      } else if (err.request) {
        setErrorNotification(
          "No response from the server. Please check your internet connection."
        );
      } else {
        setErrorNotification("An unexpected error occurred.");
      }

      setTimeout(() => {
        setErrorNotification(false);
      }, 5000);
    }
  };

  const myClassNames = {
    enter: "opacity-0",
    enterActive: "opacity-100 transition-opacity duration-500 ease-in-out",
    exitActive: "opacity-0 transition-opacity duration-500 ease-in-out",
  };

  const styles = {
    enter: "transform translate-x-full opacity-0",
    enterActive:
      "transform -translate-x-0 opacity-100 transition-all duration-500 ease-in-out",
    exitActive:
      "transform translate-x-full opacity-0 transition-all duration-500 ease-in-out",
  };

  useEffect(() => {
    if (otpSent) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [otpSent]);

  const formatTime = (time) => (time < 10 ? `0${time}` : time);

  return (
    <>
      <div className="pb-24 md:ml-[350px]">
        <div className="mt-12 flex items-center flex-col justify-center relative">
          <div className="relative">
            <img
              src={profilePicture || "/default.gif"}
              alt="Profile"
              className="w-24 h-24 rounded-full object-cover border-2 border-gray-300"
            />
            <label className="absolute bottom-0 right-0 bg-white p-2 rounded-full cursor-pointer">
              <FontAwesomeIcon icon={faCamera} />
              <input
                type="file"
                className="hidden"
                accept="image/*"
                onChange={handleFileChange}
              />
            </label>
          </div>
          {uploading && <p className="text-gray-500 mt-2">Uploading...</p>}
        </div>

        {/* NAME */}
        {loading ? (
          <div className="flex justify-center items-center h-12">
            <div className="loader border-t-4 border-btn rounded-full w-4 h-4 animate-spin"></div>
          </div>
        ) : (
          <div className="mt-8 flex items-center flex-col justify-center">
            <h2 className="font-bold text-lg">
              {user.last_name} {user.first_name}
            </h2>
            <small className="text-slate-500">{user.email}</small>
            <h2 className="font-bold text-md">{user.user_type}</h2>
          </div>
        )}

        {/* MANAGEMENT */}
        <div>
          <div className="p-8">
            <small className="text-slate-500">Management Options</small>
            <div className="bg-slate-100 border rounded-md border-dashed mt-3 p-4">
              <Link to="/profile/edit_profile">
                <div className="mb-3 flex items-center justify-between">
                  <div className="flex items-center gap-4">
                    <span className="bg-white rounded-full p-2">
                      <img src="/user_eye.svg" />
                    </span>
                    <small>Edit Profile</small>
                  </div>
                  <FontAwesomeIcon icon={faArrowRight} />
                </div>
              </Link>

              <div className="w-full h-[0.4px] flex justify-center bg-slate-400"></div>

              <div
                onClick={showOtpForm}
                className="mt-3 flex items-center justify-between"
              >
                <div className="flex items-center gap-4">
                  <span className="bg-white rounded-full p-2">
                    <img src="/user_locked.svg" />
                  </span>
                  <small>Password Reset</small>
                </div>
                <FontAwesomeIcon icon={faArrowRight} />
              </div>
            </div>
          </div>
        </div>

        {/* PREFERENCES */}
        <div>
          <div className="p-8 -mt-8">
            <small className="text-slate-500">Preferences</small>
            <div className="bg-slate-100 border rounded-md border-dashed mt-3 p-4">
              <div className="mb-3 flex items-center justify-between">
                <div className="flex items-center gap-4">
                  <span className="bg-white rounded-full p-2">
                    <img src="/email.svg" />
                  </span>
                  <small>Email Notifications</small>
                </div>
                <div className="toggle-switch">
                  <input
                    className="toggle-input"
                    checked
                    disabled
                    id="toggle"
                    type="checkbox"
                  />
                  <label className="toggle-label" for="toggle" />
                </div>
              </div>

              <div className="w-full h-[0.4px] flex justify-center bg-slate-400"></div>

              <div
                onClick={handleLogout}
                className="mt-3 flex items-center justify-between"
              >
                <div className="flex items-center gap-4">
                  <span className="bg-white rounded-full p-2">
                    <img src="/logout.svg" />
                  </span>
                  <small className="text-danger">
                    {signingOut ? "Signing Out..." : "Sign Out"}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>

        <small className="mt-12 flex justify-center text-primary cursor-pointer">
          &copy; Trakitt HQ, {currentYear}.
        </small>
      </div>
      {/* OTP Input Form */}
      <CSSTransition
        in={otpSent}
        timeout={300}
        classNames={myClassNames}
        unmountOnExit
      >
        <>
          <div
            className="fixed inset-0 overflow-scroll z-50 flex items-center justify-center bg-black/70 backdrop-blur-sm"
            onClick={() => setOtpSent(false)}
          >
            <div
              className="relative bg-white rounded-lg shadow-lg w-[90%] max-w-lg animate-fadeIn p-8"
              onClick={(e) => e.stopPropagation()}
            >
              <Formik
                initialValues={{ otp: "" }}
                validationSchema={otpValidationSchema}
                onSubmit={handleOtpSubmit}
              >
                {({ isSubmitting }) => (
                  <Form className="space-y-4">
                    <div>
                      <label
                        htmlFor="otp"
                        className="block text-md text-xl font-bold text-gray-700"
                      >
                        Enter OTP
                      </label>
                      <Field
                        type="number"
                        name="otp"
                        id="otp"
                        placeholder="Enter the OTP sent to your email"
                        className="mt-1 block w-full px-3 py-2 border rounded-md focus:ring-primary focus:border-primary sm:text-sm"
                      />
                      <ErrorMessage
                        name="otp"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="w-full py-2 px-4 bg-btn text-white font-semibold rounded-md transition duration-300"
                    >
                      {isSubmitting ? "Verifying..." : "Verify OTP"}
                    </button>

                    <div className="flex flex-col items-center mt-8 gap-4">
                      <small>Didn't receive an OTP?</small>
                      {countdownComplete && (
                        <button
                          className="cursor-pointer items-center hover:bg-transparent border transition ease-in duration-300 border-btn py-2 px-6 rounded-md"
                          onClick={handleResendOTP}
                        >
                          <FontAwesomeIcon icon={faPaperPlane} /> &nbsp; Resend
                          OTP
                        </button>
                      )}
                      {!countdownComplete && (
                        <p>
                          Resend OTP in {formatTime(Math.floor(seconds / 60))}:
                          {formatTime(seconds % 60)}
                        </p>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </>
      </CSSTransition>

      {/* Notifications */}
      <CSSTransition
        in={showNotification}
        classNames={styles}
        timeout={500}
        unmountOnExit
      >
        <div className="p-4 shadow-xl z-50 bg-white fixed right-3 top-10 border-s-2 border-success rounded-md flex items-center gap-4">
          <span className="flex items-center gap-4">
            <FontAwesomeIcon className="text-success" icon={faCheckCircle} />
            <p className="font-light">{showNotification}</p>
          </span>
        </div>
      </CSSTransition>

      <CSSTransition
        in={errorNotification}
        classNames={styles}
        timeout={500}
        unmountOnExit
      >
        <div className="p-4 shadow-xl z-50 bg-white fixed right-3 top-10 border-s-2 border-danger rounded-md flex items-center gap-4">
          <span className="flex items-center gap-4">
            <FontAwesomeIcon className="text-danger" icon={faTimesCircle} />
            <p className="font-light">{errorNotification}</p>
          </span>
        </div>
      </CSSTransition>
    </>
  );
};

export default Profile;
