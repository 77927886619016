import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faCheck,
  faCheckCircle,
  faEdit,
  faExclamationCircle,
  faPaperPlane,
  faPlusCircle,
  faTimes,
  faTimesCircle,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { CSSTransition } from "react-transition-group";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import PeriodContextSelector from "../Components/PeriodContextSelector";

const ClientFeedback = () => {
  const [feedback, setFeedbacks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const itemsPerPage = 10;
  const apiUrl = import.meta.env.VITE_API_URL;
  const token = JSON.parse(localStorage.getItem("user"))?.access || "";
  const totalPages = Math.ceil(feedback.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentFeedback = feedback.slice(startIndex, startIndex + itemsPerPage);

  useEffect(() => {
    const fetchFeedback = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/v1/tracker/feedbacks/all`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setFeedbacks(response.data.data);
      } catch (error) {
        console.error(error.response?.data || error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchFeedback();
  }, [apiUrl, token]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const closePopup = () => {
    setSelectedFeedback(null);
  };

  const myClassNames = {
    enter: "opacity-0",
    enterActive: "opacity-100 transition-opacity duration-500 ease-in-out",
    exitActive: "opacity-0 transition-opacity duration-500 ease-in-out",
  };

  const navigate = useNavigate();

  return (
    <>
      <div className="p-4 md:ml-[350px] bg-[#F8F9FC] mb-24">
        <div className="p-4 bg-white rounded-md shadow-md">
          <span className="flex items-center gap-3">
            <FontAwesomeIcon onClick={() => navigate(-1)} icon={faArrowLeft} />
            <h2 className="text-md font-bold">Client Feedback</h2>
          </span>
        </div>

        <PeriodContextSelector />

        <div className="overflow-x-auto bg-white mt-4">
          <table className="rounded-md shadow-md w-full">
            <thead className="shadow-md rounded-lg bg-white">
              <tr>
                <th className="px-6 py-3 text-left text-primary text-[14px] font-light whitespace-nowrap">
                  Feedback
                </th>
                <th className="px-6 py-3 text-left text-primary text-[14px] font-light whitespace-nowrap">
                  Manager
                </th>
                <th className="px-6 py-3 text-left text-primary text-[14px] font-light whitespace-nowrap">
                  Created At
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {loading ? (
                <tr>
                  <td colSpan="4" className="text-center py-4">
                    Loading resources...
                  </td>
                </tr>
              ) : feedback.length === 0 ? (
                <tr>
                  <td
                    colSpan="4"
                    className="text-center text-slate-400 text-sm py-4"
                  >
                    No client feedbacks available.
                  </td>
                </tr>
              ) : (
                currentFeedback.map((feedback) => (
                  <tr
                    key={feedback.id}
                    className="rounded-md shadow-sm cursor-pointer hover:bg-gray-100"
                    onClick={() => setSelectedFeedback(feedback)}
                  >
                    <td
                      className="px-6 py-4 text-[13px] font-semibold"
                      title={`${feedback.compliment || ""} ${
                        feedback.complaint || ""
                      }`}
                    >
                      <div className="flex justify-center items-center">
                        {feedback.compliment && feedback.complaint ? (
                          <div className="flex items-center whitespace-nowrap gap-1 bg-yellow-100 text-yellow-600 px-2 py-1 rounded-full">
                            <span>Complaint & Compliment</span>
                          </div>
                        ) : feedback.compliment ? (
                          <div className="flex items-center gap-1 bg-green-100 text-green-600 px-2 py-1 rounded-full">
                            <span>Compliment</span>
                          </div>
                        ) : (
                          <div className="flex items-center gap-1 bg-red-100 text-red-600 px-2 py-1 rounded-full">
                            <span>Complaint</span>
                          </div>
                        )}
                      </div>
                    </td>

                    <td className="px-6 py-4 text-[13px] font-semibold whitespace-nowrap">
                      {`${feedback.logged_by.last_name} ${feedback.logged_by.first_name}`}
                    </td>
                    <td className="px-6 py-4 text-[13px] font-semibold whitespace-nowrap">
                      {moment(feedback.created_at).format("MMMM Do, YYYY")}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        {feedback.length > itemsPerPage && (
          <div className="flex justify-center items-center mt-4">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="px-3 py-1 mx-1 text-sm font-semibold text-gray-700 bg-gray-200 rounded hover:bg-gray-300 disabled:opacity-50"
            >
              Previous
            </button>
            {[...Array(totalPages)].map((_, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                className={`px-3 py-1 mx-1 text-sm font-semibold ${
                  currentPage === index + 1
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                } rounded`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="px-3 py-1 mx-1 text-sm font-semibold text-gray-700 bg-gray-200 rounded hover:bg-gray-300 disabled:opacity-50"
            >
              Next
            </button>
          </div>
        )}
      </div>

      <CSSTransition
        in={selectedFeedback}
        timeout={300}
        classNames={myClassNames}
        unmountOnExit
      >
        <>
          {selectedFeedback && (
            <div
              className="fixed inset-0 overflow-scroll z-50 flex items-center justify-center bg-black/70 backdrop-blur-sm"
              onClick={closePopup}
            >
              <div
                onClick={(e) => e.stopPropagation()}
                className="bg-white w-96 p-6 rounded-md shadow-lg relative"
              >
                <button
                  onClick={closePopup}
                  className="flex justify-self-end mb-4"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>

                {selectedFeedback.compliment && selectedFeedback.complaint ? (
                  <div className="flex justify-center font-black text-xl">
                    <span className="text-yellow-600">
                      Complaint & Compliment{" "}
                      <FontAwesomeIcon icon={faExclamationCircle} />
                    </span>
                  </div>
                ) : selectedFeedback.compliment ? (
                  <div className="flex justify-center font-black text-xl">
                    <span className="text-success">
                      Compliment <FontAwesomeIcon icon={faCheckCircle} />
                    </span>
                  </div>
                ) : selectedFeedback.complaint ? (
                  <div className="flex justify-center font-black text-xl">
                    <span className="text-danger">
                      Complaint <FontAwesomeIcon icon={faTimesCircle} />
                    </span>
                  </div>
                ) : null}

                <div className="mt-6 space-y-4">
                  {selectedFeedback.compliment && (
                    <div className="flex justify-between items-start flex-wrap">
                      <span className="font-medium text-gray-600">
                        Compliment:
                      </span>
                      <span className="text-gray-800 break-words">
                        {selectedFeedback.compliment}
                      </span>
                    </div>
                  )}

                  {selectedFeedback.complaint && (
                    <div className="flex justify-between items-start flex-wrap">
                      <span className="font-medium text-gray-600">
                        Complaint:
                      </span>
                      <span className="text-gray-800 break-words">
                        {selectedFeedback.complaint}
                      </span>
                    </div>
                  )}

                  <div className="flex justify-between items-center">
                    <span className="font-medium text-gray-600">Client:</span>
                    <span className="text-gray-800">
                      {selectedFeedback.client_name}
                    </span>
                  </div>

                  <div className="flex justify-between items-center">
                    <span className="font-medium text-gray-600">Manager:</span>
                    <span className="text-gray-800">
                      {`${selectedFeedback.logged_by.last_name} ${selectedFeedback.logged_by.first_name}`}
                    </span>
                  </div>

                  {selectedFeedback.improvement_plan && (
                    <div className="flex justify-between items-center">
                      <span className="font-medium text-gray-600">
                        Improvement Plan:
                      </span>
                      <span className="text-gray-800">
                        {selectedFeedback.improvement_plan}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      </CSSTransition>
    </>
  );
};

export default ClientFeedback;
