import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faClipboardCheck,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import PeriodContextSelector from "../Components/PeriodContextSelector";
import moment from "moment";
import { CSSTransition } from "react-transition-group";
import KpiReportForm from "../Components/KpiReportForm";

const ManagerKpiDetails = () => {
  const id = JSON.parse(localStorage.getItem("user"))?.user_id;
  const [loading, setLoading] = useState(false);
  const [quickStats, setQuickStats] = useState({
    totalKpis: 0,
    completed: 0,
    undone: 0,
    completionRate: 0,
  });
  const navigate = useNavigate();
  const apiUrl = import.meta.env.VITE_API_URL;
  const token = JSON.parse(localStorage.getItem("user"))?.access;
  const user_id = JSON.parse(localStorage.getItem("user"))?.user_id;
  const [currentPage, setCurrentPage] = useState(1);
  const [managersData, setManagersData] = useState([]);
  const itemsPerPage = 10;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorNotification, setErrorNotification] = useState(false);
  const [selectedManager, setSelectedManager] = useState(null);
  const totalPages = Math.ceil(managersData.length / itemsPerPage);
  const paginatedData = managersData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getStatusClass = (status) => {
    switch (status) {
      case "SUBMITTED":
        return "bg-green-100 text-green-700";
      case "Undone":
        return "bg-red-100 text-red-700";
      case "PENDING":
        return "bg-yellow-100 text-yellow-700";
      default:
        return "bg-gray-100 text-gray-700";
    }
  };

  const openModal = (manager) => {
    setSelectedManager(manager);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedManager(null);
    setIsModalOpen(false);
  };

  useEffect(() => {
    const fetchQuickStats = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/v1/tracker/kpis/reports/all?reported_by=${user_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const kpiData = response.data.data;

        const completedTasks = kpiData.filter(
          (kpi) => kpi.percentage_completed === 100
        ).length;

        const undoneTasks = kpiData.filter(
          (kpi) => kpi.actual_completed === 0
        ).length;

        const totalExpected = kpiData.reduce(
          (sum, kpi) => sum + (kpi.total_expected || 0),
          0
        );

        const totalCompleted = kpiData.reduce(
          (sum, kpi) => sum + (kpi.actual_completed || 0),
          0
        );

        const completionRate = kpiData.map((kpi) =>
          kpi.percentage_completed.toFixed(2)
        );

        setQuickStats({
          totalKpis: kpiData.length,
          completed: completedTasks,
          undone: undoneTasks,
          completionRate,
        });
      } catch (error) {
        console.error("Error fetching quick stats:", error);
        setErrorNotification(
          "There was an error while fetching the statistics."
        );
        setTimeout(() => {
          setErrorNotification(false);
        }, 3000);
      }
    };

    fetchQuickStats();
  }, [apiUrl, token, id]);

  useEffect(() => {
    const fetchKpis = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${apiUrl}/api/v1/tracker/kpis/reports/all?reported_by=${user_id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        const kpiData = response.data.data[0];

        setManagersData([
          {
            id: kpiData.id,
            name: kpiData.kpi?.name || "Unnamed KPI",
            due_date: kpiData.due_date,
            targets:
              kpiData.target_reports?.map((report) => ({
                id: report.id,
                name: report.name || `Target ${report.id}`,
                description: report.description || "No description available",
                total_expected: report.total_expected || 0,
                actual_completed: report.actual_completed || 0,
              })) || [],
            description: kpiData.kpi?.description || "No description available",
            status: kpiData.status || "No status available",
            start_date: kpiData.start_date,
            created_at: kpiData.created_at,
          },
        ]);
      } catch (error) {
        console.error("Error fetching KPIs:", error);
        setErrorNotification("There was an error while fetching the KPIs.");
        setTimeout(() => {
          setErrorNotification(false);
        }, 3000);
      } finally {
        setLoading(false);
      }
    };
    fetchKpis();
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isModalOpen]);

  const styles = {
    enter: "transform translate-x-full opacity-0",
    enterActive:
      "transform -translate-x-0 opacity-100 transition-all duration-500 ease-in-out",
    exitActive:
      "transform translate-x-full opacity-0 transition-all duration-500 ease-in-out",
  };

  const myClassNames = {
    enter: "opacity-0",
    enterActive: "opacity-100 transition-opacity duration-500 ease-in-out",
    exitActive: "opacity-0 transition-opacity duration-500 ease-in-out",
  };

  return (
    <>
      <div className="p-4 md:ml-[350px] bg-[#F8F9FC]">
        <div className="p-4 bg-white rounded-md shadow-md">
          <span className="flex items-center gap-3">
            <FontAwesomeIcon onClick={() => navigate(-1)} icon={faArrowLeft} />
            <h2 className="text-md font-bold">KPI Overview</h2>
          </span>
        </div>

        <PeriodContextSelector />

        <div className="grid grid-cols-2 gap-4 mt-[24px]">
          <div className="flex bg-gray-100 p-2 border-dashed border rounded-md flex-col items-center">
            <span className="text-gray-500 text-xs">Total KPIs</span>
            <span className="text-gray-900 text-2xl font-bold">
              {quickStats.totalKpis}
            </span>
          </div>

          <div className="flex bg-gray-100 p-2 border-dashed border rounded-md flex-col items-center">
            <span className="text-gray-500 text-xs">Submitted</span>
            <span className="text-gray-900 text-2xl font-bold">
              {quickStats.completed}
            </span>
          </div>

          <div className="flex bg-gray-100 p-2 border-dashed border rounded-md flex-col items-center">
            <span className="text-gray-500 text-xs">Undone</span>
            <span className="text-gray-900 text-2xl font-bold">
              {quickStats.undone}
            </span>
          </div>

          <div className="flex bg-gray-100 p-2 border-dashed border rounded-md flex-col items-center">
            <span className="text-gray-500 text-xs text-center">
              Completion Rate
            </span>
            <span className="text-gray-900 text-2xl font-bold">
              {quickStats.completionRate}%
            </span>
          </div>
        </div>

        <div className="mb-24 mt-8 pb-4 rounded-md bg-white">
          <div className="overflow-x-auto bg-white">
            <div className="grid grid-cols-1 gap-4">
              <div className="font-bold bg-white shadow-md rounded-lg p-4">
                KPIs
              </div>
              {loading ? (
                <div className="col-span-full text-center text-[13px] text-gray-500">
                  Loading KPIs...
                </div>
              ) : paginatedData.length > 0 ? (
                paginatedData.map((manager) => (
                  <>
                    <div
                      key={manager.id}
                      onClick={() => setSelectedManager(manager)}
                      className="p-4 border rounded-md shadow-md hover:shadow-lg cursor-pointer transition-shadow"
                    >
                      <div className="flex justify-between items-center">
                        <h4 className="text-lg font-semibold text-gray-700 mb-2">
                          {manager.name}
                        </h4>
                        <span
                          className={`inline-block mt-2 px-3 py-1 rounded-full text-xs font-medium whitespace-nowrap ${getStatusClass(
                            manager.status
                          )}`}
                        >
                          {manager.status}
                        </span>
                      </div>
                      {manager.targets && manager.targets.length > 0 && (
                        <div className="mb-4">
                          <h4 className="text-[13px] text-gray-600 mb-2">
                            Targets
                          </h4>
                          <div className="space-y-4">
                            {manager.targets.map((target, index) => (
                              <div
                                key={target.id || index}
                                className="border rounded-lg p-4 shadow-sm bg-gray-50 hover:bg-gray-100 transition duration-200"
                              >
                                <h5 className="text-md font-bold text-blue-600 mb-2">
                                  {target.name}
                                </h5>
                                <p className="text-sm text-gray-700">
                                  {target.description}
                                </p>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}

                      <span className="flex justify-between items-center">
                        <p className="text-[13px] text-gray-600">
                          <strong>Start Date:</strong> {manager.start_date}
                        </p>

                        <p className="text-[13px] text-gray-600">
                          <strong>Due Date:</strong> {manager.due_date}
                        </p>
                      </span>
                    </div>
                  </>
                ))
              ) : (
                <div className="col-span-full text-center text-gray-500">
                  No KPIs for this period.
                </div>
              )}
            </div>
          </div>

          {managersData.length > itemsPerPage && (
            <div className="flex justify-center items-center mt-4">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="px-3 py-1 mx-1 text-sm font-semibold text-gray-700 bg-gray-200 rounded hover:bg-gray-300 disabled:opacity-50"
              >
                Previous
              </button>
              {[...Array(totalPages)].map((_, index) => (
                <button
                  key={index}
                  onClick={() => handlePageChange(index + 1)}
                  className={`px-3 py-1 mx-1 text-sm font-semibold ${
                    currentPage === index + 1
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                  } rounded`}
                >
                  {index + 1}
                </button>
              ))}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="px-3 py-1 mx-1 text-sm font-semibold text-gray-700 bg-gray-200 rounded hover:bg-gray-300 disabled:opacity-50"
              >
                Next
              </button>
            </div>
          )}
        </div>

        {/* SELECTED MANAGER POPUP */}
        <CSSTransition
          in={selectedManager}
          timeout={300}
          classNames={myClassNames}
          unmountOnExit
        >
          <>
            {selectedManager && (
              <div
                className="fixed inset-0 overflow-scroll z-50 flex items-center justify-center bg-black/70 backdrop-blur-sm"
                onClick={closeModal}
              >
                <div
                  className="relative bg-white rounded-lg shadow-lg w-[90%] max-w-lg animate-fadeIn p-8"
                  onClick={(e) => e.stopPropagation()}
                >
                  <button
                    onClick={closeModal}
                    className="flex justify-self-end mb-4"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                  <div className="text-center">
                    <h3 className="text-3xl font-bold text-gray-800 mb-4">
                      {selectedManager.name}
                    </h3>
                    <span
                      className={`inline-block px-4 py-1 text-sm font-semibold rounded-full ${
                        selectedManager.status === "SUBMITTED"
                          ? "bg-green-100 text-green-700"
                          : selectedManager.status === "PENDING"
                          ? "bg-yellow-100 text-yellow-700"
                          : "bg-red-100 text-red-700"
                      }`}
                    >
                      {selectedManager.status || "No Status"}
                    </span>
                  </div>

                  <div className="mt-6 space-y-4">
                    <div className="flex justify-between items-center">
                      <span className="font-medium text-gray-600">
                        Description:
                      </span>
                      <span className="text-gray-800">
                        {selectedManager.description}
                      </span>
                    </div>

                    {selectedManager.targets &&
                      selectedManager.targets.length > 0 && (
                        <div className="mb-4">
                          <h4 className="font-medium mb-2 text-gray-600">
                            Targets
                          </h4>
                          <div className="space-y-4">
                            {selectedManager.targets.map((target, index) => (
                              <div
                                key={target.id || index}
                                className="border rounded-lg p-4 shadow-sm bg-gray-50 hover:bg-gray-100 transition duration-200"
                              >
                                <h5 className="text-md font-bold text-blue-600 mb-2">
                                  {target.name}
                                </h5>
                                <p className="text-sm text-gray-700">
                                  {target.description}
                                </p>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                  </div>

                  <button
                    onClick={() =>
                      navigate(`/report_kpi/${selectedManager.id}`, {
                        state: { manager: selectedManager },
                      })
                    }
                    disabled={selectedManager.percentageCompleted === 100}
                    className="bg-btn hover:bg-orange-700 disabled:hover:bg-btn disabled:opacity-50 px-4 py-2 mt-6 rounded-full text-white flex items-center justify-center gap-2 w-full"
                  >
                    <FontAwesomeIcon icon={faClipboardCheck} /> Report KPI
                  </button>
                </div>
              </div>
            )}
          </>
        </CSSTransition>
      </div>

      <CSSTransition
        in={errorNotification}
        classNames={styles}
        timeout={500}
        unmountOnExit
      >
        <div className="p-4 shadow-xl z-50 bg-white fixed right-3 top-10 border-s-2 border-danger rounded-md flex items-center gap-4">
          <span className="flex items-center gap-4">
            <FontAwesomeIcon className="text-danger" icon={faTimes} />
            <p className="font-light">{errorNotification}</p>
          </span>
        </div>
      </CSSTransition>
    </>
  );
};

export default ManagerKpiDetails;
