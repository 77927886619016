import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import CreatableSelect from "react-select/creatable";

const UpdateCarePackage = ({ update, setUpdate, selectedPackage }) => {
  const apiUrl = import.meta.env.VITE_API_URL;
  const token = JSON.parse(localStorage.getItem("user"))?.access;
  const recordedBy = JSON.parse(localStorage.getItem("user"))?.user_id;

  const [mode, setMode] = useState("update");
  const [loading, setLoading] = useState(false);

  const formatDateForInput = (date) => {
    if (!date) return "";
    const parsedDate = new Date(date);
    if (isNaN(parsedDate)) return "";
    const pad = (n) => n.toString().padStart(2, "0");
    return `${parsedDate.getFullYear()}-${pad(parsedDate.getMonth() + 1)}-${pad(
      parsedDate.getDate()
    )}`;
  };

  const endOptions = [
    { value: "Death", label: "Death" },
    { value: "Cancellation", label: "Cancellation" },
    { value: "Private", label: "Private" },
  ];

  const validationSchema = Yup.object({
    client: Yup.string().required("Client is required"),
    start_date: Yup.date().required("Start Date is required"),
    hours_of_care_received: Yup.number()
      .nullable()
      .typeError("Hours of care must be a number"),
    ...(mode === "end" && {
      end_date: Yup.date().required("End Date is required"),
      ended_reason: Yup.string()
        .required("Ended Reason is required")
        .min(1, "Reason must not be empty"),
    }),
  });

  const formik = useFormik({
    initialValues: {
      client: selectedPackage.client.fullname || "",
      start_date: formatDateForInput(selectedPackage?.start_date) || "",
      end_date: formatDateForInput(selectedPackage?.end_date) || "",
      ended_reason: selectedPackage?.ended_reason || "",
      hours_of_care_received: selectedPackage?.hours_of_care_received || "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const commonFields = {
        client: values.client,
        start_date: formatDateForInput(values.start_date),
        hours_of_care_received: values.hours_of_care_received || "",
        recorded_by: recordedBy,
      };

      const endFields =
        mode === "end"
          ? {
              end_date: formatDateForInput(values.end_date),
              ended_reason: values.ended_reason,
              status: "ended",
            }
          : {};

      const payload = { ...commonFields, ...endFields };

      try {
        setLoading(true);
        const endpoint = `${apiUrl}/api/v1/tracker/care-plans/${selectedPackage.id}`;
        await axios.patch(endpoint, payload, {
          headers: { Authorization: `Bearer ${token}` },
          withCredentials: true,
        });

        window.location.reload();
        setUpdate(false);
      } catch (error) {
        console.error(
          "Error processing request:",
          error.response?.data || error.message
        );
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <div
      className="fixed inset-0 z-50 overflow-scroll pt-24 flex items-center justify-center bg-black/70 backdrop-blur-sm"
      onClick={() => setUpdate(false)}
    >
      <div
        className="relative bg-white rounded-lg shadow-lg w-[90%] max-w-lg animate-fadeIn p-8"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Close Button */}
        <button className="absolute top-4 right-4 text-gray-500 hover:text-gray-700">
          <FontAwesomeIcon
            onClick={() => setUpdate(false)}
            icon={faTimes}
            className="h-6 w-6"
          />
        </button>

        {/* Title */}
        <h3 className="text-3xl font-bold text-gray-800 mb-6 text-center">
          {mode === "update" ? "Update Care Package" : "End Care Package"}
        </h3>

        {/* Mode Toggle */}
        <div className="flex justify-around gap-3 mb-6">
          <button
            type="button"
            onClick={() => setMode("update")}
            className={`px-3 py-2 rounded ${
              mode === "update"
                ? "bg-btn text-white font-bold whitespace-nowrap"
                : "bg-gray-200 text-gray-800 whitespace-nowrap"
            }`}
          >
            Update Package
          </button>
          <button
            type="button"
            onClick={() => setMode("end")}
            className={`px-3 py-2 rounded ${
              mode === "end"
                ? "bg-danger text-white font-bold whitespace-nowrap"
                : "bg-gray-200 text-gray-800 whitespace-nowrap"
            }`}
          >
            End Package
          </button>
        </div>

        {/* Form */}
        <form onSubmit={formik.handleSubmit} className="space-y-6">
          {/* Client */}
          <div>
            <label
              htmlFor="client"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Client
            </label>
            <input
              type="text"
              id="client"
              name="client"
              value={formik.values.client}
              disabled
              className="w-full border border-gray-300 rounded-lg px-4 py-2 bg-gray-100"
            />
          </div>

          {/* Start Date */}
          <div>
            <label
              htmlFor="start_date"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Start Date
            </label>
            <input
              type="date"
              id="start_date"
              name="start_date"
              className={`w-full border border-gray-300 rounded-lg px-4 py-2 ${
                mode === "end" ? "bg-gray-100 cursor-not-allowed" : ""
              }`}
              value={formik.values.start_date}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={mode === "end"}
            />
          </div>

          {/* Hours of Care */}
          <div>
            <label
              htmlFor="hours_of_care_received"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Hours/Week
            </label>
            <input
              type="number"
              id="hours_of_care_received"
              name="hours_of_care_received"
              className={`w-full border border-gray-300 rounded-lg px-4 py-2 ${
                mode === "end" ? "bg-gray-100 cursor-not-allowed" : ""
              }`}
              value={formik.values.hours_of_care_received}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={mode === "end"}
            />
          </div>

          {/* Conditional Fields */}
          {mode === "end" && (
            <>
              <div>
                <label
                  htmlFor="end_date"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  End Date
                </label>
                <input
                  type="date"
                  id="end_date"
                  name="end_date"
                  className={`w-full border ${
                    formik.touched.end_date && formik.errors.end_date
                      ? "border-red-500"
                      : "border-gray-300"
                  } rounded-lg px-4 py-2`}
                  value={formik.values.end_date}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.end_date && formik.errors.end_date && (
                  <p className="text-sm text-red-500 mt-1">
                    {formik.errors.end_date}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="ended_reason"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Ended Reason
                </label>
                <CreatableSelect
                  id="ended_reason"
                  name="ended_reason"
                  options={endOptions}
                  onChange={(selectedOption) =>
                    formik.setFieldValue(
                      "ended_reason",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                  value={
                    formik.values.ended_reason
                      ? {
                          value: formik.values.ended_reason,
                          label: formik.values.ended_reason,
                        }
                      : null
                  }
                  onBlur={() => formik.setFieldTouched("ended_reason", true)}
                  isClearable
                  placeholder="Select or type a reason"
                />
                {formik.touched.ended_reason && formik.errors.ended_reason && (
                  <p className="text-sm text-red-500 mt-1">
                    {formik.errors.ended_reason}
                  </p>
                )}
              </div>
            </>
          )}

          {/* Submit Button */}
          <button
            type="submit"
            className={`w-full py-3 rounded-lg font-medium transition ${
              mode === "update"
                ? "bg-orange-500 hover:bg-orange-600 text-white"
                : "bg-danger hover:bg-red-700 text-white"
            }`}
          >
            {loading
              ? mode === "update"
                ? "Updating..."
                : "Ending..."
              : mode === "update"
              ? "Update Care Package"
              : "End Care Package"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default UpdateCarePackage;
