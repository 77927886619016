import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { TemplateProvider } from '@/Contexts/TemplateContext';
import { TemplateSearch } from './TemplateSearch';
import { TemplateList } from './TemplateList';
import { NewTemplateButton } from './NewTemplateButton';

const TaskTemplateLibrary = () => {
  return (
    <Card className="w-full">
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle>Task Templates</CardTitle>
        <NewTemplateButton />
      </CardHeader>
      <CardContent>
        <div className="mb-4">
          <TemplateSearch />
        </div>
        <TemplateList />
      </CardContent>
    </Card>
  );
};

export default TaskTemplateLibrary;