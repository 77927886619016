import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faPaperPlane,
  faTimes,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ResourceRequestForm from "../Components/ResourceRequestForm";
import ResourceUpdateForm from "../Components/ResourceUpdateForm";
import moment from "moment";
import { CSSTransition } from "react-transition-group";
import PeriodContextSelector from "../Components/PeriodContextSelector";

const ManagerResourceRequest = () => {
  const [resources, setResources] = useState([]);
  const [requestForm, setRequestForm] = useState(false);
  const [updateForm, setUpdateForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [del, setDel] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedResource, setSelectedResource] = useState(null);
  const itemsPerPage = 10;
  const apiUrl = import.meta.env.VITE_API_URL;
  const token = JSON.parse(localStorage.getItem("user"))?.access || "";
  const id = JSON.parse(localStorage.getItem("user"))?.user_id || "";
  const totalPages = Math.ceil(resources.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentResources = resources.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  const myClassNames = {
    enter: "opacity-0",
    enterActive: "opacity-100 transition-opacity duration-500 ease-in-out",
    exitActive: "opacity-0 transition-opacity duration-500 ease-in-out",
  };

  useEffect(() => {
    const fetchResources = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/v1/tracker/resource-requests/all?manager=${id}&requested_by=${id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setResources(response.data.data);
      } catch (error) {
        console.error(error.response?.data || error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchResources();
  }, [apiUrl, token]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const closePopup = () => {
    setSelectedResource(null);
  };

  const handleDelete = async (taskId) => {
    setDel(true);
    try {
      const response = await axios.delete(
        `${apiUrl}/api/v1/tracker/resource-requests/${taskId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }
      );

      if (response.status === 204) {
        setDel(false);
        window.location.reload();
      }
    } catch (err) {
      setDel(false);
      console.error("Delete Error:", err);
    }
  };

  useEffect(() => {
    if (selectedResource || updateForm || requestForm) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [selectedResource, updateForm, requestForm]);

  return (
    <>
      <div className="p-4 md:ml-[350px] bg-[#F8F9FC] mb-24">
        <div className="p-4 bg-white rounded-md shadow-md">
          <span className="flex items-center justify-between gap-3">
            <h2 className="text-ms font-bold">Resource Requests</h2>
            <button
              onClick={() => setRequestForm(true)}
              className="bg-btn px-4 py-2 text-white rounded-md"
            >
              Send Request &nbsp;
              <FontAwesomeIcon icon={faPaperPlane} />
            </button>
          </span>
        </div>

        <PeriodContextSelector />

        <div className="overflow-x-auto bg-white mt-4">
          <table className="rounded-md shadow-md w-full">
            <thead className="shadow-md rounded-lg bg-white">
              <tr>
                <th className="px-6 py-3 text-left text-primary text-[14px] font-light whitespace-nowrap">
                  Item Requested
                </th>
                <th className="px-6 py-3 text-left text-primary text-[14px] font-light whitespace-nowrap">
                  Date Needed
                </th>
                <th className="px-6 py-3 text-left text-primary text-[14px] font-light whitespace-nowrap">
                  Quantity
                </th>
                <th className="px-6 py-3 text-left text-primary text-[14px] font-light whitespace-nowrap">
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {loading ? (
                <tr>
                  <td colSpan="4" className="text-center py-4">
                    Loading resources...
                  </td>
                </tr>
              ) : resources.length === 0 ? (
                <tr>
                  <td
                    colSpan="4"
                    className="text-center text-slate-400 text-sm py-4"
                  >
                    No resource requests available.
                  </td>
                </tr>
              ) : (
                currentResources.map((resource) => (
                  <tr
                    key={resource.id}
                    className="rounded-md shadow-sm cursor-pointer hover:bg-gray-100"
                    onClick={() => setSelectedResource(resource)}
                  >
                    <td className="px-6 py-4 text-[13px] font-semibold whitespace-nowrap">
                      {resource.name}
                    </td>
                    <td className="px-6 py-4 text-[13px] font-semibold whitespace-nowrap">
                      {moment(resource.due_date).format("MMMM Do YYYY")}
                    </td>
                    <td className="px-6 py-4 text-[13px] font-semibold whitespace-nowrap">
                      {resource.qty}
                    </td>
                    <td
                      className={`inline-block mt-3 px-3 py-1 text-[13px] whitespace-nowrap font-semibold rounded-full ${
                        resource.status === "accepted"
                          ? "bg-green-100 text-green-700"
                          : resource.status === "rejected"
                          ? "bg-red-100 text-red-700"
                          : "bg-yellow-100 text-yellow-700"
                      }`}
                    >
                      {resource.status}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        {resources.length > itemsPerPage && (
          <div className="flex justify-center items-center mt-4">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="px-3 py-1 mx-1 text-sm font-semibold text-gray-700 bg-gray-200 rounded hover:bg-gray-300 disabled:opacity-50"
            >
              Previous
            </button>
            {[...Array(totalPages)].map((_, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                className={`px-3 py-1 mx-1 text-sm font-semibold ${
                  currentPage === index + 1
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                } rounded`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="px-3 py-1 mx-1 text-sm font-semibold text-gray-700 bg-gray-200 rounded hover:bg-gray-300 disabled:opacity-50"
            >
              Next
            </button>
          </div>
        )}
      </div>

      <CSSTransition
        in={selectedResource}
        timeout={300}
        classNames={myClassNames}
        unmountOnExit
      >
        <>
          {selectedResource && (
            <div
              className="fixed inset-0 z-50 overflow-scroll flex items-center justify-center bg-black/70 backdrop-blur-sm"
              onClick={closePopup}
            >
              <div
                className="relative bg-white rounded-lg shadow-lg w-[90%] max-w-lg animate-fadeIn p-8"
                onClick={(e) => e.stopPropagation()}
              >
                <button
                  onClick={closePopup}
                  className="flex justify-self-end mb-4"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
                <div className="text-center">
                  <h3 className="text-3xl font-bold text-gray-800 mb-4">
                    {selectedResource.name}
                  </h3>
                  <span
                    className={`inline-block mt-3 px-3 py-1 text-[13px] whitespace-nowrap font-semibold rounded-full ${
                      selectedResource.status === "accepted"
                        ? "bg-green-100 text-green-700"
                        : selectedResource.status === "rejected"
                        ? "bg-red-100 text-red-700"
                        : "bg-yellow-100 text-yellow-700"
                    }`}
                  >
                    {selectedResource.status}
                  </span>
                </div>

                <div className="mt-6 space-y-4">
                  <div className="flex justify-between items-center">
                    <span className="font-medium text-gray-600">
                      Date Needed:
                    </span>
                    <span className="text-gray-800">
                      {moment(selectedResource.due_date).format("MMMM Do YYYY")}
                    </span>
                  </div>

                  <div className="flex justify-between items-center">
                    <span className="font-medium text-gray-600">Quantity:</span>
                    <span className="text-gray-800">
                      {selectedResource.qty}
                    </span>
                  </div>

                  <div className="flex justify-between items-center">
                    <span className="font-medium text-gray-600">Priority:</span>
                    <span className="text-gray-800">
                      {selectedResource.priority}
                    </span>
                  </div>
                  {selectedResource.manager_notes && (
                    <div className="flex justify-between items-center">
                      <span className="font-medium text-gray-600">
                        Manager Notes:
                      </span>
                      <span className="text-gray-800">
                        {selectedResource.manager_notes}
                      </span>
                    </div>
                  )}

                  {selectedResource.admin_notes && (
                    <div className="flex justify-between items-center">
                      <span className="font-medium text-gray-600">
                        Admin Notes:
                      </span>
                      <span className="text-gray-800">
                        {selectedResource.admin_notes}
                      </span>
                    </div>
                  )}

                  <div className="flex gap-4">
                    <button
                      onClick={() => setUpdateForm(true)}
                      className="bg-btn w-full text-white disabled:opacity-50 px-4 py-2 rounded-full"
                      disabled={
                        selectedResource.status === "accepted" ||
                        selectedResource.status === "rejected"
                      }
                    >
                      <FontAwesomeIcon icon={faEdit} />
                      &nbsp; Update
                    </button>
                    <button
                      onClick={() => handleDelete(selectedResource.id)}
                      className="bg-danger w-full text-white px-4 py-2 rounded-full"
                    >
                      <FontAwesomeIcon icon={faTrashCan} />
                      &nbsp; {del ? "Deleting..." : "Delete"}
                    </button>
                  </div>

                  <CSSTransition
                    in={updateForm}
                    timeout={300}
                    classNames={myClassNames}
                    unmountOnExit
                  >
                    <>
                      <ResourceUpdateForm
                        setUpdateForm={setUpdateForm}
                        updateForm={updateForm}
                        selectedResource={selectedResource}
                      />
                    </>
                  </CSSTransition>
                </div>
              </div>
            </div>
          )}
        </>
      </CSSTransition>

      <CSSTransition
        in={requestForm}
        timeout={300}
        classNames={myClassNames}
        unmountOnExit
      >
        <>
          <ResourceRequestForm
            setRequestForm={setRequestForm}
            requestForm={requestForm}
          />
        </>
      </CSSTransition>
    </>
  );
};

export default ManagerResourceRequest;
