import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../lib/auth-utils";

const AuthContext = createContext();

export const useAuthenticateContext = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const logoutTimerRef = useRef(null);
  const navigate = useNavigate();

  const login = (userData) => {
    const {
      refresh,
      access,
      access_expiry,
      user_id,
      user_type,
      using_default_password,
    } = userData;

    const expiryTime = Date.now() + access_expiry * 1000;
    localStorage.setItem(
      "user",
      JSON.stringify({
        access,
        refresh,
        user_id,
        user_type,
        using_default_password,
      })
    );
    localStorage.setItem("expiryTime", expiryTime);

    setUser({ access, refresh, user_id, user_type, using_default_password });
    setLogoutTimer(access_expiry);
  };

  const logout = (reason = "You have been logged out.") => {
    logoutUser().then(() => {
      localStorage.setItem("logoutReason", reason);
      setUser(null);
      if (logoutTimerRef.current) {
        clearTimeout(logoutTimerRef.current);
      }
      navigate("/login");
    });
  };

  const setLogoutTimer = (expiryInSeconds) => {
    const timeout = expiryInSeconds * 1000;
    if (logoutTimerRef.current) {
      clearTimeout(logoutTimerRef.current);
    }
    logoutTimerRef.current = setTimeout(() => {
      logout("Your session has expired. Please log in again.");
    }, timeout);
  };

  const isAuthenticated = () => {
    return !!user;
  };

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    const expiryTime = localStorage.getItem("expiryTime");

    if (storedUser && expiryTime) {
      const remainingTime = expiryTime - Date.now();

      if (remainingTime > 0) {
        const parsedUser = JSON.parse(storedUser);
        setUser(parsedUser);
        setLogoutTimer(remainingTime / 1000);
      } else {
        logout("Your session has expired. Please log in again.");
      }
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, login, logout, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};
