import { Plus } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Dialog, DialogTrigger } from '@/components/ui/dialog';
import { useState } from 'react';
import { NewTemplateDialog } from './NewTemplateDialog';

export const NewTemplateButton = () => {
    const [showDialog, setShowDialog] = useState(false);

    return (
        <Dialog open={showDialog} onOpenChange={setShowDialog}>
            <DialogTrigger asChild>
                <Button size="sm" className="bg-btn">
                    <Plus className="h-4 w-4" />
                    <span className="sr-only">Add Template</span>
                </Button>
            </DialogTrigger>
            <NewTemplateDialog
                isOpen={showDialog}
                onClose={() => setShowDialog(false)}
            />
        </Dialog>
    );
};
