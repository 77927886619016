import {
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Checkbox } from '@/components/ui/checkbox';
import { useState } from 'react';
import { useTemplate } from '@/Contexts/TemplateContext';

type FormData = {
  name: string;
  description: string;
  is_inverted: boolean;
};

const initialFormData: FormData = {
  name: '',
  description: '',
  is_inverted: false,
};

export const NewTemplateDialog = ({
  isOpen,
  onClose,
  template
}: {
  isOpen: boolean;
  onClose: () => void;
  template?: {
    id: string;
    name: string;
    description: string;
    is_inverted: boolean;
  };
}) => {
  const { createTemplate, updateTemplate } = useTemplate();
  const [formData, setFormData] = useState<FormData>(() =>
    template ? {
      name: template.name,
      description: template.description,
      is_inverted: template.is_inverted,
    } : initialFormData
  );

  const handleSubmit = async () => {
    if (template) {
      await updateTemplate(template.id, formData);
    } else {
      await createTemplate(formData);
    }
    setFormData(initialFormData);
    onClose();
  };

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>
          {template ? 'Edit Template' : 'Add New Template'}
        </DialogTitle>
      </DialogHeader>
      <div className="space-y-4 py-4">
        <div>
          <label className="text-sm font-medium mb-1 block">
            Template Name
          </label>
          <Input
            placeholder="Enter task name"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          />
        </div>
        <div>
          <label className="text-sm font-medium mb-1 block">
            Description
          </label>
          <Textarea
            placeholder="Enter task description"
            value={formData.description}
            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
          />
        </div>
        <div>
          <label className="text-sm font-medium mb-1 block">
            Inverted
          </label>
          <Checkbox
            checked={formData.is_inverted}
            onCheckedChange={(checked) => setFormData({ ...formData, is_inverted: !!checked })}
          >
            Invert the task (lower is better)
          </Checkbox>
        </div>
      </div>
      <DialogFooter>
        <Button variant="outline" onClick={() => {
          setFormData(initialFormData);
          onClose();
        }}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} className="bg-btn">
          {template ? 'Update' : 'Create'}
        </Button>
      </DialogFooter>
    </DialogContent>
  );
};