import { useState, useEffect } from "react";

interface Coordinates {
  latitude: number;
  longitude: number;
  accuracy: number;
}

interface LocationHook {
  coordinates: Coordinates | null;
  error: string | null;
  loading: boolean;
}

const useGPSLocation = (requiredAccuracy: number = 20): LocationHook => {
  const [coordinates, setCoordinates] = useState<Coordinates | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    let watchId: number;

    const handleSuccess = (position: GeolocationPosition) => {
      const { latitude, longitude, accuracy } = position.coords;

      if (accuracy <= requiredAccuracy) {
        setCoordinates({ latitude, longitude, accuracy });
        setError(null);
        setLoading(false);
      }
    };

    const handleError = (error: GeolocationPositionError) => {
      let errorMessage: string;

      switch (error.code) {
        case error.PERMISSION_DENIED:
          errorMessage = "Please enable location services to continue.";
          break;
        case error.POSITION_UNAVAILABLE:
          errorMessage = "Location information is currently unavailable.";
          break;
        case error.TIMEOUT:
          errorMessage = "Location request timed out.";
          break;
        default:
          errorMessage = "An unknown error occurred while getting location.";
      }

      setError(errorMessage);
      setLoading(false);
    };

    if ("geolocation" in navigator) {
      const options = {
        enableHighAccuracy: true,
        timeout: 100000,
        maximumAge: 0,
      };

      watchId = navigator.geolocation.watchPosition(
        handleSuccess,
        handleError,
        options
      );
    } else {
      setError("Geolocation is not supported by your browser.");
      setLoading(false);
    }

    return () => {
      if (watchId) {
        navigator.geolocation.clearWatch(watchId);
      }
    };
  }, [requiredAccuracy]);

  return { coordinates, error, loading };
};

export default useGPSLocation;
