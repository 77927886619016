import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import axios from "axios";
ChartJS.register(ArcElement, Tooltip, Legend);

const FeedbackSentiment = () => {
  const [feedback, setFeedbacks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState({
    labels: ["Complaints", "Compliments", "Both"],
    datasets: [
      {
        label: "Feedback Sentiment",
        data: [0, 0],
        backgroundColor: ["#F44336", "#4CAF50", "#FFC107"],
        borderColor: ["#F44336", "#4CAF50", "#FFC107"],
        borderWidth: 2,
      },
    ],
  });
  const apiUrl = import.meta.env.VITE_API_URL;
  const token = JSON.parse(localStorage.getItem("user"))?.access || "";

  useEffect(() => {
    const fetchFeedback = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/v1/tracker/feedbacks/all`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        const feedbacks = response.data.data;
        setFeedbacks(feedbacks);

        const complaintCount = feedbacks.filter((fb) => fb.complaint).length;
        const complimentCount = feedbacks.filter((fb) => fb.compliment).length;
        const bothCount = feedbacks.filter(
          (fb) => fb.complaint && fb.compliment
        ).length;

        setChartData((prevData) => ({
          ...prevData,
          datasets: [
            {
              ...prevData.datasets[0],
              data: [complaintCount, complimentCount, bothCount],
            },
          ],
        }));
      } catch (error) {
        console.error(error.response?.data || error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchFeedback();
  }, [apiUrl, token]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          padding: 30,
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.label || "";
            const value = context.raw || 0;
            return `${label}: ${value}`;
          },
        },
      },
    },
  };

  const hasData = chartData.datasets[0].data.some((value) => value > 0);

  return (
    <div className="p-4">
      <div className="p-6 bg-white rounded-lg flex flex-col items-center shadow-md">
        <h2 className="text-lg font-semibold mb-4 self-start text-gray-800">
          Feedback Sentiment
        </h2>
        <div className="w-[300px] h-[300px]">
          {hasData ? (
            <Pie data={chartData} options={options} />
          ) : (
            <p className="text-gray-500">No data available</p>
          )}
        </div>

        <div className="mt-6 flex gap-4">
          <p className="text-sm font-medium text-gray-800">
            <span className="text-red-500 font-bold">
              {chartData.datasets[0].data[0]}
            </span>
            &nbsp; Complaints
          </p>
          <p className="text-sm font-medium text-gray-800">
            <span className="text-green-500 font-bold">
              {chartData.datasets[0].data[1]}
            </span>
            &nbsp; Compliments
          </p>
          <p className="text-sm font-medium text-gray-800">
            <span className="text-yellow-500 font-bold">
              {chartData.datasets[0].data[2]}
            </span>
            &nbsp; Both
          </p>
        </div>
      </div>
    </div>
  );
};

export default FeedbackSentiment;
