import React from "react";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const Unauthorized = () => {
  return (
    <>
      <section className="flex justify-center flex-col h-screen p-8 w-screen items-center">
        <img src="/unauth.svg" className="lg:w-[30%]" alt="" />
        <h4 className="text-lg text-center">
          You should not be here. Please log in to access this page.
        </h4>
        <div className="flex flex-col items-center mt-8 gap-4">
          <Link to="/login">
            <button
              type="submit"
              className="bg-btn mt-8 w-full transition border-btn border duration-300 ease-in-out text-white hover:border hover:bg-transparent hover:text-btn flex items-center justify-center cursor-pointer rounded-lg px-4 py-2"
            >
              <div>
                Login &nbsp;
                <FontAwesomeIcon icon={faUser} />
              </div>
            </button>
          </Link>
        </div>
      </section>
    </>
  );
};

export default Unauthorized;
