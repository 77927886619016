import {
  faTimes,
  faTrashCan,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const DeletePopup = ({ CSSTransition, user_id, erase, setErase, styles }) => {
  const navigate = useNavigate();
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const apiUrl = import.meta.env.VITE_API_URL;

  const handleDelete = async () => {
    const token = JSON.parse(localStorage.getItem("user"))?.access;
    try {
      const response = await axios.delete(
        `${apiUrl}/api/v1/auth/delete/${user_id}/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }
      );

      if (response.status === 204) {
        setErase(false);
        setNotificationMessage("User deleted successfully.");
        setShowNotification(true);
        setTimeout(() => {
          setShowNotification(false);
          navigate(-1);
        }, 2000);
      }
    } catch (err) {
      console.error("Delete Error:", err);

      setShowNotification(false);

      if (err.response) {
        const { status, data } = err.response;
        switch (status) {
          case 404:
            setNotificationMessage("User not found.");
            break;
          case 401:
            setNotificationMessage("Unauthorized. Please log in again.");
            navigate("/login");
            break;
          case 500:
            setNotificationMessage("Server error. Please try again later.");
            break;
          default:
            setNotificationMessage("Failed to delete user. Please try again.");
            break;
        }
      } else if (err.request) {
        setNotificationMessage(
          "No response from server. Please check your connection."
        );
      } else {
        setNotificationMessage("An unexpected error occurred.");
      }

      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 3000);
    }
  };

  const styles2 = {
    enter: "transform translate-x-full opacity-0",
    enterActive:
      "transform -translate-x-0 opacity-100 transition-all duration-500 ease-in-out",
    exitActive:
      "transform translate-x-full opacity-0 transition-all duration-500 ease-in-out",
  };

  const myClassNames = {
    enter: "opacity-0",
    enterActive: "opacity-100 transition-opacity duration-500 ease-in-out",
    exitActive: "opacity-0 transition-opacity duration-500 ease-in-out",
  };

  return (
    <>
      <CSSTransition
        in={erase}
        classNames={myClassNames}
        timeout={300}
        unmountOnExit
      >
        <div
          className="fixed inset-0 overflow-scroll z-50 flex items-center justify-center bg-black/70 backdrop-blur-sm"
          onClick={() => setErase(false)}
        >
          <div
            className="relative bg-white rounded-lg shadow-lg w-[90%] max-w-lg animate-fadeIn p-8"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              onClick={() => setErase(false)}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 transition-colors"
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <div className="flex flex-col items-center">
              <div className="text-red-600 text-4xl mb-4">
                <FontAwesomeIcon icon={faExclamationTriangle} />
              </div>
              <h2 className="text-lg font-bold mb-2 text-center">
                Confirm Deletion
              </h2>
              <p className="text-sm text-gray-600 text-center mb-6">
                Are you sure you want to delete this user? This action cannot be
                undone.
              </p>
              <div className="flex justify-between gap-4 w-full">
                <button
                  onClick={handleDelete}
                  className="flex-1 bg-red-600 text-white font-bold py-2 px-4 rounded-md hover:bg-red-700 transition-colors"
                >
                  <FontAwesomeIcon icon={faTrashCan} className="mr-2" />
                  Delete
                </button>
                <button
                  onClick={() => setErase(false)}
                  className="flex-1 bg-gray-200 text-gray-700 font-bold py-2 px-4 rounded-md hover:bg-gray-300 transition-colors"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>

      <CSSTransition
        in={showNotification}
        classNames={styles2}
        timeout={500}
        unmountOnExit
      >
        <div className="p-4 shadow-xl z-50 bg-white fixed right-3 top-10 border-s-2 border-danger rounded-md flex items-center gap-4">
          <span className="flex items-center gap-4">
            <FontAwesomeIcon className="text-danger" icon={faTimes} />
            <p className="font-light">{notificationMessage}</p>
          </span>
        </div>
      </CSSTransition>
    </>
  );
};

export default DeletePopup;
